import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Flex, Heading, Text, Illustration } from '@postman/aether';
import { openExternalLink } from '@postman-app-monolith/renderer/js/external-navigation/ExternalNavigationService';
import {
  HOME_PAGE_URL,
  DOCS_URL,
  LICENSE_URL,
  CHANGELOG_URL,
  TWITTER_URL,
  ISSUES_URL
} from '../../constants/AppUrlConstants';

const isBrowser = __SDK_PLATFORM__ === 'browser';

class SettingsAbout extends Component {
  constructor (props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  handleLinkClick ({ link, target = '_blank' }, e) {
    e && e.preventDefault();
    openExternalLink(link, target);
  }

  // Platform info is necessary only in native app not in browser

  getPlatformDesc () {
    const { t } = this.props;

    switch (this.props.platform) {
      case 'darwin':
        return t('settings:about.postman_for_darwin');
      case 'win32':
        return t('settings:about.postman_for_win32');
      case 'linux':
        return t('settings:about.postman_for_linux');
      default:
        return '';
    }
  }

  render () {
    const { t } = this.props;

    return (
      <Flex direction='column' gap='spacing-xxl' alignItems='center' className='settings-about-wrapper' padding='spacing-xxl spacing-zero spacing-zero'>
        <div className='postman-logo' />
        <Flex direction='column' gap='spacing-l' alignItems='center'>
          <Heading type='h2' text={isBrowser ? t('settings:about.postman_for_web') : !_.isEmpty(this.props.platform) && this.getPlatformDesc(this.props.platform)} />
          <Flex direction='column' gap='spacing-l' alignItems='center' justifyContent='center'>
            {
              pm.app.get('productVersion') &&
              <Flex direction='column' alignItems='center'>
                <Text color='content-color-secondary'>{t('settings:about.product_version')}</Text>
                <Text color='content-color-primary'>{pm.app.get('productVersion')}</Text>
              </Flex>
            }
            {
              !isBrowser && !__WP_IS_SIGNED_OUT_DESKTOP__ &&
              <Flex direction='column' alignItems='center'>
                <Text color='content-color-secondary'>{t('settings:about.ui_version')}</Text>
                <Text type='para' color='content-color-primary'>{window.APP_VERSION}</Text>
              </Flex>
            }
            {
              !isBrowser && !__WP_IS_SIGNED_OUT_DESKTOP__ &&
              <Flex direction='column' alignItems='center'>
                <Text color='content-color-secondary'>{t('settings:about.desktop_platform_version')}</Text>
                <Text type='para' color='content-color-primary'>{this.props.version}</Text>
              </Flex>
            }
            {
              !isBrowser &&
              <Flex direction='column' alignItems='center'>
                <Text color='content-color-secondary'>{t('settings:about.architecture')}</Text>
                <Text type='para' color='content-color-primary'>{this.props.architecture}</Text>
              </Flex>
            }
            {
              isBrowser ?

                // browser
                <Flex direction='column' alignItems='center'>
                    <Text color='content-color-secondary'>{t('settings:about.browser_and_os')}</Text>
                    <Text type='para' color='content-color-primary'>{this.props.platform + ', ' + this.props.os}</Text>
                  </Flex>
                :

                // native app
                <Flex direction='column' alignItems='center'>
                  <Text color='content-color-secondary'>{t('settings:about.os_platform')}</Text>
                  <Text type='para' color='content-color-primary'>{this.props.os}</Text>
                </Flex>
            }
          </Flex>
        </Flex>
        <Flex direction='column' gap='spacing-s' justifyContent='center' alignItems='center' wrap='wrap'>
            <a
              className='settings-about-links'
              href={HOME_PAGE_URL}
              onClick={this.handleLinkClick.bind(this, { link: HOME_PAGE_URL })}
            >
              <Text type='link-subtle' isExternal>{t('settings:about.links.website')}</Text>
            </a>
            <a
              className='settings-about-links'
              href={TWITTER_URL}
              onClick={this.handleLinkClick.bind(this, { link: TWITTER_URL })}
            >
              <Text type='link-subtle' isExternal>{t('settings:about.links.twitter')}</Text>
            </a>
            <a
              className='settings-about-links'
              href={LICENSE_URL}
              onClick={this.handleLinkClick.bind(this, { link: LICENSE_URL })}
            >
              <Text type='link-subtle' isExternal>{t('settings:about.links.privacy')}</Text>
            </a>
            <a
              className='settings-about-links'
              href={CHANGELOG_URL}
              onClick={this.handleLinkClick.bind(this, { link: CHANGELOG_URL })}
            >
              <Text type='link-subtle' isExternal>{t('settings:about.links.release_notes')}</Text>
            </a>
            <a
              className='settings-about-links'
              href={DOCS_URL}
              onClick={this.handleLinkClick.bind(this, { link: DOCS_URL })}
            >
              <Text type='link-subtle' isExternal>{t('settings:about.links.learning_center')}</Text>
            </a>
            <a
              className='settings-about-links'
              href={ISSUES_URL}
              onClick={this.handleLinkClick.bind(this, { link: ISSUES_URL })}
            >
              <Text type='link-subtle' isExternal>{t('settings:about.links.report_issue')}</Text>
            </a>
          </Flex>
      </Flex>
    );
  }
}

export default withTranslation('settings')(SettingsAbout);
