import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Text from '@postman-app-monolith/renderer/js/components/base/Text';
import { openExternalLink } from '@postman-app-monolith/renderer/js/external-navigation/ExternalNavigationService';

import { POSTMAN_AGENTS } from '@postman-app-monolith/renderer/js/constants/AppUrlConstants';
import { Button } from '@postman-app-monolith/renderer/js/components/base/Buttons';
import { withTranslation } from 'react-i18next';
import { TYPES } from '../../../agent/AgentConstants';
import { handleSwitchToDesktopAgent } from '../../../agent/AgentHelpers';

@observer
class ArtemisEmptyState extends Component {
  constructor (props) {
    super(props);

    this.switchToDesktopAgent = this.switchToDesktopAgent.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  getClasses () {
    return classnames({ 'artemis-empty-state': true }, this.props.className);
  }

  getAssetClasses () {
    return classnames({ 'artemis-empty-state__asset': true }, this.props.emptyStateAsset);
  }

  getMessageClasses () {
    return classnames({ 'artemis-empty-state__message': true }, this.props.messageClass);
  }

  getPrimaryActionClasses () {
    return classnames({
      'artemis-empty-state__primary-action': true,
      'margin-xl': this.props.enableSecondaryAction
    });
  }

  switchToDesktopAgent () {
    handleSwitchToDesktopAgent && handleSwitchToDesktopAgent(this.props.cleanUp);
  }

  handleLinkClick () {
    openExternalLink(POSTMAN_AGENTS, '_blank');
  }

  render () {
    return (
      <div className={this.getClasses()}>
        { this.props.emptyStateAsset && <div className={this.getAssetClasses()} />}
        <div className='artemis-empty-state__title'>
          <Text
            value={this.props.title}
            type={this.props.titleVariant}
          />
        </div>
        <div className={this.getMessageClasses()}>
          <Text
            value={this.props.message}
            type='body-medium'
          />
        </div>
        {
          _.get(pm.runtime, 'agent.stat.type') !== TYPES.WS && this.props.enablePrimaryAction && (
            <Button
              className={this.getPrimaryActionClasses()}
              type='primary'
              onClick={this.switchToDesktopAgent}
            >
              {this.props.t('api-client-core:empty_state.primary_action')}
            </Button>
          )
        }
        {
          this.props.enableSecondaryAction && (
            <Button
              className='artemis-empty-state__secondary-action'
              onClick={this.handleLinkClick}
              type='text'
            >
              {this.props.t('api-client-core:empty_state.secondary_action')}
            </Button>
          )
        }
      </div>
    );
  }
}

export default withTranslation('api-client-core')(ArtemisEmptyState);

ArtemisEmptyState.defaultProps = {
  className: undefined,
  messageClass: undefined,
  emptyStateAsset: undefined,
  titleVariant: 'label-primary-medium',
  enablePrimaryAction: true,
  enableSecondaryAction: false,
  cleanUp: _.noop
};

ArtemisEmptyState.propTypes = {
  titleVariant: PropTypes.oneOf([
    'label-primary-medium',
    'heading-h3'
  ]),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  messageClass: PropTypes.string,
  emptyStateAsset: PropTypes.string,
  enablePrimaryAction: PropTypes.bool,
  enableSecondaryAction: PropTypes.bool,
  cleanUp: PropTypes.func
};
