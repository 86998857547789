/* eslint-disable no-confusing-arrow */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  IconActionEditStroke,
  IconActionLockStrokeSmall,
  IconActionRefreshStroke,
  IconStateErrorStrokeSmall,
  IconStateSuccessStrokeSmall,
  IconStateWarningStrokeSmall
} from '@postman/aether-icons';
import { Text, Flex, Button } from '@postman/aether';
import { getDefaultShortcuts } from '@postman-app-monolith/renderer/js/controllers/ShortcutsList';
import { isBrowser as checkIsBrowser } from '@postman-app-monolith/renderer/js/utils/platform-utils';
import { getShortcutKeyBinding } from '@postman-app-monolith/renderer/uxfoundation/utils/CustomKeyboardShortcutsUtil';

import ShortcutKey from './ShortcutKey';
import CaptureCustomShortcut from './CaptureCustomShortcut';
import { useTranslation } from 'react-i18next';

const isDarwin = window ? _.includes(navigator.platform, 'Mac') : process.platform === 'darwin';
const isBrowser = checkIsBrowser();
const EMPTY_KEY_BINDING_PLACEHOLDER_I18N_KEY = 'settings:shortcuts.empty_key_binding_placeholder';
export const SHORTCUT_ITEM_STATUS = {
  'DEFAULT': 'default', // When the shortcut capture mode is off
  'SUCCESS': 'success', // When the new shortcut is successfully submitted
  'INVALID': 'invalid', // When the new shortcut is invalid
  'CONFLICT': 'conflict' // When the new shortcut is valid creating a conflict
};

const PrefixElementContainer = styled.div`
  height: ${(props) => props.theme['size-s']};
  width: ${(props) => props.theme['size-s']};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => props.theme['spacing-xs']};
`;

const ResetShortcutButton = styled(Button)`
  height: ${(props) => props.theme['size-s']};
  width: ${(props) => props.theme['size-s']};
  margin-left: ${(props) => props.theme['spacing-xs']};

  &:hover,
  &:active {
    background-color: transparent;
    border: ${(props) => `${props.theme['border-width-default']} ${props.theme['border-style-solid']} ${props.theme['border-color-strong']}`};
  }
`;

/**
 * This funciton defines the background color of the shortcut item based on its current state
 */
function getBackgroundColor ({ status, isActive, isBrowser, areShortcutsEnabled, theme }) {
  if (!areShortcutsEnabled || isBrowser) {
    return `
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    `;
  }

  if (isActive && status === SHORTCUT_ITEM_STATUS.DEFAULT) {
    return `
      background-color: ${theme['background-color-info']};

      &:hover {
        background-color: ${theme['background-color-info']};
      }
    `;
  }

  if (isActive && status === SHORTCUT_ITEM_STATUS.INVALID) {
    return `
      background-color: ${theme['background-color-error']};

      &:hover {
        background-color: ${theme['background-color-error']};
      }
    `;
  }

  if (isActive && status === SHORTCUT_ITEM_STATUS.CONFLICT) {
    return `
      background-color: ${theme['background-color-warning']};

      &:hover {
        background-color: ${theme['background-color-warning']};
      }
    `;
  }

  if (status === SHORTCUT_ITEM_STATUS.SUCCESS) {
    return `
      background-color: ${theme['background-color-success']};

      &:hover {
        background-color: ${theme['background-color-success']};
      }
    `;
  }

  return `
    background-color: transparent;

    &:hover {
      background-color: ${theme['background-color-tertiary']};
    }
  `;
}

const StyledShortcutItem = styled.div`
  box-sizing: border-box;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  border-radius: ${(props) => props.theme['border-radius-default']};
  color: ${(props) => {
    if (!props.areShortcutsEnabled) {
      return props.theme['content-color-tertiary'];
    }

    return props.isActive ? props.theme['content-color-primary'] : props.theme['content-color-secondary'];
  }};
  // Using negative margins to align the left and right side with headings and content above
  margin-left: -8px;
  margin-right: -32px;
  padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-s);


  ${(props) => getBackgroundColor({
    status: props.status,
    isActive: props.isActive,
    isBrowser: props.isBrowser,
    areShortcutsEnabled: props.areShortcutsEnabled,
    theme: props.theme
  })}

  .shortcut-item__icon {
    height: 16px;
    width: 16px;
    padding: 2px;
    box-sizing: border-box;
  }

  .shortcut-item__icon.show-on-hover {
    visibility: hidden;
  }

  ${(props) => {
    if (props.areShortcutsEnabled && !props.isBrowser) {
      return `
        &:hover {
          cursor: ${props.isLocked ? 'not-allowed' : 'pointer'};
          .shortcut-item__icon.show-on-hover {
            visibility: ${props.isActive ? 'hidden' : 'visible'};
          }

          .shortcut-key {
            background-color: ${props.theme['background-color-primary']};
          }
        }
      `;
    }

    return `
      &:hover {
        cursor: ${props.isBrowser ? 'default' : 'not-allowed'};
      }
    `;
  }}
`;

/**
 * This function renders the shortcut key binding
 * @param {String} label The shortcut's key label
 */
function renderKeyLabels (label, isDisabled, i18n) {
  if (!label) return;

  if (label === i18n.t(EMPTY_KEY_BINDING_PLACEHOLDER_I18N_KEY)) {
    return (
      <Text
        type='body-small'
        color='content-color-tertiary'
      >
        {label}
      </Text>
    );
  }

  const wordPattern = /[a-zA-Z]{2,}/g;
  const macShortcutPattern = /\s+|[\^\⇧\⌥\⌘]|[^\s\^\⇧\⌥\⌘]+/g;

  const macFormattedLabel = label.match(macShortcutPattern);
  const nonMacFormattedLabel = label.split('+').filter((chunk) => chunk !== '');

  // Key representation in Windows/Linux
  if (!isDarwin) {
    if (label.includes('++')) {
      nonMacFormattedLabel.push('+');
    }

    if (label.includes('Num+')) {
      nonMacFormattedLabel[nonMacFormattedLabel.indexOf('Num')] = 'Num+';
    }

    return nonMacFormattedLabel.map((labelChunk, index) => {
      // Render other words wordPatterns like Ctrl/Shift/Win/Alt in ShortcutKey component
      if (labelChunk.match() && (labelChunk === i18n.t('settings:shortcuts.keys_between_label'))) {
        return (
          <Text
            key={index}
            typographyStyle={{
              fontSize: 'text-size-xs',
              fontWeight: 'text-weight-regular',
              lineHeight: 'line-height-s',
              fontFamily: 'text-family-default'
            }}
            color='content-color-tertiary'
          >
            {labelChunk}
          </Text>
        );
      }

      return <ShortcutKey key={index} keyLabel={labelChunk} isDisabled={isDisabled} />;
    });
  }

  return macFormattedLabel.map((labelChunk, index) => {
    if (!labelChunk.trim()) return;

    // Key representation in MacOS
    else {
      if (labelChunk.match(wordPattern) && (labelChunk === 'click' || labelChunk === 'scroll' || labelChunk === 'through')) {
        return (
          <Text
            key={index}
            typographyStyle={{
              fontSize: 'text-size-xs',
              fontWeight: 'text-weight-regular',
              lineHeight: 'line-height-s',
              fontFamily: 'text-family-default'
            }}
            color='content-color-tertiary'
          >
            {labelChunk}
          </Text>
        );
      }

      return <ShortcutKey key={index} keyLabel={labelChunk} isDisabled={isDisabled} />;
    }
  });
}

/**
 * This function renders a prefix element that appears just before the shortcut label.
 */
function renderPrefixElement ({
  isCustomised,
  isLocked,
  isBrowser,
  areShortcutsEnabled,
  transitionStatus,
  handleResetShortcut,
  i18n
}) {
  if (isBrowser) return <PrefixElementContainer />;

  if (isLocked) {
    return (
      <PrefixElementContainer>
        <IconActionLockStrokeSmall
          color='content-color-secondary'
          className='shortcut-item__icon show-on-hover'
        />
      </PrefixElementContainer>
    );
  }

  if (isCustomised && transitionStatus === 'default') {
    return (
      <ResetShortcutButton
        type='tertiary'
        size='small'
        {...(areShortcutsEnabled && { tooltip: i18n.t('settings:shortcuts.tooltips.restore_default') })}
        icon={<IconActionRefreshStroke size='small' />}
        onClick={handleResetShortcut}
        isDisabled={!areShortcutsEnabled}
      />
    );
  }

  if (transitionStatus === 'success') {
    return (
      <PrefixElementContainer>
        <IconStateSuccessStrokeSmall
          color='content-color-success'
          className='shortcut-item__icon'
        />
      </PrefixElementContainer>
    );
  }

  if (transitionStatus === 'invalid') {
    return (
      <PrefixElementContainer>
        <IconStateErrorStrokeSmall
          color='content-color-error'
          className='shortcut-item__icon'
        />
      </PrefixElementContainer>
    );
  }

  if (transitionStatus === 'conflict') {
    return (
      <PrefixElementContainer>
        <IconStateWarningStrokeSmall
          color='content-color-warning'
          className='shortcut-item__icon'
        />
      </PrefixElementContainer>
    );
  }

  return (
    <PrefixElementContainer>
      <IconActionEditStroke
        color='content-color-secondary'
        size='small'
        className='shortcut-item__icon show-on-hover'
      />
    </PrefixElementContainer>
  );
}

/**
 * This hook captures the click outside shortcut item
 */
function useOnClickOutside (ref, isActive, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target) || !isActive) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    [ref, handler]
  );
}

/**
 * This functional component renders individual shortcut item inside settings modal
 */
function ShortcutItem (props) {
  const { shortcut, areShortcutsEnabled } = props;

  const shortcutItemRef = useRef(null);
  const captureInputRef = useRef(null);

  const [status, setStatus] = useState(SHORTCUT_ITEM_STATUS.DEFAULT);
  const [isActive, setActive] = useState(false);
  const [isCustomised, setIsCustomised] = useState(false);

  const [shortcutKeyBinding, setShortcutKeyBinding] = useState(() => getShortcutKeyBinding(shortcut));
  const [isInvalidShortcut, setIsInvalidShortcut] = useState(false);

  let isConflicted = status === SHORTCUT_ITEM_STATUS.CONFLICT;
  const isLocked = shortcut.isLocked ? shortcut.isLocked : false;
  const originalShortcut = getDefaultShortcuts()[shortcut.name];

  const { t, i18n } = useTranslation('settings');

  // This function toggles on the capture mode. It is called we click anywhere on the shortcut item.
  const handleClick = () => {
    // We don't turn on the capture shortcut mode when --
    // (i) Shortcuts are disabled using the top toggle switch
    // (ii) Shortcut is non-customisable
    // (iii) Settings Modal is opened in a browser

    if (areShortcutsEnabled && !isLocked && !isBrowser) {
      setActive((prevValue) => !prevValue);
      setStatus(SHORTCUT_ITEM_STATUS.DEFAULT);
      setIsInvalidShortcut(false);
      setShortcutKeyBinding(getShortcutKeyBinding(shortcut));
    }
  };

  // If the capture mode is on then clicking on capture input should not toggle off the capture mode.
  const handleClickOnInput = (e) => {
    e.stopPropagation();
  };

  const handleResetShortcut = (e) => {
    e.stopPropagation();
    setActive(false);
    setIsInvalidShortcut(false);
    pm.shortcuts.resetShortcut(shortcut.name);
  };

  const handleOnSubmit = (newShortcutBinding) => {
    setActive(false);
    setIsInvalidShortcut(false);
    setStatus(SHORTCUT_ITEM_STATUS.SUCCESS);
    pm.shortcuts.changeShortcut(shortcut.name, newShortcutBinding);
  };

  const handleOnDismiss = (shouldKeepActiveMode = false) => {
    // Don't toggle off the shortcut capture mode if shouldKeepActiveMode is passed as true
    if (!shouldKeepActiveMode) {
      setActive(false);
      setIsInvalidShortcut(false);
    }

    setStatus(SHORTCUT_ITEM_STATUS.DEFAULT);

    if (!shortcut.shortcut && !shortcut.keyLabel && !shortcut.keyLabelDarwin) {
      setShortcutKeyBinding(t(EMPTY_KEY_BINDING_PLACEHOLDER_I18N_KEY));
    } else {
      setShortcutKeyBinding(getShortcutKeyBinding(shortcut));
    }
  };

  const handleClickOutside = () => {
    handleOnDismiss();
  };

  useEffect(() => {
    if (!_.isEqual(shortcut?.shortcut, originalShortcut?.shortcut)) {
      setIsCustomised(true);
    } else {
      setIsCustomised(false);
    }

    if (!shortcut.shortcut && !shortcut.keyLabel && !shortcut.keyLabelDarwin) {
      setShortcutKeyBinding(t(EMPTY_KEY_BINDING_PLACEHOLDER_I18N_KEY));
    } else {
      const newShortcutLabel = getShortcutKeyBinding(shortcut);
      setShortcutKeyBinding(newShortcutLabel);
    }

  }, [shortcut]);

  useEffect(() => {
    if (isActive) {
      if (captureInputRef && captureInputRef.current) {
        captureInputRef.current.focus();
        captureInputRef.current.select();
      }
    }
  }, [isActive, shortcutKeyBinding]);

  useEffect(() => {
    if (status === SHORTCUT_ITEM_STATUS.SUCCESS) {
      setTimeout(() => {
        setStatus(SHORTCUT_ITEM_STATUS.DEFAULT);
      }, 2000);
    }
  }, [status]);

  useOnClickOutside(shortcutItemRef, isActive, () => handleClickOutside());

  return (
    <StyledShortcutItem
      ref={shortcutItemRef}
      isActive={isActive}
      isLocked={isLocked}
      isBrowser={isBrowser}
      areShortcutsEnabled={areShortcutsEnabled}
      status={status}
      onClick={handleClick}
    >
      <Flex
        alignItems='center'
        gap='spacing-xs'
        justifyContent='space-between'
        width='100%'
      >
        <Text type='body-medium' text={shortcut.label} isTruncated className='shortcut-item__name' />
        <Flex alignItems='center'>
          {!isBrowser && isActive ? (
            <CaptureCustomShortcut
              ref={captureInputRef}
              value={shortcutKeyBinding}
              onClick={handleClickOnInput}
              onDismiss={handleOnDismiss}
              onSubmit={handleOnSubmit}
              isActive={isActive}
              isConflicted={isConflicted}
              isInvalidShortcut={isInvalidShortcut}
              shortcut={shortcut}
              status={status}
              setStatus={setStatus}
              setIsInvalidShortcut={setIsInvalidShortcut}
              setShortcutKeyBinding={setShortcutKeyBinding}
            />
          ) : (
            <Flex gap='spacing-xs'>
              {renderKeyLabels(shortcutKeyBinding, !areShortcutsEnabled, i18n)}
            </Flex>
          )}
          {renderPrefixElement({
            isCustomised,
            isLocked,
            areShortcutsEnabled,
            transitionStatus: status,
            handleResetShortcut,
            i18n
          })}
        </Flex>
      </Flex>
    </StyledShortcutItem>
  );
}

export default observer(ShortcutItem);
