import React from 'react';

import IntegrationsTokenMangerContainer from '@@renderer/integrations/components/TokenManager/Container';

/**
 * Component to render the connected accounts tokens
 */
export default function SettingsConnectedAccountsContainer () {
  return (
    <IntegrationsTokenMangerContainer view='settingsModal' />
  );
}
