import { computed } from 'mobx';
import { launchDarkly } from '@postman-app-monolith/renderer/onboarding/src/common/LaunchDarkly';

class DeepLinkingSwitchFlags {
  @computed
  get isEnabled () {
    return launchDarkly.getFlag('uxf-979-deep-link', false);
  }
}

export default new DeepLinkingSwitchFlags();
