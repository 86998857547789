var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
const APIPermissionService = require('@postman-app-monolith/renderer/api-dev/services/APIPermissionService').default;
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import CurrentUserStore from '@postman-app-monolith/renderer/js/stores/CurrentUserStore';
// @ts-ignore
import IntegrationsStore from '@postman-app-monolith/renderer/js/stores/IntegrationsStore';
const { UPDATE_API_META } = require('@postman-app-monolith/renderer/api-dev/constants/Permissions');
import { getAPI } from '@postman-app-monolith/renderer/api-dev/interfaces/APIInterface';
// @ts-ignore
import { createOrReplaceDefinition } from '@postman-app/api-definition-ui';
import _ from 'lodash';
export const 
/**
 * Function to return error context object which is used to log errors to NewRelic from client
 *
 * For more information on the usage of this context object, see the following:
 * https://postmanlabs.atlassian.net/wiki/spaces/EFDOCS/pages/3897655942/Error+Reporting+FrontEnd
 *
 * @param {String} apiName - Name of the api to distinguish errors
*/
getErrorContextObject = (apiName) => {
    return pm.logger.getContext(apiName, 'integrations');
}, 
/**
 * Function to get date and time from timestamp in the format like Jan 12, 2022, 12:09 pm
 */
getFormattedDate = (timestamp) => {
    if (timestamp) {
        return moment(parseInt(timestamp)).format('MMM DD, YYYY, h:mm a');
    }
    else {
        return null;
    }
}, formatUtcDate = (date) => {
    if (!date)
        return null;
    return moment(date).format('MMM DD, YYYY, h:mm a');
}, 
/**
 * Function to join base URL path and route path
 * @param basePath the host name
 * @param route the route excluding host name
 * @returns {string} joined URL
 */
getURLFromBasePathAndRoute = (basePath, route) => {
    basePath = basePath ? basePath.toString() : '';
    route = route ? route.toString() : '';
    basePath = basePath.endsWith('/') ? basePath.slice(0, -1) : basePath;
    route = route[0] === '/' ? route.slice(1) : route;
    return basePath + '/' + route;
}, 
/**
 * Function to convert a timestamp to relative string like "2 days ago"
 *
 * @param {String} timestamp ISO timestamp string
 * @return {String}
 */
getRelativeTime = (timestamp) => {
    const fallback = 'N/A';
    if (!timestamp) {
        return fallback;
    }
    try {
        return moment(timestamp).fromNow();
    }
    catch (e) {
        pm.logger.error(`integrations.utils.CommonHelperMethods.getRelativeTime ~ Error while parsing timestamp: ${timestamp}`, e);
        return fallback;
    }
}, 
/**
 * Function to check if the current user has specified permission on the specified API.
 * If not permission is supplied, it will check if the user has the permission to update the API.
 *
 * @param {String} apiId Id of the API
 * @param {Array} apiPermissions List of permissions to check for
 * @returns {Promise} Resolves to True - if the user has specified permission on the specified API else False
 */
fetchApiPermissions = (apiId, apiPermissions) => {
    if (_.isEmpty(apiPermissions)) {
        apiPermissions = [UPDATE_API_META];
    }
    return new Promise((resolve) => {
        const userId = resolveStoreInstance(CurrentUserStore).id, permissionQueryObject = APIPermissionService.getAPIPermissionQueryObject(apiPermissions, [{ id: apiId }], userId);
        APIPermissionService.fetchPermissions(permissionQueryObject)
            .then((permissions) => {
            const allPermissionsExist = _.every(apiPermissions, (permission) => {
                return _.get(permissions, `${apiId}:${permission}.allowed`, false);
            });
            return resolve(allPermissionsExist);
        })
            .catch((err) => {
            pm.logger.error('integrations.utils.CommonHelperMethods~fetchApiPermissions: Something went wrong while fetching API permissions', err);
            return resolve(false);
        });
    });
}, 
/**
 * Function extract out the value token id from an integration object. Token id is the
 * id of oauth token table which is used to save authentication details.
 *
 * For an integration, this value is stored in the integration input with type `postman-integration-oauth`.
 * If the supplied integration object is in old format (i.e. does not have input types) we will resort to using
 * corresponding keys to extract the token id.
 *
*/
getTokenIdFromIntegration = (integration) => {
    let tokenId = '';
    if (integration.inputs) {
        // Integration inputs present, check if it has postman-integration-oauth type, and return the value
        const oauthInput = _.find(integration.inputs, { type: 'postman-integration-oauth' });
        tokenId = _.get(oauthInput, 'value', '');
    }
    else if (integration.variables) {
        // Integration variables present, fetch via key
        tokenId = _.get(integration, ['variables', 'oauth_token_id']);
    }
    return tokenId;
}, 
/**
 * Common function to fetch API details for given API id
 */
getAPIDetails = (apiId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const apiDetails = yield getAPI(apiId), versions = _.get(apiDetails, 'versions', []);
        if (_.size(versions) > 1) {
            pm.logger.warn(`CommonHelperMethods ~ getAPIDetails Fetched API with id:${apiId} had multiple associated versions.`);
        }
        return apiDetails;
    }
    catch (error) {
        pm.logger.error('CommonHelperMethods ~ getAPIDetails Encountered error in fetching api details ', error);
        return {};
    }
}), 
/**
 * Function to sanitize the route and query parameters for V10 URLs.
 * - Removes `versionId` from routeParams
 * - If more than one API version is present, it will add `version` query parameter with value of routeParams.versionId
 *
 * @param {Object} routeParams
 * @param {Object} queryParams
 * @param {String} apiId
 */
getSanitizedRouteAndQueryParamsForV10APIUrls = (routeParams, queryParams, apiId) => __awaiter(void 0, void 0, void 0, function* () {
    // Create copies of route and query params object to not modify the sources
    const routeParamsVersionId = _.get(routeParams, 'versionId'), sanitizedRouteParams = _.omit(routeParams, 'versionId'), sanitizedQueryParams = _.assign({}, queryParams);
    try {
        const apiDetails = yield getAPIDetails(apiId), apiVersionsLength = _.size(_.get(apiDetails, 'versions'));
        if (apiVersionsLength > 1 || apiVersionsLength === 0) {
            // Send version id in query params
            sanitizedQueryParams.version = routeParamsVersionId;
        }
    }
    catch (error) {
        pm.logger.error('CommonHelperMethods ~ getSanitizedRouteAndQueryParamsForV10APIUrls Unable to get API details', error);
        // Unable to get details for the api, set route param version in query params
        sanitizedQueryParams.version = routeParamsVersionId;
    }
    finally {
        return [sanitizedRouteParams, sanitizedQueryParams];
    }
}), 
/**
 * Function to filter integrations on the basis of client category
 *
 * @param {Object} integrationClasses - list of all available integration classes
 * @param {String} clientCategory - clientCategory based on which the integration classes will be filtered
 */
filterIntegrationClassesByClientCategory = (integrationClasses, clientCategory) => {
    if (_.isEmpty(integrationClasses)) {
        return [];
    }
    const filteredIntegrations = _.filter(integrationClasses, (integrationClass) => {
        const clientCategories = _.map(integrationClass.clientCategories, 'key');
        return _.includes(clientCategories, clientCategory);
    });
    return filteredIntegrations;
}, 
/**
 * Parses the error and returns the error message
 *
 * @param {Object} error - error object
 * @param {String} defaultMessage - default message to send if the error message is not found
 */
getErrorMessage = (error, defaultMessage) => {
    return ((_.get(error, 'error.details.message') || _.get(error, 'error.message')) || defaultMessage);
}, 
/**
 * Deletes the current schema files inside the given api definition and
 * adds a new file with the given schema content
 *
 * @param {String} apiId - Unique Id of the API
 * @param {String} apiVersionId - Unique Id of the version of the API
 * @param {String} apiDefinitionId - Unique Id of the API Definition
 * @param {Object} items - Object containing type, name, path and content of the schema
 * @returns {Promise} - Returns true if API definition is updated successfully else returns the err.
 */
updateGitLinkedAPIDefinition = (apiId, apiVersionId, apiDefinitionId, item) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const model = yield createOrReplaceDefinition({
            apiId,
            apiVersionId,
            items: [
                {
                    name: _.get(item, 'name'),
                    path: _.get(item, 'path'),
                    content: _.get(item, 'content'),
                },
            ],
            options: {
                type: _.get(item, 'type'),
            },
        });
        const fileId = yield model.getRedirectionFileId();
        return Promise.resolve(fileId);
    }
    catch (err) {
        return Promise.reject(err);
    }
}), 
/**
* Resets store variables.
* Updates IntegrationsStore and the class specific store if passed in the parameters
*
* @param {String} integrationClass - class/rule of the newly added integration
* @param {Store} storeInstance - Integration class/category specific store
* @param {Object} entityProperties - Stores entity type and Id
*/
resetAndRefetchStoreVariables = (integrationClass, storeInstance, entityProperties) => __awaiter(void 0, void 0, void 0, function* () {
    let integrationsStore = resolveStoreInstance(IntegrationsStore);
    try {
        integrationsStore.setRuleSpecificTeamIntegrations(integrationClass, null);
        // refresh the team integrations data
        integrationsStore.refetchTeamIntegrations();
    }
    catch (err) {
        pm.logger.error('resetAndRefetchStoreError~Could not update IntegrationsStore', err);
    }
    try {
        if (storeInstance && entityProperties) {
            let store = resolveStoreInstance(storeInstance);
            store.fetchIntegrationInstance && store.fetchIntegrationInstance(entityProperties.entityType, entityProperties.entityId);
        }
    }
    catch (err) {
        pm.logger.error('resetAndRefetchStoreError~Could not update store', err);
    }
    return null;
}), 
/**
 * Resets store variables.
 * Updates IntegrationsStore and the class specific store if passed in the parameters
 *
 * @param {String} integrationClass - class/rule of the newly added integration
 * @param {Store} storeInstance - Integration class/category specific store
 * @param {Object} entityProperties - Stores entity type and Id
 */
postAddIntegrationActions = (integrationClass, storeInstance, entityProperties) => __awaiter(void 0, void 0, void 0, function* () {
    let integrationsStore = resolveStoreInstance(IntegrationsStore);
    resetAndRefetchStoreVariables(integrationClass, storeInstance !== null && storeInstance !== void 0 ? storeInstance : undefined, entityProperties !== null && entityProperties !== void 0 ? entityProperties : undefined);
    // reset the selected workspace and entities
    integrationsStore.resetAddIntegrationDependencies();
    return null;
});
