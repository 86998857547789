import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { getShortcutsStructure, publishShortcutsEnabled, updateElectronMenuShortcut } from '@postman-app-monolith/renderer/uxfoundation/utils/CustomKeyboardShortcutsUtil';
import { observer } from 'mobx-react';
import { ToggleSwitch, Flex, Button, Heading, Text } from '@postman/aether';
import CustomKeyboardShortcutsStore from '@postman-app-monolith/renderer/js/stores/CustomKeyboardShortcutsStore';
import { CUSTOM_SHORTCUT_EVENT_BUS_CHANNEL, SHORTCUTS_EVENT_TOGGLE_STATE, SHORTCUT_GROUP_IDENTIFIERS } from '@postman-app-monolith/renderer/uxfoundation/constants/CustomKeyboardShortcutConstants';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';

import ShortcutGroup from './shortcuts/ShortcutGroup';
import PostbotGlobalStore from '@postman-app-monolith/renderer/runtime-repl/postbot/datastores/PostbotGlobalStore';

const isBrowser = __SDK_PLATFORM__ === 'browser';

// Shortcut items that are displayed on the left column
const ITEMS_ON_LEFT = isBrowser
? [SHORTCUT_GROUP_IDENTIFIERS.SIDEBAR, SHORTCUT_GROUP_IDENTIFIERS.WINDOWS_AND_MODALS]
: [SHORTCUT_GROUP_IDENTIFIERS.TABS, SHORTCUT_GROUP_IDENTIFIERS.SIDEBAR];

// Shortcut items that are displayed on the right column
const ITEMS_ON_RIGHT = isBrowser
? [
    SHORTCUT_GROUP_IDENTIFIERS.REQUEST,
    SHORTCUT_GROUP_IDENTIFIERS.INTERFACE,
    SHORTCUT_GROUP_IDENTIFIERS.CONSOLE
  ]
: [
    SHORTCUT_GROUP_IDENTIFIERS.REQUEST,
    SHORTCUT_GROUP_IDENTIFIERS.INTERFACE,
    SHORTCUT_GROUP_IDENTIFIERS.WINDOWS_AND_MODALS,
    SHORTCUT_GROUP_IDENTIFIERS.CONSOLE
  ];

@observer
class SettingsShortcuts extends Component {
  constructor (props) {
    super(props);

    this.state = {
      shortcutsEnabled: !pm.app.get('shortcutsDisabled')
    };

    this.handleToggleShortcuts = this.handleToggleShortcuts.bind(this);
    this.subscribeShortcutStateToggle();
  }

  /**
   * This event subscription is only present for the following edge case:
   *
   * User is on Settings Modal in multiple opened Postman windows and changes the setting from one
   * Postman window. This ensures the change made in setting is reflected in other opened Postman windows.
   */
  subscribeShortcutStateToggle () {
    let appEventsChannel = pm.eventBus.channel(CUSTOM_SHORTCUT_EVENT_BUS_CHANNEL);
    appEventsChannel.subscribe((event) => {
      if (event.name === SHORTCUTS_EVENT_TOGGLE_STATE && event.data) {
        if (event.data.shortcutsEnabled !== this.state.shortcutsEnabled) {
          this.setState({ shortcutsEnabled: event.data.shortcutsEnabled });

          // Update the store observable value we are using to keep tracking of shortcuts enabled setting
          resolveStoreInstance(CustomKeyboardShortcutsStore).toggleEnableShortcuts(event.data.shortcutsEnabled);
        }
      }
    });
  }

  handleToggleShortcuts (shortcutsEnabled) {
    this.setState({ shortcutsEnabled }, () => {
      (shortcutsEnabled) ? pm.app.enableShortcuts() : pm.app.disableShortcuts();

      // Publish setting change accross Postman windows
      publishShortcutsEnabled(shortcutsEnabled);

      if (shortcutsEnabled) {
        // When enabling shortcuts, we also want to update the OS menu with user shortcuts
        updateElectronMenuShortcut(resolveStoreInstance(CustomKeyboardShortcutsStore).userDefinedShortcuts);
      }

      resolveStoreInstance(CustomKeyboardShortcutsStore).toggleEnableShortcuts(shortcutsEnabled);
    });
  }

  handleResetAllShortcuts () {
    pm.shortcuts.resetAllShortcuts();
  }

  settingsShortcuts (i18n) {
    let allShortcuts = resolveStoreInstance(CustomKeyboardShortcutsStore).allShortcuts;

    // deep clone
    let shortcuts = JSON.parse(JSON.stringify(allShortcuts));

    const { postbotFeatureFlags } = resolveStoreInstance(PostbotGlobalStore);

    /*
     This is a temporary fix to not show postbot shortcut when postbot flag is disabled, this needs to be refactored as it prevents users with postbot disabled to create command+shift+\ shortcut
     */
    if (!postbotFeatureFlags.isGlobalPostbotEnabled())
    delete shortcuts['triggerPostbot'];

    Object.entries(shortcuts).forEach(([key, value]) => {
      if (value.label) {
        value.label = i18n.t(`settings:shortcuts.${_.snakeCase(value.name)}`);
      }
    });

    return getShortcutsStructure(shortcuts, i18n);
  }

  render () {
    // i18n translation function
    const { t, i18n } = this.props;
    const { shortcutsEnabled } = this.state;
    const shortcutsList = this.settingsShortcuts(i18n);
    const LEFT_COLUMN_SHORTCUTS = shortcutsList.filter((shortcutObj) => ITEMS_ON_LEFT.includes(shortcutObj.id));
    const RIGHT_COLUMN = shortcutsList.filter((shortcutObj) => ITEMS_ON_RIGHT.includes(shortcutObj.id));

    return (
      <Flex width='100%' direction='column'>
        <Flex gap='spacing-xl' direction='column'>
          <Heading type='h2' text={t('settings:shortcuts.label')} />
          <Flex gap='spacing-xl' justifyContent='space-between' alignItems='center' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }} padding={{ paddingTop: 'spacing-m' }}>
            <Flex grow={1} gap='spacing-l' alignItems='center' justifyContent='space-between'>
              <Text>{t('settings:shortcuts.toggle_switch_label')}</Text>
              <ToggleSwitch
                isChecked={shortcutsEnabled}
                onChange={this.handleToggleShortcuts}
                id='enable-shortcuts'
              />
            </Flex>
            {!isBrowser ? (
              <Flex border={{ borderLeft: 'border-width-default' }} padding='spacing-xs spacing-zero spacing-xs spacing-xl'>
                <Button
                  type='outline'
                  size='small'
                  text={t('settings:shortcuts.restore_defaults_button_label')}
                  onClick={this.handleResetAllShortcuts}
                  isDisabled={!shortcutsEnabled}
                />
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        <Flex direction='column'>
          {
             LEFT_COLUMN_SHORTCUTS && LEFT_COLUMN_SHORTCUTS.length &&
              <div>
                {LEFT_COLUMN_SHORTCUTS.map((group, groupIndex) => (
                  <ShortcutGroup
                    group={group}
                    key={groupIndex}
                    areShortcutsEnabled={shortcutsEnabled}
                  />
                ))}
              </div>
          }
          {
            RIGHT_COLUMN && RIGHT_COLUMN.length &&
              <div>
                {RIGHT_COLUMN.map((group, groupIndex) => (
                  <ShortcutGroup
                    group={group}
                    key={groupIndex}
                    areShortcutsEnabled={shortcutsEnabled}
                  />
                ))}
              </div>
          }
        </Flex>
      </Flex>
    );
  }
}

export default withTranslation('settings')(SettingsShortcuts);
