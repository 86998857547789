/**
 * Checks if the editor has unsaved changes
 */
const isDirty = (editorResource) => {
  const cachedData = editorResource.cachedEditorData;
  return cachedData && cachedData.isDirty;
};

/**
 * Checks if any active workspace session has unsaved changes
 */
export const isUnsavedChanges = (allActiveWorkspaces) =>
  allActiveWorkspaces.some((workspace) => {
    if (workspace) {
      const { workspace: wid, editorsData: allEditors, closedEditors } = workspace;

      /**
       * Any editor with unsaved changes that was closed by the user remains part of editorsData,
       * and the user can restore their editor from `Recently Closed Tabs`.
       * But the decision to close the editor and discard is considered as a conscious choice of the user
       * as Postman shows a warning prompt before closing the editor.
       * Before opting-in to no-tabs state, we consider unsaved changes only for visible editors in workspaces.
       */
      const visibleEditors = _.omit(allEditors, closedEditors);
      const editorResources = visibleEditors && Object.values(visibleEditors);

      if (editorResources.some(isDirty)) {
        return true;
      }
    }
    return false;
  });

