import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Button } from '../base/Buttons';
import Text from '../base/Text';
import { openExternalLink } from '@postman-app-monolith/renderer/js/external-navigation/ExternalNavigationService';

const updateTroubleshootDoc = 'https://go.pstmn.io/troubleshoot-updates';

 class SettingsUpdateSnap extends Component {
  openDocLink () {
    openExternalLink(updateTroubleshootDoc);
  }

  render () {
    const { t } = this.props;
    const currentVersion = this.props.currentVersion;

    return (
      <div>
        <Text value={t('settings:update.updates_through_snap.up_to_date.heading')} type='heading-h5' />
        <p>
        <Trans
          i18nKey='settings:update.updates_through_snap.up_to_date.desc'
          defaults='You’re on <0>v{currentVersion}</0>. Snap will automatically update Postman whenever a new version is released.'
          // eslint-disable-next-line react/jsx-key
          components={[<b>v{currentVersion}</b>]}
          values={{ currentVersion }}
        />

        </p>
        <div className='divider' />
        <p>
          <Text value={t('settings:update.updates_through_snap.troubleshoot.desc')} type='body-medium' />
        </p>
        <Button
          className='learn-more-button'
          type='text'
          onClick={this.openDocLink}
        >
          <Text value={t('settings:update.updates_through_snap.troubleshoot.cta')} type='button-medium' />
        </Button>
      </div>
    );
  }
}

SettingsUpdateSnap.defaultProps = { autoDownloadUpdateStatus: 0 };

export default withTranslation('settings')(SettingsUpdateSnap);
