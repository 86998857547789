import React, { Component } from 'react';
import {
  Text, Flex, ToggleSwitch, Tooltip
} from '@postman/aether';
import { IconStateWarningStrokeSmall } from '@postman/aether-icons';
import ProcessedFileInput from '@@runtime-repl/_common/components/ProcessedFileInput';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const StyledFileInputContainer = styled(Flex)`
  // Truncate file name to stay within the boudaries of the container
  .file-input-container {
    min-width: 0px;
  }
`,

  StyledFileInputLabel = styled(Text)`
  // Prevent input label to break on smaller viewport widths or if file name is huge
  flex-shrink: 0;
`;

class CACertificate extends Component {
  constructor (props) {
    super(props);

    this.state = {
      enabled: false,
      filePath: '',
      fileError: false
    };

    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.filePathChangeListener = this.settingChangeListener.bind(this, 'filePath');
    this.enabledChangeListener = this.settingChangeListener.bind(this, 'enabled');
  }

  UNSAFE_componentWillMount () {
    this.setState({
      enabled: pm.settings.getSetting('isCACertEnabled'),
      filePath: pm.settings.getSetting('CACertPath')
    });

    this.fileErrorCheck(pm.settings.getSetting('CACertPath'));

    pm.settings.on({
      'setSetting:isCACertEnabled': this.enabledChangeListener,
      'setSetting:CACertPath': this.filePathChangeListener
    });
  }

  componentWillUnmount () {
    pm.settings.off({
      'setSetting:isCACertEnabled': this.enabledChangeListener,
      'setSetting:CACertPath': this.filePathChangeListener
    });
  }

  UNSAFE_componentWillReceiveProps () {
    this.setState({
      enabled: pm.settings.getSetting('isCACertEnabled'),
      filePath: pm.settings.getSetting('CACertPath')
    });

    this.fileErrorCheck(pm.settings.getSetting('CACertPath'));
  }

  settingChangeListener (key, value) {
    this.setState({ [key]: value });
  }

  handleFileSelect (files) {
    const path = _.get(files, '[0].path', '');

    this.setState({ filePath: path });
    this.fileErrorCheck(path);
    pm.settings.setSetting('CACertPath', path);
  }

  handleToggle (isActive) {
    this.setState({ enabled: isActive });
    pm.settings.setSetting('isCACertEnabled', isActive);
  }

  getValueForFileInput (filePath) {
    // If path doesn't exist then return empty array
    if (!filePath) {
      return [];
    }

    // Value exist then create array with single element
    return [filePath];
  }

  fileErrorCheck (filePath) {
    if (!filePath) {
      return false;
    }

    pm.runtime
      .pathAccessible(filePath, false, (err) => {
        this.setState({ fileError: !!err });
      });
  }

  render () {
    const { t } = this.props;

    return (
      <div className='ca-certificate-wrapper'>
        <Flex justifyContent='space-between' gap='spacing-xxl'>
          <Flex direction='column'>
            <Text>{t('settings:certificates.ca_certificates.heading')}</Text>
          </Flex>
          <Flex className='settings-rhs-control-spacing-adjust' shrink={0}>
            <ToggleSwitch
              data-testid={`ca-certificate-toggle-switch-${this.state.enabled}`}
              isChecked={this.state.enabled}
              onChange={this.handleToggle}
            />
          </Flex>
        </Flex>

        <Flex direction='column' gap='spacing-xs' className={`ca-certificate__field ${this.state.enabled ? '' : 'disabled'}`}>
          <StyledFileInputContainer gap='spacing-xs' alignItems='center'>
            <StyledFileInputLabel color='content-color-secondary'>
              {t('settings:certificates.ca_certificates.pem_file.label')}
            </StyledFileInputLabel>
            {this.state.filePath && this.state.fileError && (
              <Tooltip content={t('settings:certificates.ca_certificates.pem_file.file_format_error')}>
                <IconStateWarningStrokeSmall color='content-color-warning' />
              </Tooltip>
            )}
            <ProcessedFileInput
              dataTestId='ca-certificate-pem-file-input'
              value={this.getValueForFileInput(this.state.filePath)}
              onSelect={this.handleFileSelect}
              onClear={this.handleFileSelect}
              disabled={!this.state.enabled}
            />
          </StyledFileInputContainer>
          <Text
            color='content-color-secondary'
            typographyStyle={{
              fontSize: 'text-size-s',
              lineHeight: 'line-height-s'
            }}
          >
            {t('settings:certificates.ca_certificates.pem_file.description')}
          </Text>
        </Flex>
      </div>
    );
  }
}

export default withTranslation('settings')(CACertificate);
