import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Flex, Heading, Text } from '@postman/aether';
import Link from '@postman-app-monolith/renderer/appsdk/components/link/Link';
import { openExternalLink } from '@postman-app-monolith/renderer/js/external-navigation/ExternalNavigationService';

const DOWNLOAD_NEWMAN_URL = 'https://www.npmjs.com/package/newman';
class SettingsAddons extends Component {
  constructor (props) {
    super(props);
  }

  // TODO:  If we have more addons must be pass through model or an const array
  render () {
    const { t } = this.props;
    return (
      <Flex gap='spacing-l' direction='column' className='settings-addons-wrapper'>
        <Flex direction='column' gap='spacing-xl' padding={{ paddingBottom: 'spacing-xs' }}>
          <Heading type='h2' text={t('settings:addons.label')} />
          <Flex direction='column' gap='spacing-l' justifyContent='space-between'>
            <Flex direction='column' gap='spacing-xs' shrink={1}>
              <Heading type='h4' text={t('settings:addons.heading')} />
              <Text type='para'>
                {t('settings:addons.section_summary')}
              </Text>
            </Flex>
            <Link
              to={DOWNLOAD_NEWMAN_URL}
              onClick={(e) => { e.preventDefault(); openExternalLink(DOWNLOAD_NEWMAN_URL, '_blank'); }}
            >
              <Text type='link-button-outline' isExternal>{t('settings:addons.download_linkText')}</Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default withTranslation('settings')(SettingsAddons);
