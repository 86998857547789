import { action, observable, autorun } from 'mobx';
import PostmanGatewayService from '@postman-app-monolith/renderer/js/utils/PostmanGatewayService';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import SyncStatusStore from '@postman-app-monolith/renderer/js/stores/SyncStatusStore';
import { getCurrentUser } from '@postman-app-monolith/renderer/iam/src/common/utils/getCurrentUser';

const ALLOWED_ENTITY_TYPES = ['team', 'user'];

export default class FeatureFlagsStore {
  @observable featureFlags = observable.map();
  @observable fetchingFeatureFlags = false;

  constructor () {
    this.activeItemAutorun = autorun(() => {
      if (resolveStoreInstance(SyncStatusStore).isSocketConnected) {
        this.updateFlags();
      }
    });
  }

  isEnabled (flag) {
    return this.featureFlags.get(flag);
  }

  get (flag) {
    return this.featureFlags.get(flag);
  }

  @action
  _getFlagFromSync () {
    if (this.fetchingFeatureFlags) {
      return;
    }

    this.fetchingFeatureFlags = true;

    // if user is not signed in. then call to feature config
    // does not make sense, as that is not a public endpoint
    return Promise.resolve()
      .then(() => {
        if (!getCurrentUser('isLoggedIn')) {
          return;
        }

        return PostmanGatewayService.request('/featureConfig', {
          method: 'GET',
          headers: {
            'Content-type': 'application/json'
          }
        }, { returnRawResponse: true })
          .then(async (response) => {
            const responseBody = await response.json();

            if (!response.ok) {
              return Promise.reject({
                status: response.status,
                error: responseBody?.error
              });
            }

            return {
              body: responseBody
            };
          })
          .finally(() => {
            this.fetchingFeatureFlags = false;
          });
      });
  }

  @action.bound
  updateFlags () {
    Promise.all([
      pm.features._getAll(),
      this._getFlagFromSync()
    ])
    .then((values) => {
      let syncFeatureValues = values && values[1] && values[1].body && values[1].body.data || {},
        defaultFeatureFlagValues = values && values[0] || {},
        flagsToBeUpdated = {};

      /**
       * Response in the format
       *
       * {
          "meta": {
            "model": "config",
            "action": "find"
          },
          "data": {
            "workspace": {
              "team": {
                "isPrivateTeamWorkspaceEnabled": true,
                "isAccessControlEnabled": true,
                "publicWorkspaceCreationEnabled": true
              },
              "user": {}
            }
          }
        }
       * Preference given to user flags over team flags
       */
      _.forEach(syncFeatureValues, (entities, model) => {
        _.forEach(ALLOWED_ENTITY_TYPES, (entityType) => {
          if (!entities[entityType]) {
            return;
          }

          flagsToBeUpdated = _.assign({}, flagsToBeUpdated, _.reduce(entities[entityType], (acc, value, key) => {
            acc[`${model}:${key}`] = value;
            return acc;
          }, {}));
        });
      });

      this.update(_.defaultsDeep({}, defaultFeatureFlagValues, flagsToBeUpdated));
    })
    .catch((err) => {
      pm.logger.warn('FeatureFlagsStore~updateFlags: Could not find feature flags', err);

      pm.features._getAll().then(this.update);
    });

  }

  @action.bound
  update (values) {
    this.featureFlags.replace(values);
  }

  @action.bound
  updateFlag (flag, value) {
    this.featureFlags.set(flag, value);
  }
}
