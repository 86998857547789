import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Flex, Text, Checkbox, TextInput, Button, Heading } from '@postman/aether';
import classnames from 'classnames';
import { TrackedState, bindTrackedStateToComponent } from '../../../modules/tracked-state/TrackedState';
import AnalyticsService from '../../../modules/services/AnalyticsService';

/**
 * This component is used for proxy settings which are applied by default for Postman internal requests
 * i.e. sync, login, updates etc. These are picked up from the system proxy settings. This is not related
 * to the proxy settings used for request sending flow.
 *
 * DO NOT add any logic related to proxy configuration for request sending flow.
 */
class SettingsSystemProxy extends Component {
  constructor (props) {
    super(props);

    let proxyConfig = pm.cloudProxyHandler.proxyConfig;

    this.trackedState = new TrackedState({
      isAuthNeeded: Boolean(proxyConfig && proxyConfig.auth),
      username: _.get(proxyConfig, 'auth.username') || '',
      password: _.get(proxyConfig, 'auth.password') || ''
    });

    bindTrackedStateToComponent(this.trackedState, this);

    this.handleProxyAuthUsernameChange = this.handleProxyAuthPropertyChange.bind(this, 'username');
    this.handleProxyAuthPasswordChange = this.handleProxyAuthPropertyChange.bind(this, 'password');
    this.handleProxyAuthSubmit = this.handleProxyAuthSubmit.bind(this);
    this.handleAuthNeededChange = this.handleAuthNeededChange.bind(this);
  }

  handleProxyAuthPropertyChange (key, value) {
    this.trackedState.set({
      [key]: value
    });
  }

  handleProxyAuthSubmit () {
    let authNeeded = this.trackedState.get('isAuthNeeded');

    AnalyticsService.addEventV2({
      category: 'app_proxy_config',
      action: authNeeded ? 'auth_updated' : 'auth_removed',
      label: 'settings_modal'
    });

    if (!authNeeded) {
      pm.cloudProxyHandler.handleProxyAuthSubmit({});
      return;
    }

    pm.cloudProxyHandler.handleProxyAuthSubmit({ username: this.trackedState.get('username'), password: this.trackedState.get('password') });
  }

  handleAuthNeededChange () {
    let prevState = this.trackedState.get('isAuthNeeded');

    this.trackedState.set({ isAuthNeeded: !prevState });
  }

  render () {
    let isAuthNeeded = this.trackedState.get('isAuthNeeded'),
        submitBtnClassname = classnames({
          'submit-btn': true,
          'auth-enabled': isAuthNeeded
        });
    const { t } = this.props;

    return (
      <Flex
        direction='column'
        padding='spacing-s spacing-zero spacing-zero'
      >
        <Flex direction='column' grow={0} shrink={1} gap='spacing-xs'>
          <Heading type='h4' text={t('settings:proxy.system.heading')} />
          <Text type='para'>
            {t('settings:proxy.system.summary')}
          </Text>
        </Flex>
        <Flex
          direction='column'
          gap='spacing-l'
          padding='spacing-l spacing-zero'
        >
          <Checkbox
            isChecked={isAuthNeeded}
            onChange={this.handleAuthNeededChange}
            label={t('settings:proxy.system.auth_label')}
          />
          {
            isAuthNeeded &&
            <Flex direction='column' gap='spacing-l'>
              <TextInput
                value={this.trackedState.get('username')}
                placeholder={t('settings:proxy.common.username_label')}
                onChange={(e) => this.handleProxyAuthUsernameChange(e.target.value)}
              />
              <TextInput
                value={this.trackedState.get('password')}
                placeholder={t('settings:proxy.common.password_label')}
                onChange={(e) => this.handleProxyAuthPasswordChange(e.target.value)}
                type='password'
              />
            </Flex>
          }
          {
            this.trackedState.isDirty() &&
            <div className={submitBtnClassname}>
              <Button
                className='submit-button'
                type='primary'
                onClick={this.handleProxyAuthSubmit}
                text={t('settings:proxy.system.restart_button_text')}
              />
            </div>
          }
        </Flex>
      </Flex>
    );
  }
}
export default withTranslation('settings')(SettingsSystemProxy);
