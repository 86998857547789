export const NETWORK_CONNECTION_MODE_KEY = 'network-connection-mode';

export const ENABLE_LONG_POLLING_TRANSPORT_KEY =
  'enable-long-polling-transport';

export const FIRST_APPLOAD_ENABLE_LONG_POLLING_TRANSPORT_KEY =
  'first-appload-enable-long-polling-transport';

export const FIRST_APPLOAD_PRE_POLLING_SOCKET_ATTEMPTS_KEY =
  'first-appload-pre-polling-socket-attempts';

export const PRE_POLLING_SOCKET_ATTEMPTS_KEY = 'pre-polling-socket-attempts';
export const NETWORK_CONNECTION_MODE_WEBSOCKET = 'websocket';
export const NETWORK_CONNECTION_MODE_POLLING = 'polling';

export const RESTORE_SOCKET_CONNECTION_MODE_EVENT = 'restoreSocketConnect';
export const FORCE_POLLING_CONNECTION_MODE_EVENT = 'forcePollingConnect';

export const NETWORK_SETTINGS_CATEGORY = 'network_settings';

export const ENABLE_CONNECTION_MODE_SETTINGS_KEY =
  'enable-connection-mode-settings';
