export const CLIENT_CATEGORY = 'notification-center';
export const SLACK = 'slack';
export const SERVICES = {
    [SLACK]: {
        rule: 'notifications_integration_slack',
        oauth_service: 'slackv2',
        label: 'Slack',
        integration_name: 'Slack Notification Integration'
    }
};
