import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SettingsSystemProxy from './SettingsSystemProxy';
import SettingsGlobalProxy from './SettingsGlobalProxy';
import { Heading, Flex } from '@postman/aether';

class SettingsCustomProxy extends Component {
  render () {
    const { t } = this.props;
    return (
      <Flex direction='column' gap='spacing-l'>
        <Heading text={t('settings:proxy.label')} type='h2' />
        {
          (__SDK_PLATFORM__ !== 'browser') &&
            <SettingsSystemProxy />
        }
        <SettingsGlobalProxy
          useSystemProxy={this.props.useSystemProxy}
          onToggleSystemProxy={this.props.onToggleSystemProxy}
          useProxyEnvironmentVariables={this.props.useProxyEnvironmentVariables}
          onToggleProxyEnvironmentVariables={this.props.onToggleProxyEnvironmentVariables}
          proxy={this.props.globalProxy}
          errors={this.props.errors.globalProxy}
          onSave={this.props.onSaveGlobalProxy}
          onUpdate={this.props.onUpdateGlobalProxy}
        />
      </Flex>
    );

  }
}

SettingsCustomProxy.propTypes = {
  globalProxy: PropTypes.object.isRequired,
  onSaveGlobalProxy: PropTypes.func.isRequired,
  onToggleSystemProxy: PropTypes.func.isRequired,
  onUpdateGlobalProxy: PropTypes.func.isRequired,
  useSystemProxy: PropTypes.bool.isRequired
};

SettingsCustomProxy.defaultProps = {
  onSaveGlobalProxy: _.noop,
  onToggleSystemProxy: _.noop,
  onUpdateGlobalProxy: _.noop,
  errors: { globalProxy: {} },
  useSystemProxy: true
};

export default withTranslation('settings')(SettingsCustomProxy);
