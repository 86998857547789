import CollectionController from '@@runtime-repl/collection/datastores/controllers/CollectionController';
import EnvironmentController from '@@runtime-repl/environment/datastores/controllers/EnvironmentController';
import GlobalsController from '@@runtime-repl/globals/datastores/controllers/GlobalsController';
import HeaderPresetsController from '@@runtime-repl/header-preset/datastores/controllers/HeaderPresetController';
import WorkspaceController from '../../modules/controllers/WorkspaceController';
import UserController from '../../modules/controllers/UserController';
import environmentModel from '../../modules/export/environment';
import headerPresetModel from '../../modules/export/header-preset';
import { saveAndOpenFile, STATE } from '../../models/services/filesystem';
import util from '../../utils/util';
import exportSingle from '../../modules/export/export-single';
import JSONFileService from '../../modules/services/JSONFileService';

const COLLECTION = 'collection',
      DEFAULT_WORKSPACE_NAME = 'Workspace',
      GLOBALS_NAME_SUFFIX = ' - globals',
      TAB = '\t',
      EXPORTED_FILENAME = 'Backup.postman_dump.json',
      EXPORTED_FILETYPE = 'application/json';

export const EXPORT_STATE = {
  SUCCESS: STATE.SUCCESS,
  ABORTED: STATE.ABORTED
};

/**
 * Exports a model (collection, environment or globals)
 * @param {Object} options
 * @param {String} options.exportLevel One of 'all' or 'user'
 * @param {String} [options.filePath] Path to save the data dump. Optional. If not provided a native prompt will be shown.
 * @returns {Promise<Object>} which resolves in an object having version, collections, environments, globals and headerPresets
 */
export default function (options = {}) {
  let userId,
      dump = {
        version: 1,
        collections: [],
        environments: [],
        headerPresets: [],
        globals: []
      },
      workspaces,
      dumpCollections,
      dumpEnvironments,
      dumpGlobals,
      dumpHeaderPresets;

  dumpCollections = function () {
    return Promise.resolve()

      // Get all the collections from db
      .then(() => {
        return CollectionController.getCollections();
      })

      // filter the collection for the user (only if the exportLevel is user)
      .then((collections) => {
        if (options.exportLevel !== 'user') {
          return collections;
        }

        return _.filter(collections, ['owner', userId]);
      })

      // Populate individual collections
      .then((collections) => {
        return Promise.all(_.map(collections, (collection) => {
          return exportSingle(COLLECTION, { id: collection.id });
        }));
      })

      // map the output of exportSingle into array of collections
      .then((exportedCollections) => {
        return _(exportedCollections).map((data) => {
          return data && data.json;
        }).compact().value();
      })

      // Put the collections in the data-dump
      .then((collections) => {
        dump.collections = collections;
      });
  };

  dumpEnvironments = function () {
    return Promise.resolve()

      // Get all the environments from db
      .then(() => {
        return EnvironmentController.getAll();
      })

      // filter the environments for the user (only if the exportLevel is user)
      .then((environments) => {
        if (options.exportLevel !== 'user') {
          return environments;
        }

        return _.filter(environments, ['owner', userId]);
      })

      // sanitize the environments
      .then((environments) => {
        return _.map(environments, (env) => {
          return environmentModel.sanitize(env);
        });
      })

      // Put the environments in the data-dump
      .then((environments) => {
        dump.environments = environments;
      });
  };

  dumpGlobals = function () {
    return Promise.resolve()

      // Get all the globals from db
      .then(() => {
        return GlobalsController.getAll();
      })

      // Convert globals to environments
      .then((globals) => {
        return _.map(globals, (globalsRecord) => {
          let workspace = _.find(workspaces, ['id', globalsRecord.workspace]),
              workspaceName = _.get(workspace, 'name') || DEFAULT_WORKSPACE_NAME,
              environmentName = workspaceName + GLOBALS_NAME_SUFFIX;

          return {
            id: util.guid(),
            name: environmentName,
            values: globalsRecord.values
          };
        });
      })

      // Sanitize the globals but using environmentModel
      // since the globals were converted to environments
      .then((globals) => {
        return _.map(globals, (globalsRecord) => {
          return environmentModel.sanitize(globalsRecord);
        });
      })

      // Put the globals in the data-dump
      .then((globals) => {
        dump.globals = globals;
      });
  };

  dumpHeaderPresets = function () {
    return Promise.resolve()

      // Get all the headerPresets from db
      .then(() => {
        return HeaderPresetsController.getAll();
      })

      // filter the headerPresets for the user (only if the exportLevel is user)
      .then((headerPresets) => {
        if (options.exportLevel !== 'user') {
          return headerPresets;
        }

        return _.filter(headerPresets, ['owner', userId]);
      })

      // sanitize the headerPresets
      .then((headerPresets) => {
        return _.map(headerPresets, (headerPreset) => {
          return headerPresetModel.sanitize(headerPreset);
        });
      })

      // Put the headerPresets in the data-dump
      .then((headerPresets) => {
        dump.headerPresets = headerPresets;
      });
  };

  return Promise.resolve()

    // Get the user info
    .then(() => {
      return UserController.get();
    })

    // Cache the userId
    .then((user) => {
      userId = user.id;
    })

    // Get the workspaces
    .then(() => {
      return WorkspaceController.getAll();
    })

    // Cache the workspaces
    .then((workspacesFromDb) => {
      workspaces = workspacesFromDb;
    })

    // Wait for all the exports to finish
    .then(() => {
      return Promise.all([dumpCollections(), dumpEnvironments(), dumpGlobals(), dumpHeaderPresets()]);
    })

    // Move globals from dump.globals to dump.environments
    .then(() => {
      dump.environments = _.concat(dump.environments, dump.globals);
      dump.globals = [];
    })

    // Show a native prompt to save the file
    .then(() => {
      if (options.filePath) {
        return new Promise((resolve, reject) => {
          JSONFileService.write(options.filePath, dump, (err) => {
            if (err) {
              pm.logger.warn('ExportAll: Error while saving the data-dump to file', err);
              reject(err);
              return;
            }

            resolve(STATE.SUCCESS);
          });
        });
      }

      let serializedData = JSON.stringify(dump, null, TAB);

      return new Promise((resolve, reject) => {
        saveAndOpenFile(EXPORTED_FILENAME, serializedData, EXPORTED_FILETYPE, (err, state) => {
          if (err) {
            console.warn('Error while saving the data-dump', err);
            reject(err);
            return;
          }

          resolve(state);
        });
      });
    });
}
