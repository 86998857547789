import OnPremTokenService from '@@renderer/integrations/git/services/OnPrem/OnPremTokenService';
import AuthenticationInstanceService from '@@renderer/integrations/services/AuthenticationInstanceService';
import PlanFeaturesStore from '@postman-app-monolith/renderer/billing/stores/PlanFeaturesStore';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';

const CONNECTED_ACCOUNTS_TAB_DISPLAYED = 'isConnectedAccountTabDisplayed';

/**
 * Utility to determine if the connected account tab needs to be rendered in the settings page/modal
 * The tab is shown if the below criterion are met:
 * 1. The user has provided access tokens at least once (If the user has deleted the token, show the tab as they have seen it once).
 * 2. The user has added at least one team-scoped authentication instances which are whitelisted to be shown in the settings UI.
 * Please refer to the ALLOWED_AUTHENTICATION_CLASSES_FOR_SETTINGS_PAGE inside AuthenticationInstanceService.
 *
 * @returns {Boolean} True if the tab needs to be rendered in the settings page/modal, otherwise false
 */
const shouldShowConnectedAccountsTab = async () => {
  const planFeaturesStore = resolveStoreInstance(PlanFeaturesStore);
  const isIntegrationsEnabled = _.get(planFeaturesStore, ['plan', 'features', 'integrations_enabled', 'value'], true);
  if (!isIntegrationsEnabled) return false;

  // If the connected account tab was shown to the user once during the session , do not hide them.
  const wasTabViewedOnce = pm.settings.getSetting(CONNECTED_ACCOUNTS_TAB_DISPLAYED);

  if (wasTabViewedOnce) {
    return true;
  }


  try {
    // Git enterprise tokens will only be available in desktop apps
    const onPremGitTokens = __SDK_PLATFORM__ === 'browser' ? [] : OnPremTokenService.getTokens(),
      authInstanceData = await AuthenticationInstanceService.listAuthenticationInstancesForSettingsPage(),
      authInstances = _.get(authInstanceData, 'body.data'),
      allTokens = _.concat(onPremGitTokens, authInstances);

    // If the token is not empty - show the tab
    if (!_.isEmpty(allTokens)) {
      pm.settings.setSetting(CONNECTED_ACCOUNTS_TAB_DISPLAYED, true);
      return true;
    }
  } catch (err) {
    pm.logger.error('ConnectedAccountsUtil ~ shouldShowConnectedAccountsTab: Failed to fetch authentication instances', err);

    // In order to avoid inconsistency, show the tab
    // in case of any errors while fetching auth instances
    return true;
  }

  return false;
};

export { shouldShowConnectedAccountsTab };
