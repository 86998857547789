import {
  computed,
  observable,
  runInAction
} from 'mobx';
import { bootFirmware } from '@postman-app-monolith/renderer/appsdk/scratchpad/services/scratchpadMigrationBootFirmware';

export const IS_OFFLINE_API_CLIENT_ENABLED = 'offlineAPIClientEnabled';

class OfflineAPIClientService {
  @observable _isEnabled = false;

  @computed
  get isEnabled () {
    return this._isEnabled;
  }

  /**
   * Initialize the service.
   */
  async init () {
    if (__SDK_PLATFORM__ !== 'desktop') {
      return;
    }

    try {
      pm.logger.info('OfflineAPIClientService~init: started');
      const isOfflineAPIClientEnabled = pm.settings.getSetting(IS_OFFLINE_API_CLIENT_ENABLED);

      pm.logger.info('OfflineAPIClientService~init: settings cache isOfflineAPIClientEnabled', isOfflineAPIClientEnabled);

      if (typeof isOfflineAPIClientEnabled === 'boolean') {
        runInAction(() => {
          this._isEnabled = isOfflineAPIClientEnabled;
        });

        return;
      }

      pm.logger.info('OfflineAPIClientService~init: Boot started');

      await bootFirmware();
      const scratchpadSettingsString = await pm.migrator.localStorage.getItem('settings');

      if (!_.isEmpty(scratchpadSettingsString)) {
        const scratchpadSettings = JSON.parse(scratchpadSettingsString);
        const isEnabled = Boolean(scratchpadSettings[IS_OFFLINE_API_CLIENT_ENABLED]);

        pm.logger.info('OfflineAPIClientService~init: Boot done isOfflineAPIClientEnabled', isOfflineAPIClientEnabled);

        // set in the settings
        pm.settings.setSetting(IS_OFFLINE_API_CLIENT_ENABLED, isEnabled);

        runInAction(() => {
          this._isEnabled = isEnabled;
        });
      }
    }
    catch (err) {
      pm.logger.error('OfflineAPIClientService~init: errored', err, {
        context: pm.logger.getContext('OfflineAPIClientService', 'runtime')
      });
    }
  }
}

export default new OfflineAPIClientService();
