import React from 'react';
import { POSTMAN_THEMES_ENUM } from '../../themes/utils';

const ThemeIllustrationsMappings = {
  [POSTMAN_THEMES_ENUM.LIGHT]: {
      baseBrand: '#FF6C37',
      backgroundPrimary: '#FFFFFF',
      contentTertiary: '#A6A6A6',
      borderPrimary: '#F2F2F2',
      baseInfo: '#097BED',
      borderSecondary: '#EDEDED'
  },
  [POSTMAN_THEMES_ENUM.DARK]: {
      baseBrand: '#FF6C37',
      backgroundPrimary: '#212121',
      contentTertiary: '#6B6B6B',
      borderPrimary: '#303030',
      baseInfo: '#097BED',
      borderSecondary: '#2B2B2B'
  },
  [POSTMAN_THEMES_ENUM.DRACULA]: {
      baseBrand: '#FF61BA',
      backgroundPrimary: '#282A36',
      contentTertiary: '#7E82A0',
      borderPrimary: '#43465B',
      baseInfo: '#FF61BA',
      borderSecondary: '#363949'
  },
  [POSTMAN_THEMES_ENUM.MONOKAI]: {
      baseBrand: '#2497AD',
      backgroundPrimary: '#282923',
      contentTertiary: '#8A8E7B',
      borderPrimary: '#484A3F',
      baseInfo: '#38ADFF',
      borderSecondary: '#383A31'
  },
  [POSTMAN_THEMES_ENUM.AYU_LIGHT]: {
      baseBrand: '#F2A60D',
      backgroundPrimary: '#F8F9FA',
      contentTertiary: '#5C729D',
      borderPrimary: '#D8DDE8',
      baseInfo: '#F2A60D',
      borderSecondary: '#E5E9F0'
  },
  [POSTMAN_THEMES_ENUM.AYU_DARK]: {
      baseBrand: '#F2A60D',
      backgroundPrimary: '#0D1016',
      contentTertiary: '#5C729D',
      borderPrimary: '#2D384D',
      baseInfo: '#F2A60D',
      borderSecondary: '#222A3A'
  },
  [POSTMAN_THEMES_ENUM.HIGH_CONTRAST_LIGHT]: {
      baseBrand: '#E4460C',
      backgroundPrimary: '#FFFFFF',
      contentTertiary: '#7D7D7D',
      borderPrimary: '#7D7D7D',
      baseInfo: '#E4460C',
      borderSecondary: '#A6A6A6'
  },
  [POSTMAN_THEMES_ENUM.HIGH_CONTRAST_DARK]: {
      baseBrand: '#E4460C',
      backgroundPrimary: '#0D0D0D',
      contentTertiary: '#6E6E6E',
      borderPrimary: '#919191',
      baseInfo: '#E4460C',
      borderSecondary: '#7D7D7D'
  },
  [POSTMAN_THEMES_ENUM.NIGHT_OWL_LIGHT]: {
      baseBrand: '#2FA297',
      backgroundPrimary: '#FBFBFB',
      contentTertiary: '#5D7F9C',
      borderPrimary: '#D2D6DA',
      baseInfo: '#2FA297',
      borderSecondary: '#DBDBDB'
  },
  [POSTMAN_THEMES_ENUM.NIGHT_OWL_DARK]: {
      baseBrand: '#A958DF',
      backgroundPrimary: '#011727',
      contentTertiary: '#5D7F9C',
      borderPrimary: '#134977',
      baseInfo: '#A958DF',
      borderSecondary: '#0E3658'
  },
  [POSTMAN_THEMES_ENUM.SOLARIZED_LIGHT]: {
    baseBrand: '#A28620',
    backgroundPrimary: '#FCF6E5',
    contentTertiary: '#ABA592',
    borderPrimary: '#CFC9B5',
    baseInfo: '#A28620',
    borderSecondary: '#EBE5D1'
  },
  [POSTMAN_THEMES_ENUM.SOLARIZED_DARK]: {
    baseBrand: '#349D94',
    backgroundPrimary: '#002B36',
    contentTertiary: '#586E75',
    borderPrimary: '#274C57',
    baseInfo: '#349D94',
    borderSecondary: '#1A434E'
}
};

/**
 * Returns the illustration for different themes.
 */
export default function ThumbnailIllustration (props) {
  const theme = props.theme || POSTMAN_THEMES_ENUM.LIGHT;
  const themeColors = ThemeIllustrationsMappings[theme] || ThemeIllustrationsMappings[POSTMAN_THEMES_ENUM.LIGHT];

  return (
  <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 158 80'>
    <rect width='157' height='79' x='.5' y='.5' fill={themeColors.backgroundPrimary} rx='3.5' />
    <rect width='157' height='79' x='.5' y='.5' stroke={themeColors.borderPrimary} rx='3.5' />
    <path fill={themeColors.borderSecondary} fillRule='evenodd' d='M118 41H18a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h100a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1ZM94 6H64a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1ZM64 5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h30a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H64ZM18 40a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h100a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H18Z' clipRule='evenodd' />
    <path fill={themeColors.baseInfo} d='M126 42a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-5Z' />
    <path fill={themeColors.baseBrand} d='M16 62a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H17a1 1 0 0 1-1-1Z' />
    <path fill={themeColors.borderPrimary} d='M0 15h158v1H0v-1Z' />
    <g fill={themeColors.contentTertiary} opacity='.64'>
      <path d='M6 7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7ZM26 7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H27a1 1 0 0 1-1-1V7ZM16 29a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H17a1 1 0 0 1-1-1v-2ZM16 56a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H17a1 1 0 0 1-1-1v-2ZM32 56a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H33a1 1 0 0 1-1-1v-2ZM52 56a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H53a1 1 0 0 1-1-1v-2ZM136 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM126 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM146 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z' />
    </g>
    <path fill={themeColors.backgroundPrimary} fillRule='evenodd' d='M141.5 8a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm0-4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z' clipRule='evenodd' />
    <path fill={themeColors.baseBrand} d='M140 5.5a1.5 1.5 0 0 1 3 0 1.5 1.5 0 0 1-3 0Z' />
  </svg>
);
}
