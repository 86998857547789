/**
 *
 * Labels for different integrations
 * It can be `New`, `Noteworthy` and `Sponsored`
 *
 * @todo - to expect labels from server
 */
const INTEGRATION_LABELS = {
  apimatic: '',
  aws_api_gateway: '',
  bigpanda: '',
  bitbucket: 'new',
  coralogix: '',
  datadog: '',
  dropbox: '',
  github: 'new',
  gitlab: 'new',
  hipchat: '',
  keen_io: '',
  microsoft_flow: '',
  microsoft_teams: '',
  pagerduty: '',
  pm_pro_api: 'noteworthy',
  slack: '',
  victorops: '',
  custom: '',
  api_gateway_aws: 'new',
  api_gateway_apigee: 'new',
  circleci: '',
  travisci: '',
  devops_pipelines: 'new',
  apm_new_relic: 'new',
  jenkins: 'new',
  sentry: 'new'
};

/**
 * precedence order for different labels (new > noteworthy > sponsored)
 */
const INTEGRATION_LABEL_PRIORITY = {
  new: 1,
  noteworthy: 2,
  sponsored: 3
};

export { INTEGRATION_LABELS, INTEGRATION_LABEL_PRIORITY };
