import transformer from 'postman-collection-transformer';

// import CollectionController from '@@scratchpad-runtime-repl/collection/datastores/controllers/CollectionController';
import util from '../../../utils/util';
import { NO_BODY_METHODS } from '@@runtime-repl/request-http/RequestConstants';
import { REQUEST_DATA_MODE_GRAPHQL } from '@@runtime-repl/request-http/RequestDataModeConstants';
import { denormalizeRequest } from '../../../utils/RequestNormalizerHelper';

const DEPRECATED_PROPS = [
        'currentHelper',
        'helperAttributes',
        'tests',
        'preRequestScript'
      ],
      TAB = '\t',
      COLLECTION = 'collection',
      FILE_NAME_EXT = '.postman_collection.json',
      ARRAY_BODY_TYPES = ['params', 'urlencoded'],
      ALLOWED_VARIABLE_FIELDS = ['id', 'key', 'value', 'type', 'disabled', 'description'],
      ALLOWED_DATA_FIELDS = ['key', 'value', 'contentType', 'description', 'type', 'enabled'],
      ALLOWED_QUERY_PARAMS_FIELDS = ['key', 'value', 'equals', 'description', 'enabled'],
      ALLOWED_RESPONSE_HEADER_FIELDS = ['key', 'value', 'name', 'description', 'type'],
      COLLECTION_META_PROPS = ['owner', 'permissions', 'shared', 'favorite'],

      METHOD_GET = 'GET',
      DBP_FLAG = 'protocolProfileBehavior.disableBodyPruning',
      NO_BODY_METHODS_SET = new Set(NO_BODY_METHODS);

/**
 * Gets the populated collection from the db
 * @param {Object} criteria
 * @returns {Promise} Which resolves in the populated collection
 */
async function getFromDb (criteria) {
  let collection = await pm.migrator.models.collection.find(criteria);

  let newCollection = collection[0];
  let [folders, requests, responses] = await Promise.all([
        pm.migrator.models.folder.find({ collection: newCollection.id }),
        pm.migrator.models.request.find({ collection: newCollection.id }),
        pm.migrator.models.response.find({ collection: newCollection.id })
      ]);

      let reqMap = new Map();

      requests.forEach((req) => {
        reqMap.set(req.id, req);
      });

      responses.forEach((res) => {
        let request = reqMap.get(res.request);
        if (request) {
          request.responses = Array.isArray(request.responses) ? request.responses : [];

          request.responses.push(res);
        }
      });

      newCollection.folders = folders;
      newCollection.requests = requests;

  return newCollection;
}

/**
 * Sanitizes the collection-request and example-request fields
 * 1. Remove the timestamps
 * 2. Sanitize the body (convert the types)
 * 3. Sanitize the query params
 * 4. Remove unnecessary (but valid) fields
 * @param {Object} request
 */
function _sanitizeRequestFields (request) {
  // Bail out when request is falsy or is string (possible for example requests)
  if (!request || typeof request === 'string') {
    return;
  }

  let sanitizedRequest = _.clone(request);

  // remove the timestamps
  delete sanitizedRequest.createdAt;
  delete sanitizedRequest.updatedAt;

  // remove the local files references
  delete sanitizedRequest._postman_local_files;

  // Body of type form and urlencoded are only stored as array.
  // Do not sanitize body of type raw and binary
  let shouldSanitizeBody = _.includes(ARRAY_BODY_TYPES, sanitizedRequest.dataMode) && !_.isEmpty(sanitizedRequest.data),
      shouldSanitizeParams = _.isArray(sanitizedRequest.queryParams) && !_.isEmpty(sanitizedRequest.queryParams);

  if (shouldSanitizeBody) {
    if (_.isArray(sanitizedRequest.data)) {
      sanitizedRequest.data = _.map(sanitizedRequest.data, (value) => {
        return _.pick(value, ALLOWED_DATA_FIELDS);
      });
    } else {
      // It's possible that a request was saved with body type form/url-encoded and then later the type was changed to raw/binary.
      // In that case if the actual data wasn't reset we do it now to avoid exporting incorrect data
      sanitizedRequest.data = [];
    }
  }

  if (shouldSanitizeParams) {
    sanitizedRequest.queryParams = _.map(sanitizedRequest.queryParams, (value) => {
      return _.pick(value, ALLOWED_QUERY_PARAMS_FIELDS);
    });
  }

  let methodAllowsAllowBody = !NO_BODY_METHODS_SET.has(sanitizedRequest.method || METHOD_GET),
      noBodyPresent = !sanitizedRequest.dataMode,
      isDbpFalsy = !_.get(sanitizedRequest, DBP_FLAG);

  // remove DBP flag if it's unnecessary
  if (methodAllowsAllowBody || noBodyPresent || isDbpFalsy) {
    _.unset(sanitizedRequest, 'protocolProfileBehavior.disableBodyPruning');
  }

  // remove protocolProfileBehavior if it's unnecessary (even if DBP is removed, there can be other flags here in future)
  _.isEmpty(sanitizedRequest.protocolProfileBehavior) && _.unset(sanitizedRequest, 'protocolProfileBehavior');

  return sanitizedRequest;
}

/**
 * Sanitizes the response fields
 * @param {Object} request
 */
function _sanitizeResponseFields (response) {
  if (!response) {
    return;
  }

  let sanitizedResponse = _.clone(response),
      shouldSanitizeHeaders = _.isArray(response.headers) && !_.isEmpty(response.headers);

      sanitizedResponse.requestObject && (sanitizedResponse.requestObject = _sanitizeRequestFields(sanitizedResponse.requestObject));

  delete sanitizedResponse.createdAt;
  delete sanitizedResponse.updatedAt;

  if (!shouldSanitizeHeaders) {
    return sanitizedResponse;
  }

  sanitizedResponse.headers = _.map(sanitizedResponse.headers, (value) => {
    return _.pick(value, ALLOWED_RESPONSE_HEADER_FIELDS);
  });


  return sanitizedResponse;
}

/**
 * Sanitizes the collection variables.
 * @param {Array<Object>} variables
 * @returns {Array<Object>} Sanitized collection variables
 */
function _sanitizeVariables (variables) {
  if (_.isEmpty(variables)) {
    return [];
  }

  return _.map(variables, (variable) => {
    let disabled = false;

    // While exporting, we give more preference to enabled field (unlike while importing)
    // since we are exporting from app format in which enabled is the valid field
    if (typeof variable.enabled !== 'undefined') {
      disabled = !variable.enabled;
    }
    else if (typeof variable.disabled !== 'undefined') {
      disabled = Boolean(variable.disabled);
    }

    return _.pick(_.merge({}, variable, { disabled }), ALLOWED_VARIABLE_FIELDS);
  });
}

/**
 * Sanitizes the collection object recursively for exporting
 * @param {Object} collection
 * @returns {Object} The sanitized collection
 */
function sanitize (collection) {
  let sanitizedCollection = _.cloneDeep(collection);

  sanitizedCollection.requests = _.map(sanitizedCollection.requests, (request) => {
    // sanitize the request
    let sanitizedRequest = _sanitizeRequestFields(request);

    // sanitize its responses
    if (_.isArray(sanitizedRequest.responses) && !_.isEmpty(sanitizedRequest.responses)) {
      sanitizedRequest.responses = _.map(sanitizedRequest.responses, _sanitizeResponseFields);
    }

    return sanitizedRequest;
  });

  return sanitizedCollection;
}

/**
 * Sanitize request body
 * @param {Object} request - Input or IndexedDB value.
 */
function _sanitizeRequestBody (request) {
  if (request.dataMode === 'raw' || request.dataMode === 'binary') {
    request.rawModeData = request.data;
    request.data = [];
  }
  else if (request.dataMode === REQUEST_DATA_MODE_GRAPHQL) {
    request.graphqlModeData = request.data;
    request.data = [];
  }
  else if (request.dataMode === 'params') {
    // file formData rows has a single file then set value to a string
    _.each(request.data, function (dataRow) {
      if (dataRow.type === 'file' && _.isArray(dataRow.value) && dataRow.value.length === 1) {
        dataRow.value = dataRow.value[0];
      }
    });
  }
  if (request.rawModeData) {
    // to prevent rawModeData being sent as ["text"] instead of "text"
    if ((request.rawModeData instanceof Array) &&
      request.rawModeData.length == 1) {
      request.rawModeData = request.rawModeData[0];
    }
    if (typeof request.rawModeData !== 'string') {
      request.rawModeData = '';
    }
  }
}

/**
 * This converts the collection from app-format to v1 format
 * @param {Object} collection
 * @param {Object} options - Additional options for the export
 * @returns {Object} The collection in v1 format
 */
function _transformToV1 (collection, options) {
  let requests = collection.requests,
      requestIdsAdded = [];

  // sanitize the variables
  collection.variables = _sanitizeVariables(collection.variables);

  // sort data.folders
  collection.folders = _.sortBy(collection.folders, 'name');

  for (let i = 0, count = requests.length; i < count; i++) {
    if (requestIdsAdded.indexOf(requests[i].id) !== -1) {
      // this request is already there
      continue;
    }

    requests[i] = _.omit(requests[i], DEPRECATED_PROPS);

    // At present this code is not needed as anyway db will have both old and new props,
    // But when we remove the old props support, still we need to support them in v1 export
    // thus the reason for using denormalize is
    // When we remove the properties from DB itself means, still this code needs to work.
    // This means it is v1 format
    if (!options) {
      denormalizeRequest(requests[i]);
      requests[i].collectionId = collection.id;
    }

    requestIdsAdded.push(requests[i].id);
    requests[i].synced = false;

    _sanitizeRequestBody(requests[i]);

    if (requests[i].responses instanceof Array) {

      // delete duplicate responses while exporting
      requests[i].responses = _.uniqBy(requests[i].responses, 'id');

      // sanitize requestObject in response
      requests[i].responses = _.map(requests[i].responses, (response) => {
        if (response && response.requestObject) {
          _sanitizeRequestBody(response.requestObject);
        }

        return response;
      });
    }

    if (_.isArray(requests[i].headerData)) {
      requests[i].headers = util.packHeaders(requests[i].headerData);
    }

    if (_.isArray(requests[i].pathVariableData)) {
      let pathVariables = {};
      _.forEach(requests[i].pathVariableData, (datum) => {
        pathVariables[datum.key] = datum.value;
      });
      requests[i].pathVariables = pathVariables;
    }

    var propsToRemove = ['write', 'synced', 'collectionOwner', 'createdAt', 'updatedAt', 'owner', 'lastUpdatedBy', 'lastRevision', 'history', 'collection'];
    _.each(propsToRemove, function (prop) {
      delete requests[i][prop];
    });
  }

  if (!options) {
    let numFolders = (collection.folders && (collection.folders instanceof Array)) ? collection.folders.length : 0;

    for (let i = 0; i < numFolders; i++) {
      // V1 format support
      collection.folders[i].collectionId = collection.folders[i].collection;
      collection.folders[i].folderId = collection.folders[i].id;
    }
  }

  // remove all meta properties from collection
  // instead of deleting all the meta properties, we should whitelist collection V1 schema properties
  _.forEach(COLLECTION_META_PROPS, function (metaProp) {
    delete collection[metaProp];
  });

  collection.requests = requests;
  return collection;
}

/**
 * This transforms the collection into the exportable format
 * First it converts the collection to v1 format, then optionally to v2/v2.1 format
 * @param {Object} collection
 * @param {Object} context
 * @returns {Promise} Which resolves in the transformed collection
 */
function transform (collection, context) {
  let options = context.exportOptions,
      collectionV1 = _transformToV1(collection, options);

  if (!(options && options.outputVersion && options.inputVersion && options.outputVersion !== '1.0.0')) {
    return Promise.resolve(collectionV1);
  }

  return new Promise((resolve, reject) => {
    transformer.convert(collectionV1, options, (err, response) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(response);
    });
  });
}

/**
 * Returns the entity name
 * @param {Object} collection
 */
function getEntityName (collection) {
  return collection.name || COLLECTION;
}

/**
 * Returns the fileName that can be used if exporting to a file
 * @param {Object} collection
 */
function getFileName (collection) {
  return this.getEntityName(collection) + FILE_NAME_EXT;
}

/**
 * Serializes the collection
 * @param {Object} collection
 * @returns {String} Stringified collection
 */
function serialize (collection) {
  return JSON.stringify(collection, null, TAB);
}

export default {
  getFromDb,
  sanitize,
  transform,
  getEntityName,
  getFileName,
  serialize
};
