import { action, observable, reaction, computed } from 'mobx';
import {
  NETWORK_CONNECTION_MODE_POLLING,
  NETWORK_CONNECTION_MODE_WEBSOCKET,
  ENABLE_LONG_POLLING_TRANSPORT_KEY,
  PRE_POLLING_SOCKET_ATTEMPTS_KEY,
  FIRST_APPLOAD_PRE_POLLING_SOCKET_ATTEMPTS_KEY,
  NETWORK_CONNECTION_MODE_KEY,
  RESTORE_SOCKET_CONNECTION_MODE_EVENT,
  FORCE_POLLING_CONNECTION_MODE_EVENT,
  NETWORK_SETTINGS_CATEGORY,
  FIRST_APPLOAD_ENABLE_LONG_POLLING_TRANSPORT_KEY,
  ENABLE_CONNECTION_MODE_SETTINGS_KEY
} from '../constants/ConnectionModeConstants';
import CurrentUserDetailService from '../services/CurrentUserDetailsService';

import { createEvent } from '../common/model-event';
import { launchDarkly } from '../../onboarding/src/common/LaunchDarkly';
import AnalyticsService from '../modules/services/AnalyticsService';

const getSettingPartitionKey = () => {
  if (__SDK_PLATFORM__ !== 'browser') {
    return 'settings';
  }
  return (
    CurrentUserDetailService &&
    CurrentUserDetailService.partitionId &&
    `settings-${CurrentUserDetailService.partitionId}`
  );
};

const getSettingsFromSettingsStore = () => {
  try {
    const settingsDataString = localStorage.getItem(getSettingPartitionKey());
    if (!settingsDataString) {
      return null;
    }
    return JSON.parse(settingsDataString);
  } catch (ex) {
    return null;
  }
};

/**
 * Get value from Local Settings Store
 * @param {*} key Key to fetch from Local Settings
 * @param {*} defaultValue Default value for the value stored in Settings
 * @returns {*} value stored in Settings LocalStorage against the key
 */
const getValueFromSettingStore = (key, defaultValue = null) => {
  try {
    const settingsMap = getSettingsFromSettingsStore();
    if (settingsMap === null || !settingsMap.hasOwnProperty(key)) {
      return defaultValue;
    }
    return settingsMap[key];
  } catch (ex) {
    return defaultValue;
  }
};

const setValueInSettingStore = (key, value) => {
  if (pm.settings && typeof pm.settings.setSetting === 'function') {
    return pm.settings.setSetting(key, value);
  }
  let settings = getSettingsFromSettingsStore();
  if (settings === null) {
    settings = {};
  }
  settings[key] = value;
  return localStorage.setItem(
    getSettingPartitionKey(),
    JSON.stringify(settings)
  );
};

/**
 * Helper utility to GET values from sessionStorage
 * @param {string} key Key to query sessionStorage
 * @param {*} defaultValue Default value to return if key not present
 * @returns {*} value stored in sessionStorage against the key
 */
const getValueFromSessionStore = (key, defaultValue) => {
  const value = sessionStorage.getItem(key);
  if (value === null) {
    setValueInSessionStore(key, defaultValue);
    return defaultValue;
  }
  try {
    return JSON.parse(value);
  } catch (ex) {
    return null;
  }
};

/**
 * Helper utility to SET values in sessionStorage
 * @param {string} key Key to set in sessionStorage
 * @param {*} value Value to store in sessionStorage against the key
 * @returns {*}
 */
const setValueInSessionStore = (key, value) => {
  return sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * This utility helps to figure out if the current app session is a first app install session
 * returns {boolean} true if its first app install session
 */
const isFirstAppLoad = () => {
  if (__SDK_PLATFORM__ === 'desktop') {
    const isFirstLoad = require('@electron/remote').app.firstLoad;
    pm.logger &&
      pm.logger.info('ConnectionModeStore~_isFirstLoad', isFirstLoad);
    return isFirstLoad;
  }
  return false;
};

class ConnectionModeStore {
  @observable connectionMode;
  @observable isPollingModeEnabled;
  @observable maxSocketAttempts;
  @observable automaticModeSwitching;
  @observable isConnecting;
  @observable isPollingEnabledForAllAppLoads;
  @observable enableConnectionModeSettings;

  constructor () {
    // Default value of -1 means unlimited maximum attempts
    this.maxSocketAttempts = getValueFromSessionStore(
      PRE_POLLING_SOCKET_ATTEMPTS_KEY,
      -1
    );

    // Default value of false to ensure that only users who are targetted via
    // launchDarkly flag get into the experiment
    this.isPollingModeEnabled = getValueFromSessionStore(
      ENABLE_LONG_POLLING_TRANSPORT_KEY,
      false
    );

    this.enableConnectionModeSettings = getValueFromSettingStore(
      ENABLE_CONNECTION_MODE_SETTINGS_KEY,
      false
    );

    // This flag takes care of enabling/disabling automated mode switching based on attempts.
    // This is used in cases where the socket is connected atleast once in a session
    this.automaticModeSwitching = true;
    this.connectionMode = this.getConnectionModeSetting();
    this._setupLaunchDarkly();
  }

  getConnectionModeSetting () {
    return (
      (pm.settings && pm.settings.getSetting(NETWORK_CONNECTION_MODE_KEY)) ||

      // In case the pm.settings is not initialized, we read from Settings
      // Store (localstorage) directly
      getValueFromSettingStore(
        NETWORK_CONNECTION_MODE_KEY,
        NETWORK_CONNECTION_MODE_WEBSOCKET
      )
    );
  }

  publishConnectionModeChangeToSync (connectionMode) {
    const syncInternalChannel =
      pm.eventBus && pm.eventBus.channel('sync-manager-internal');
    if (!syncInternalChannel) {
      return;
    }

    if (connectionMode === NETWORK_CONNECTION_MODE_POLLING) {
      syncInternalChannel.publish(
        createEvent(
          FORCE_POLLING_CONNECTION_MODE_EVENT,
          NETWORK_CONNECTION_MODE_KEY
        )
      );
    }
    if (connectionMode === NETWORK_CONNECTION_MODE_WEBSOCKET) {
      syncInternalChannel.publish(
        createEvent(
          RESTORE_SOCKET_CONNECTION_MODE_EVENT,
          NETWORK_CONNECTION_MODE_KEY
        )
      );
    }
  }

  /**
   * This function creates a reaction to subscribe to connectionMode changes
   * @returns {*} disposer to clean-up the subscription set
   */
  setupReaction () {
    const connectionSettingDisposer = reaction(
      () => this.connectionMode,
      (connectionMode) => {
        setValueInSettingStore(
          NETWORK_CONNECTION_MODE_KEY,
          this.connectionMode
        );
        this.publishConnectionModeChangeToSync(connectionMode);
      }
    );
    return connectionSettingDisposer;
  }

  /**
   * Initializing the flags from launchDarkly
   * As LaunchDarkly is a non-blocking call, this relies on sessionStorage to
   * set flags in the meantime, and resets once LaunchDarkly is ready
   */
  _setupLaunchDarkly () {
    launchDarkly.onInitialization().then(() => {
      let isPollingModeEnabled = launchDarkly.getFlag(
        ENABLE_LONG_POLLING_TRANSPORT_KEY,
        false
      );
      let maxAttempts = launchDarkly.getFlag(
        PRE_POLLING_SOCKET_ATTEMPTS_KEY,
        -1
      );
      let enableConnectionModeSettings = launchDarkly.getFlag(
        ENABLE_CONNECTION_MODE_SETTINGS_KEY,
        false
      );

      this.setEnableConnectionModeSettings(enableConnectionModeSettings);
      setValueInSettingStore(
        ENABLE_CONNECTION_MODE_SETTINGS_KEY,
        enableConnectionModeSettings
      );

      // Reset the ConnectionMode to default
      this.publishConnectionModeChangeToSync(
        enableConnectionModeSettings
          ? this.connectionMode
          : NETWORK_CONNECTION_MODE_WEBSOCKET
      );

      this.setIsPollingEnabledForAllAppLoads(isPollingModeEnabled);

      // analytics for feature flags
      AnalyticsService.addEventV2(
        {
          category: NETWORK_SETTINGS_CATEGORY,
          action: 'feature_flags_initial',
          label: isPollingModeEnabled.toString(),
          value: maxAttempts
        },
        { noActiveWorkspace: true }
      );

      // Override flag values for Firstload users with respective flags
      if (isFirstAppLoad()) {
        isPollingModeEnabled = launchDarkly.getFlag(
          FIRST_APPLOAD_ENABLE_LONG_POLLING_TRANSPORT_KEY,
          isPollingModeEnabled
        );
        maxAttempts = launchDarkly.getFlag(
          FIRST_APPLOAD_PRE_POLLING_SOCKET_ATTEMPTS_KEY,
          maxAttempts
        );

        // analytics for first app install
        AnalyticsService.addEventV2(
          {
            category: NETWORK_SETTINGS_CATEGORY,
            action: 'first_app_install',
            label: isPollingModeEnabled.toString(),
            value: maxAttempts
          },
          { noActiveWorkspace: true }
        );
      }
      this.setIsPollingModeEnabled(isPollingModeEnabled);
      this.setMaxSocketAttempts(maxAttempts);
      reaction(
        () => launchDarkly.flags.get(ENABLE_LONG_POLLING_TRANSPORT_KEY),
        (pollingEnabled) => {
          if (pollingEnabled !== undefined) {
            this.setIsPollingModeEnabled(pollingEnabled);
            this.setIsPollingEnabledForAllAppLoads(pollingEnabled);

            // Reset the ConnectionMode to default
            if (pollingEnabled === false) {
              // Reset the ConnectionMode to default
              this.publishConnectionModeChangeToSync(
                this.enableConnectionModeSettings
                  ? this.connectionMode
                  : NETWORK_CONNECTION_MODE_WEBSOCKET
              );
            }

            AnalyticsService.addEventV2(
              {
                category: NETWORK_SETTINGS_CATEGORY,
                action: 'feature_flags_update_enable_polling_transport',
                label: pollingEnabled.toString()
              },
              { noActiveWorkspace: true }
            );
          }
        }
      );
      reaction(
        () => launchDarkly.flags.get(PRE_POLLING_SOCKET_ATTEMPTS_KEY),
        (maxAttempts) => {
          if (maxAttempts !== undefined && !isFirstAppLoad()) {
            this.setMaxSocketAttempts(maxAttempts);

            AnalyticsService.addEventV2(
              {
                category: NETWORK_SETTINGS_CATEGORY,
                action: 'feature_flags_update_attempt',
                label: maxAttempts.toString()
              },
              { noActiveWorkspace: true }
            );
          }
        }
      );
      reaction(
        () =>
          launchDarkly.flags.get(FIRST_APPLOAD_PRE_POLLING_SOCKET_ATTEMPTS_KEY),
        (firstLoadMaxAttempts) => {
          if (firstLoadMaxAttempts !== undefined && isFirstAppLoad()) {
            this.setMaxSocketAttempts(firstLoadMaxAttempts);

            AnalyticsService.addEventV2(
              {
                category: NETWORK_SETTINGS_CATEGORY,
                action: 'feature_flags_update_new_user_attempt',
                label: firstLoadMaxAttempts.toString()
              },
              { noActiveWorkspace: true }
            );
          }
        }
      );
      reaction(
        () =>
          launchDarkly.flags.get(
            FIRST_APPLOAD_ENABLE_LONG_POLLING_TRANSPORT_KEY
          ),
        (enableFirstloadPolling) => {
          if (enableFirstloadPolling !== undefined && isFirstAppLoad()) {
            this.setIsPollingModeEnabled(enableFirstloadPolling);

            AnalyticsService.addEventV2(
              {
                category: NETWORK_SETTINGS_CATEGORY,
                action:
                  'feature_flags_update_new_user_enable_polling_transport',
                label: enableFirstloadPolling.toString()
              },
              { noActiveWorkspace: true }
            );
          }
        }
      );
      reaction(
        () => launchDarkly.flags.get(ENABLE_CONNECTION_MODE_SETTINGS_KEY),
        (enableConnectionModeSettings) => {
          if (enableConnectionModeSettings !== undefined) {
            setValueInSettingStore(
              ENABLE_CONNECTION_MODE_SETTINGS_KEY,
              enableConnectionModeSettings
            );
            this.setEnableConnectionModeSettings(enableConnectionModeSettings);

            // Reset the ConnectionMode to default
            this.publishConnectionModeChangeToSync(
              enableConnectionModeSettings
                ? this.connectionMode
                : NETWORK_CONNECTION_MODE_WEBSOCKET
            );

            AnalyticsService.addEventV2(
              {
                category: NETWORK_SETTINGS_CATEGORY,
                action: 'feature_flags_update_enable_connection_mode_settings',
                label: enableConnectionModeSettings.toString()
              },
              { noActiveWorkspace: true }
            );
          }
        }
      );
    });
  }

  /**
   * This action disables automated connection mode switching
   * This is needed when a websocket connection is successful
   * @TODO This settings doesn't work across browser tabs
   */
  @action
  disableAutoSwitch () {
    this.automaticModeSwitching = false;
  }

  /**
   * This action enables Connection Mode Selection as experiment
   * @param {boolean} value Enable or disable Multiple Connection Modes
   */
  @action
  setIsPollingModeEnabled (value = false) {
    this.isPollingModeEnabled = value;
    setValueInSessionStore(
      ENABLE_LONG_POLLING_TRANSPORT_KEY,
      this.isPollingModeEnabled
    );
  }

  /**
   * This action sets Max Attempts value in store
   * @param {Number} value MaxAttempts of WebSocket connection before falling
   * back to long polling
   */
  @action
  setConnectionMode (mode = NETWORK_CONNECTION_MODE_WEBSOCKET) {
    const isValidMode = [
      NETWORK_CONNECTION_MODE_POLLING,
      NETWORK_CONNECTION_MODE_WEBSOCKET
    ].includes(mode);
    if (isValidMode && mode !== this.connectionMode) {
      this.connectionMode = mode;
    }
  }

  /**
   * This action sets Max Attempts value in store
   * @param {Number} value MaxAttempts of WebSocket connection before falling
   * back to long polling
   * The value -1 signifies unlimited attempts
   */
  @action
  setMaxSocketAttempts (value = -1) {
    this.maxSocketAttempts = value;
    setValueInSessionStore(
      PRE_POLLING_SOCKET_ATTEMPTS_KEY,
      this.maxSocketAttempts
    );
  }

  /**
   * This action sets connecting flag, when connection is being attempted
   * @param {boolean} value flag notifying if the connection is being attempted
   */
  @action
  setIsConnecting (value = false) {
    this.isConnecting = value;
  }

  /**
   * This action sets flag to enable showing the ConnectionMode Settings in the
   * settings menu
   * @param {boolean} value flag enabling/disabling the connectionMode Setting UX
   */
  @action
  setIsPollingEnabledForAllAppLoads (value = false) {
    this.isPollingEnabledForAllAppLoads = value;
  }

  /**
   * This action sets flag to enable showing the ConnectionMode Settings in the
   * settings menu
   * @param {boolean} value flag enabling/disabling the connectionMode Setting UX
   */
  @action
  setEnableConnectionModeSettings (value = false) {
    this.enableConnectionModeSettings = value;
  }

  /**
   * This computed value returns true if connection mode is default (websocket)
   */
  @computed
  get isDefaultMode () {
    return this.connectionMode === NETWORK_CONNECTION_MODE_WEBSOCKET;
  }

  /**
   * This computed value returns true if connection mode is forced polling
   */
  @computed
  get isForcePollingMode () {
    return this.connectionMode === NETWORK_CONNECTION_MODE_POLLING;
  }

  /**
   * This computed value denotes if polling connection mode should be force-used
   */
  @computed
  get forcePollingMode () {
    return (
      this.enableConnectionModeSettings &&
      this.getConnectionModeSetting() === NETWORK_CONNECTION_MODE_POLLING
    );
  }

  /**
   * This method evaluates if the connection mode can be automatically switched
   * to polling, by checking if the socket connection failed for more than
   * configured maxAttempts
   * @param {*} attemptNumber Scheduled websocket attemptNumber
   * @returns {boolean} true if connection mode should be switched
   */
  canUsePollingForAttempt (attemptNumber, connectionMode) {
    if (!this.isPollingModeEnabled) {
      return false;
    }
    if (
      this.forcePollingMode ||
      connectionMode === NETWORK_CONNECTION_MODE_POLLING
    ) {
      return true;
    }
    if (!this.automaticModeSwitching) {
      return false;
    }
    return this.maxSocketAttempts > 0 && attemptNumber > this.maxSocketAttempts;
  }

  get pollingServerURL () {
    return pm.config && pm.config.get('__WP_POLLING_SERVER_URL__');
  }
}

/**
 * Ensuring singleton store
 */
export default new ConnectionModeStore();
