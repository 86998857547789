import React, { Component } from 'react';
import classnames from 'classnames';
import { Input } from './Inputs';
import { AutoSuggestInput } from '../base/AutoSuggest/AutoSuggestInput';
import { Button } from './Buttons';
import PropTypes from 'prop-types';
import { Icon } from '@postman/aether-icons';

export default class PasswordInput extends Component {
  constructor (props) {
    super(props);

    this.state = {
      masked: true,
      error: '',
      warning: ''
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.handleValidationState(nextProps);
  }

  handleToggle () {
    this.setState({ masked: !this.state.masked });
  }

  handleValidationState (nextProps) {
    // Show warning or error message, if input is not valid
    const { validation, value } = nextProps;

    if (validation) {
      let validationType, validationMessage;

      for (let i = 0; i < validation.length; i++) {
        const { validator, message, type } = validation[i] || {};

        if (typeof validator !== 'function') {
          continue;
        }

        if (!validator(value, nextProps)) {
          validationType = type;
          validationMessage = message;
          break;
        }
      }

      if (validationType) {
        return (this.state[validationType] !== validationMessage) && this.setState({ [validationType]: validationMessage });
      }
    }

    this.setState({
      error: '',
      warning: ''
    });
  }

  getValidationIcon () {
    if (this.state.error) {
      return (
        <Button type='icon' className='password-input__error-btn' tooltip={this.state.error}>
          <Icon name='icon-state-error-stroke' size='small' />
        </Button>
      );
    }

    if (this.state.warning) {
      return (
        <Button type='icon' className='password-input__warning-btn' tooltip={this.state.warning}>
          <Icon name='icon-state-warning-stroke' size='small' />
        </Button>
      );
    }
  }

  getPasswordInput (inputProps) {
    if (!this.props.enableVariableSuggestions) {
      return (
        <Input
          {...inputProps}
          type={this.state.masked ? 'password' : 'text'}
        />
      );
    }

   return this.state.masked ? (
      <Input
        {...inputProps}
        type='password'
      />
    ) : (
      <AutoSuggestInput
        {...inputProps}
        enableVariableSuggestions
        type='text'
      />
    );
  }

  render () {
    let { className, ...inputProps } = this.props;

    return (
      <div className={classnames('password-input', className)}>
        {this.getPasswordInput(inputProps)}
        <div className='password-input-message'>
          {this.getValidationIcon()}
        </div>
        <div
          className='password-input__toggle'
          onClick={this.handleToggle}
        >
          {
            this.state.masked ?
            <Icon name='icon-action-hide-stroke' title='Show' /> :
            <Icon name='icon-action-view-stroke' title='Hide' />
          }
        </div>
      </div>
    );
  }
}

PasswordInput.propTypes = {
  className: PropTypes.string,
  inputStyle: PropTypes.oneOf(['box', 'line']),
  size: PropTypes.oneOf(['huge']),
  enableVariableSuggestions: PropTypes.bool,
  validation: PropTypes.arrayOf(PropTypes.shape({
    validator: PropTypes.func.isRequired,
    message: PropTypes.string,
    type: PropTypes.oneOf(['error', 'warning'])
  }))
};

PasswordInput.defaultProps = {
  enableVariableSuggestions: false
};
