import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs, Tab } from '../base/Tabs';
import { Text } from '@postman/aether';
import {
  SETTINGS_GENERAL,
  SETTINGS_THEMES,
  SETTINGS_SHORTCUTS,
  SETTINGS_DATA,
  SETTINGS_ADDONS,
  SETTINGS_SYNC,
  SETTINGS_CERT,
  SETTINGS_PROXY,
  SETTINGS_UPDATE,
  SETTINGS_ABOUT,
  SETTINGS_DEV_OPTIONS,
  SETTINGS_CONNECTED_ACCOUNTS
} from '../../constants/SettingsTypeConstants';
import {
  IconDescriptiveMagicStrokeSmall,
  IconDescriptiveCertificateStrokeSmall,
  IconDescriptiveConnectedAccountsStrokeSmall,
  IconDescriptiveDataStrokeSmall,
  IconDescriptiveDeveloperStrokeSmall,
  IconDescriptivePostmanStrokeSmall,
  IconDescriptiveProxyStrokeSmall,
  IconDescriptiveSettingStrokeSmall,
  IconDescriptiveShortcutsStrokeSmall,
  IconDescriptiveSystemUpdateStrokeSmall,
  IconDescriptiveThemeStrokeSmall
} from '@postman/aether-icons';
import { TRACK_SUPPORTED_CHANNELS } from '../../constants/TrackSupportConstants';
import { isEnterpriseApplication } from '../../utils/enterpriseUtil';
import { shouldShowConnectedAccountsTab } from '../../utils/connectedAccountsUtil';

const enterpriseApplication = isEnterpriseApplication();

class SettingsTabs extends Component {
  constructor (props) {
    super(props);

    this.state = {
      shouldShowConnectedAccounts: false
    };
  }

  async componentDidMount () {
    const shouldShowConnectedAccounts = await shouldShowConnectedAccountsTab();
    this.setState({ shouldShowConnectedAccounts }, () => {
      // Update the width of the SettingsModalContainer tab
      this.props.updateConnectedAccountsTab(shouldShowConnectedAccounts);
    });
  }

  render () {
    // Determine if the integrations connected accounts tab needs to be rendered
    const { shouldShowConnectedAccounts } = this.state;

    // i18n translation function
    const { t } = this.props;

    return (
      <div className='settings-tabs'>
        <Tabs
          type='primary'
          defaultActive={SETTINGS_GENERAL}
          activeRef={this.props.activeTab}
          onChange={this.props.onSelect}
        >
          <Tab refKey={SETTINGS_GENERAL}>
            <IconDescriptiveSettingStrokeSmall />
            <Text>{t('settings:general.label')}</Text>
          </Tab>
          <Tab refKey={SETTINGS_THEMES}>
            <IconDescriptiveThemeStrokeSmall />
            <Text>{t('settings:themes.label')}</Text>
          </Tab>
          <Tab refKey={SETTINGS_SHORTCUTS}>
            <IconDescriptiveShortcutsStrokeSmall />
            <Text>{t('settings:shortcuts.label')}</Text>
          </Tab>
          <Tab refKey={SETTINGS_DATA}>
            <IconDescriptiveDataStrokeSmall />
            <Text>{t('settings:data.label')}</Text>
          </Tab>
          <Tab refKey={SETTINGS_ADDONS}>
            <IconDescriptiveMagicStrokeSmall />
            <Text>{t('settings:addons.label')}</Text>
          </Tab>
          {/* <Tab refKey={SETTINGS_SYNC}>Sync</Tab> */}
          <Tab refKey={SETTINGS_CERT}>
            <IconDescriptiveCertificateStrokeSmall />
            <Text>{t('settings:certificates.label')}</Text>
          </Tab>
          {
            shouldShowConnectedAccounts &&
              <Tab refKey={SETTINGS_CONNECTED_ACCOUNTS}>
                <IconDescriptiveConnectedAccountsStrokeSmall />
                <Text>{t('settings:connected_accounts.label')}</Text>
              </Tab>
          }
          <Tab refKey={SETTINGS_PROXY}>
            <IconDescriptiveProxyStrokeSmall />
            <Text>{t('settings:proxy.label')}</Text>
          </Tab>
          {
              __SDK_PLATFORM__ !== 'browser' && !enterpriseApplication &&
              <Tab refKey={SETTINGS_UPDATE}>
                <IconDescriptiveSystemUpdateStrokeSmall />
                <Text>{t('settings:update.label')}</Text>
              </Tab>
          }
          {
            TRACK_SUPPORTED_CHANNELS.has(window.RELEASE_CHANNEL) &&
            <Tab refKey={SETTINGS_DEV_OPTIONS}>
              <IconDescriptiveDeveloperStrokeSmall />
              <Text>{t('settings:dev_options.label')}</Text>
            </Tab>
          }
          <Tab refKey={SETTINGS_ABOUT}>
          <IconDescriptivePostmanStrokeSmall />
            <Text>{t('settings:about.label')}</Text>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation('settings')(SettingsTabs);
