import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Text } from '@postman/aether';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import CustomKeyboardShortcutsStore from '@postman-app-monolith/renderer/js/stores/CustomKeyboardShortcutsStore';
import { MODIFIER_KEYS } from '@postman-app-monolith/renderer/uxfoundation/constants/CustomKeyboardShortcutConstants';
import { isValidShortcut, populateNewShortcutConfig, getShortcutKeyBinding } from '@postman-app-monolith/renderer/uxfoundation/utils/CustomKeyboardShortcutsUtil';
import { isMacApp } from '@postman-app-monolith/renderer/js/utils/platform-utils';

import ShortcutValidationTooltip from './ShortcutValidationTooltip';
import { SHORTCUT_ITEM_STATUS } from './ShortcutItem';
import { useTranslation, Trans } from 'react-i18next';

const isDarwin = isMacApp();

/**
 * This method renders the content inside the tooltip - which appears when user presses invalid shortcut or conflicted shortcut
 */
function renderTooltipContent ({
  status,
  tooltipMessage,
  i18n
}) {
  if (status === SHORTCUT_ITEM_STATUS.CONFLICT) {
    return (
      tooltipMessage
        ? <Trans t={i18n.t} i18nKey='settings:shortcuts.tooltips.conflict.reassign_message_with_shortcut' values={{ osKey: isDarwin ? 'RETURN' : 'ENTER', shortcut: tooltipMessage }} >
          <Text type='para' color='content-color-primary'>
            The shortcut is already used by
            <Text type='strong' text={tooltipMessage} />.
          </Text>
          <Text
            type='para'
            color='content-color-secondary'
            text='Press {osKey} to reassign shortcut. ESC to cancel.'
          />
        </Trans>
        : <Trans t={i18n.t} i18nKey='settings:shortcuts.tooltips.conflict.reassign_message_without_shortcut' values={{ osKey: isDarwin ? 'RETURN' : 'ENTER' }} >
          <Text type='para' color='content-color-primary'>
            The shortcut is already used by
            <Text type='strong' text='other shortcut' />.
          </Text>
          <Text
            type='para'
            color='content-color-secondary'
            text='Press {osKey} to reassign shortcut. ESC to cancel.'
          />
        </Trans>
    );
  }

  return (
    <Text type='para' color='content-color-primary'>
      {tooltipMessage}
    </Text>
  );
}

/**
 * Function which returns the TextInput styles based on the current interaction state
 * @param {string} interactionState - The current interaction state of the TextInput
 */
function getInputStyles (interactionState, theme) {
  switch (interactionState) {
    case 'hover':
      return `
        background-color: ${theme['background-color-primary']};
        color: ${theme['input-color-default']};
        border: ${theme['border-width-default']} ${theme['border-style-solid']} ${theme['input-border-color-default']};
      `;

    case 'active':
      return `
        background-color: ${theme['background-color-primary']};
        color: ${theme['input-color-default']};
        border: ${theme['border-width-default']} ${theme['border-style-solid']} ${theme['input-border-color-focus']};
      `;

    case 'focus':
      return `
        background-color: ${theme['background-color-primary']};
        color: ${theme['input-color-default']};
        border: ${theme['border-width-default']} ${theme['border-style-solid']} ${theme['input-border-color-focus']};
      `;

    default:
      return `
        background-color: ${theme['background-color-primary']};
        color: ${theme['input-color-default']};
        border: ${theme['border-width-default']} ${theme['border-style-solid']} ${theme['input-border-color-default']};
      `;
  }
}

const shakeKeyframes = keyframes`
  25% {
    transform: translateX(4px);
  }
  50% {
      transform: translateX(-4px);
  }
  75% {
      transform: translateX(4px);
  }
`;

const shakeAnimation = css`
  animation: ${shakeKeyframes} 0.4s ease-in-out 0s 1;
`;

const StyledInputContainer = styled.div`
  box-sizing: border-box;
  cursor: text;
  width: 96px;
  padding: ${(props) => props.theme['spacing-zero']};
  border-radius: ${(props) => props.theme['border-radius-default']};
  ${(props) => getInputStyles('default', props.theme)};
  ${(props) => props.shakeInput && shakeAnimation}
  

  &:hover {
    ${(props) => !props.isDisabled && getInputStyles('hover', props.theme)};
  }

  &:focus-within {
    ${(props) => !props.isDisabled && getInputStyles('focus', props.theme)};
  }

  ${(props) =>
    (props.interactionState === 'focus' || props.interactionState === 'active'
      ? `box-shadow: 0 0 0 2px ${props.theme['input-shadow-focus']}`
      : null)};
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  text-align: right;
  width: 100%;
  outline: none;
  border: none;
  appearance: none;
  resize: none;
  border-color: transparent;
  background-color: transparent;
  color: inherit;
  padding: ${(props) => `0px ${props.theme['spacing-xs']}`};
  font-size: ${(props) => props.theme['text-size-m']};
  line-height: ${(props) => props.theme['line-height-m']};
  font-weight: ${(props) => props.theme['text-weight-regular']};
  font-family: ${(props) => props.theme['text-family-default']};

  &::placeholder {
    color: ${(props) => props.theme['content-color-tertiary']};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const CaptureCustomShortcut = React.forwardRef(function CaptureCustomShortcut (
  props,
  ref
) {
  const {
    value,
    onBlur,
    onChange,
    onFocus,
    onClick,
    onDismiss,
    onSubmit,
    isActive,
    isConflicted,
    isInvalidShortcut,
    shortcut,
    status,
    setStatus,
    setIsInvalidShortcut,
    setShortcutKeyBinding
  } = props;
  const [interactionState, setInteractionState] = useState('default');
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [shortcutConfig, setShortcutConfig] = useState(() => ({ ...shortcut }));

  const { t, i18n } = useTranslation('settings');

  const handleOnMouseDown = () => {
    setInteractionState('active');
  };

  const handleOnChange = (event) => {
    if (onChange) onChange(event);
  };

  const handleOnFocus = (event) => {
    setInteractionState('focus');
    if (onFocus) onFocus(event);
  };

  const handleOnBlur = (event) => {
    setInteractionState('default');
    if (onBlur) onBlur(event);
  };

  const handleKeyDown = async (keyboardEvent) => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();

    const event = keyboardEvent.nativeEvent;
    const isModifierKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
    const code = event.code;

    // While capturing key-presses, if the keyboardEvents of "only" modifier keys are captured then bail off
    // because, we get the info about the pressed modifiers via event.altKey/event.metaKey/event.shiftKey/event.ctrlKey as well.
    if (MODIFIER_KEYS.includes(code)) return;

    if (isActive && !isModifierKeyPressed) {

      // While capturing custom shortcuts, when Escape key is pressed, we trigger the onDismiss method.
      // It reverts the shortcut value to previous value.
      if (code === 'Escape') {
        if (status === SHORTCUT_ITEM_STATUS.CONFLICT) {
          onDismiss(true);
          return;
        }

        onDismiss();
        return;
      }

      // While capturing custom shortcuts, when Backspace key is pressed, we just clear the input.
      if (code === 'Backspace') {
        setShortcutKeyBinding('');
        return;
      }

      // While capturing custom shortcuts, when Enter key is pressed, then
      // (1) if there is a conflict, then confirm it and apply it
      // (2) else just revert the shortcut value to previous value.
      if (code === 'Enter' || code === 'NumpadEnter') {
        if (status === SHORTCUT_ITEM_STATUS.CONFLICT) {
          onSubmit(shortcutConfig);
          return;
        }

        onDismiss();
        return;
      }
    }

    const newShortcutConfig = await populateNewShortcutConfig(event);
    const newShortcut = newShortcutConfig.shortcut;
    const printableKeyLabel = isDarwin ? newShortcutConfig.keyLabelDarwin : newShortcutConfig.keyLabel;

    // Bail out if the user is pressing the same shortcut as current shortcut while customising
    if (newShortcut === shortcut.shortcut) {
      onDismiss();
      return;
    }

    if (isInvalidShortcut) {
      setIsInvalidShortcut(false);
    }

    // Update the user facing text inside input based on the keystrokes pressed by user
    setShortcutKeyBinding(printableKeyLabel);

    // Check if the new shortcut is valid or not
    const { isValid, errorMessage } = isValidShortcut(event, newShortcut, printableKeyLabel, i18n);
    if (!isValid) {
      if (errorMessage) {
        setStatus(SHORTCUT_ITEM_STATUS.INVALID);
        setTooltipMessage(errorMessage);
        setIsInvalidShortcut(true);
      }
      return;
    }

    const updatedShortcutConfig = {
      ...shortcutConfig,
      ...newShortcutConfig
    };
    setShortcutConfig(updatedShortcutConfig);
    setShortcutKeyBinding(getShortcutKeyBinding(updatedShortcutConfig));

    // Check if the new shortcut is creating conflict or not
    const conflictedShortcut = resolveStoreInstance(CustomKeyboardShortcutsStore).getAssignedKeyBindingData(newShortcut);
    if (conflictedShortcut) {
      setStatus(SHORTCUT_ITEM_STATUS.CONFLICT);
      const conflictedShortcutLabel = t(`settings:shortcuts.${_.snakeCase(conflictedShortcut.name)}`);
      setTooltipMessage(conflictedShortcutLabel);
    } else {
      onSubmit(updatedShortcutConfig);
    }
  };

  const handleOnClick = (event) => {
    if (onClick) onClick(event);
  };

  return (
    <ShortcutValidationTooltip
      isVisible={isConflicted || isInvalidShortcut}
      content={renderTooltipContent({ status, tooltipMessage, i18n })}
    >
      <StyledInputContainer
        data-value={value}
        interactionState={interactionState}
        shakeInput={isConflicted || isInvalidShortcut}
      >
        <StyledInput
          ref={ref}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onClick={handleOnClick}
          onFocus={handleOnFocus}
          onKeyDown={handleKeyDown}
          onMouseDown={handleOnMouseDown}
          value={value}
          data-testid='capture-custom-shortcut-input'
        />
      </StyledInputContainer>
    </ShortcutValidationTooltip>
  );
});

export default CaptureCustomShortcut;
