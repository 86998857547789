import React, { useEffect, useState } from 'react';
import ThemeManager from '../../controllers/theme/ThemeManager';
import AnalyticsService from '@postman-app-monolith/renderer/js/modules/services/AnalyticsService';
import SettingsThemes from '../../components/settings/SettingsThemes';
import { APP_THEME_CONFIG, APP_THEME_CONFIG_DEFAULT } from '../../controllers/theme/constants';
import { isValidAppThemeConfig } from '../../themes/utils';
import ThemesList from '../../apps/shared/theme';

const LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
  THEME_SETTING = 'postmanTheme';

/**
 *
 * @returns The smart component for the theme switch setting
 */
function SettingsThemeContainer () {
  const [currentTheme, setCurrentTheme] = useState(LIGHT);
  const [dayNightConfig, setDayNightConfig] = useState(APP_THEME_CONFIG_DEFAULT);
  const isSystemDayActive = ThemeManager.isSystemPreferenceDay();

  const handleThemeSelect = (theme) => {
    if (!theme || currentTheme === theme) {
      return;
    }

    // Record event when the theme is updated by the user
    AnalyticsService.addEventV2({
      category: 'application-theme-settings',
      action: 'select_theme',
      label: theme
    });

    ThemeManager.changeTheme(theme);
  };

  const handleDayNightConfigChange = (config) => {
    if (!isValidAppThemeConfig(config)) {
      return;
    }

     // Record event when the system theme config is updated
     config && AnalyticsService.addEventV2({
      category: 'application-theme-settings',
      action: 'day_night_theme_change',
      meta: config
    });

    setDayNightConfig(config);
    ThemeManager.changeDayNightConfig(config);
  };

  const handleExternalThemeChange = () => {
    // System theme can be changed only from the theme settings page
    // At first we will check if system theme is active, in that case we will set the current theme as system theme
    // Otherwise set the proper theme retrieving from local storage
    //
    // Note: A valid option is to ignore setting any theme if system theme is set assuming that will be the active option in UI
    // But due to some unforeseen situation if the local storage value and UI option is not in sync, then this will make them sync and prevent a weird UI state
    // Should not happen under any circumstances, but just additional safe guard
    const isSystemThemeActive = ThemeManager.isSystemDefaultThemeActive();
    setCurrentTheme(isSystemThemeActive ? SYSTEM : ThemeManager.getCurrentTheme());
    isSystemThemeActive && setDayNightConfig(ThemeManager.getCurrentDayNightConfig());
  };

  /**
   * When the config is changed in a different postman app window,
   * we want the new config state to reflect in the component state.
   */
  const handleExternalConfigChange = () => {
    const updatedDayNightConfig = ThemeManager.getCurrentDayNightConfig();
    updatedDayNightConfig !== dayNightConfig && setDayNightConfig(updatedDayNightConfig);
  };


  useEffect(() => {
    const isSystemThemeActive = ThemeManager.isSystemDefaultThemeActive();
    setCurrentTheme(isSystemThemeActive ? SYSTEM : ThemeManager.getCurrentTheme());
    isSystemThemeActive && setDayNightConfig(ThemeManager.getCurrentDayNightConfig());

    ThemesList.hydrateAllThemes();

    pm.settings.on(`setSetting:${THEME_SETTING}`, handleExternalThemeChange);
    pm.settings.on(`setSetting:${APP_THEME_CONFIG}`, handleExternalConfigChange);

    return () => {
      pm.settings.off(`setSetting:${THEME_SETTING}`, handleExternalThemeChange);
      pm.settings.off(`setSetting:${APP_THEME_CONFIG}`, handleExternalConfigChange);
    };
  }, []);


  return (
    <SettingsThemes
      currentTheme={currentTheme}
      dayNightConfig={dayNightConfig}
      handleThemeSelect={handleThemeSelect}
      handleDayNightConfigChange={handleDayNightConfigChange}
      isSystemDayActive={isSystemDayActive}
    />
  );
}

export default SettingsThemeContainer;
