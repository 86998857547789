import React from 'react';
import { SingleSelect } from '@postman/aether';
import getDefaultHTTPVersion from '@postman-app-monolith/renderer/runtime-repl/request-http/RequestVersionHelper';

const menuOptions = ['auto', 'http1', 'http2'],
  menuLabels = ['Auto', 'HTTP/1.x', 'HTTP/2'];

/**
 * HTTPProtocolVersionSelector component
 * @param {Object} props
 * @param {String} props.value
 * @param {Function} props.onChange
 * @param {Boolean} props.isDisabled
 * @returns {ReactElement}
 */
export default function HTTPProtocolVersionSelector (props) {
  const {
    value,
    onChange,
    isDisabled
  } = props;

  const defaultProtocolVersion = getDefaultHTTPVersion();

  const currInputValue = value,
    options = menuOptions.map((option, index) => ({
      label: menuLabels?.[index] ?? option,
      value: option
    })),
    selectedOption = options.find(({ value }) => value === currInputValue),
    defaultValue = {
      value: defaultProtocolVersion,
      label: menuLabels.find((label, index) => menuOptions[index] === defaultProtocolVersion)
    };

  return (
    <SingleSelect
      triggerWidth={100}
      menuWidth='auto'
      menuMinWidth={120}
      options={options}
      value={selectedOption ?? defaultValue}
      onChange={({ value }) => {
        onChange(value);
      }}
      isClearable={false}
      isSearchable={false}
      isDisabled={isDisabled}
    />
  );
}
