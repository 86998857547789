import styled from 'styled-components';
import { Flex } from '@postman/aether';

const StyledRadioGroupContainer = styled(Flex)`
  // To align RadioButton labels to the text on LHS.
   & > div > div {
    margin-top: 2px;

    @media screen and (max-width: 720px) { // To stack RadioButtons at width below 720px because of lack of horizontal space
      display: grid;
      gap: var(--spacing-xs);
    }
  }

  // To override extra margin added by Aether RadioGroup when label is not used as Aether Radiogroup adds a top spacing to its underlying Flex.
  & > div > div:first-child {
    margin: var(--spacing-zero);
  }
`;

export {
  StyledRadioGroupContainer
};
