import { action, observable, toJS } from 'mobx';
import { INTEGRATIONS_VIEW } from '../../integrations/constants/integrationsConstants';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import SyncStatusStore from '@postman-app-monolith/renderer/js/stores/SyncStatusStore';
import IntegrationService from '../../onboarding/src/features/Homepage/pages/IntegrationsPage/services/IntegrationService';
import IntegrationClassService from '@postman-app-monolith/renderer/onboarding/src/features/Homepage/pages/IntegrationsPage/services/IntegrationClassService';
import IntegrationsUtils from '../../onboarding/src/features/Homepage/pages/IntegrationsPage/components/BrowseIntegrations/IntegrationsUtils';
import IntegrationInstanceService
  from '../../onboarding/src/features/Homepage/pages/IntegrationsPage/services/IntegrationInstanceService';
import NewRelicService from '@postman-app-monolith/renderer/integrations/monitoring/services/NewRelicService';
import { DATADOG_APM_CLASS_ID, NEW_RELIC_APM_CLASS_ID } from '@postman-app-monolith/renderer/integrations/monitoring/constants';

const REFETCH_DATA_WAIT_TIME = 60000,
  REFETCH_RULE_INTEGRATIONS = 2.5 * 60 * 1000,
  SYNC_STATUS_TIMEOUT = 8 * 1000,

  CATEGORY_ALL = 'all',
  POWERUP_SECTION_CLIENT_CATEGORIES = [
    'continuous-integration',
    'api-performance-integrations',
    'api-gateway-observability'
  ],

  /**
   * Calling debounced function for visited rule otherwise will call original function
   */
  memoizeDebounce = (func, wait, options) => {
    const memory = {};

    return (...args) => {
      // using rule as key
      const [service, rule] = args;

      if (typeof memory[rule] === 'function') {
        return memory[rule](...args);
      }

      memory[rule] = _.debounce(func, wait, { ...options, leading: true });
      return memory[rule](...args);
    };
  };

export default class IntegrationStore {
  @observable activeIntegrationsView = INTEGRATIONS_VIEW.TEAM;
  @observable serviceId = '';
  @observable activeServiceDetailsId = '';
  @observable ruleId = '';
  @observable teamIntegrations = [];
  @observable availableIntegrations = [];
  @observable loadingAvailableIntegrations = true;
  @observable loadingIntegrationClasses = true;
  @observable loadingIntegrationServices = true;
  @observable errorFetchingAvailableIntegrations = false;
  @observable availableIntegrationsErrorDetails = '';
  @observable filteredAvailableIntegrations = [];
  @observable filterByCategoryAvailableIntegrations = [];
  @observable integrationCategories = [];
  @observable noTeamIntegrations = false;
  @observable loadingTeamIntegrations = true;
  @observable errorInFetchingTeamIntegrations = false;
  @observable errorDetails = {};
  @observable ruleSpecificTeamIntegrations = {};
  @observable ruleSpecificTeamIntegrationErrors = {};
  @observable ruleSpecificTeamIntegrationsMeta = {};
  @observable serviceDetails = {};
  @observable ruleDetails = {};
  @observable noRuleSpecificTeamIntegrations = false;
  @observable isHydrating = false;
  @observable isAdding = false;
  @observable categoryFilter = 'all';
  @observable createFormActiveRuleId = '';
  @observable createFormActiveServiceId = '';
  @observable workspaces = [];
  @observable entities = [];
  @observable selectedWorkspace = null
  @observable selectedEntity = null
  @observable selectedEntityVersion = null
  @observable authDetails={};
  @observable errorFetchingAuthDetails = false;
  @observable authDetailsError = '';
  @observable runLogPageData = [];
  @observable loadingRunLogPage = true;
  @observable errorInFetchingRunLogPageData = false;
  @observable runLogPageErrorDetails = '';
  @observable sourceConflicting = false;
  @observable refreshingRunLog = false;
  @observable authType = '';
  @observable availableIntegrationClasses = [];
  @observable integrationCategories = [];
  @observable availableIntegrationServices = [];
  @observable availableIntegrationServicesByCategory = [];
  @observable filteredIntegrationServices = [];

  // Variables for new nouns
  @observable preferredEntities = [];
  @observable integrationClass = null;
  @observable loadingIntegrationClass = null;
  @observable errorFetchingIntegrationClass = null;
  @observable integrationInstance = null;
  @observable loadingIntegrationInstance = null;
  @observable errorFetchingIntegrationInstance = null;

  constructor () {
    this.initializeFetchingTeamIntegrations = _.debounce(this.initializeFetchingTeamIntegrations.bind(this), REFETCH_DATA_WAIT_TIME, { leading: true, trailing: false });
    this.getAvailableIntegrationClasses = _.debounce(this.getAvailableIntegrationClasses.bind(this), REFETCH_DATA_WAIT_TIME, { leading: true, trailing: false });
    this.getAvailableIntegrationServices = _.debounce(this.getAvailableIntegrationServices.bind(this), REFETCH_DATA_WAIT_TIME, { leading: true, trailing: false });
    this.initializeRuleSpecificTeamIntegrations = memoizeDebounce(this.initializeRuleSpecificTeamIntegrations.bind(this), REFETCH_RULE_INTEGRATIONS, { leading: true, trailing: false });
  }

  @action
  setActiveIntegrationsView (state) {
    this.activeIntegrationsView = state;
  }

  /**
   *
   * Returns rule specific team integrations
   * @param {String} rule - rule id like `collection_github`
   */
  readRuleSpecificTeamIntegrations (rule) {
    return toJS(this.ruleSpecificTeamIntegrations[rule]);
  }

  readRuleSpecificTeamIntegrationsMeta (rule) {
    return toJS(this.ruleSpecificTeamIntegrationsMeta[rule]);
  }

  /**
   * Returns service meta info
   * @param {String} service - serviceId like `github`
   */
  readServiceDetails (service) {
    return toJS(this.serviceDetails[service]);
  }

  readRuleDetails (classId) {
    return toJS(this.ruleDetails[classId]);
  }

  @action
  setAuthType (authType) {
    this.authType = authType;
  }

  @action
  setRuleSpecificTeamIntegrations (rule, data) {
    this.ruleSpecificTeamIntegrations[rule] = data;
  }

  @action
  resetRuleSpecificTeamIntegrations (rule) {
    delete this.ruleSpecificTeamIntegrations[rule];
  }

  @action
  setServiceDetails (service, data) {
    this.serviceDetails[service] = data;
  }

  @action
  setRuleDetails (classId, data) {
    this.ruleDetails[classId] = data;
  }

  @action
  resetServiceDetails (service) {
    delete this.serviceDetails[service];
  }

  @action
  setActiveIntegrationRule (serviceId, classId) {
    this.serviceId = serviceId;
    this.ruleId = classId;
  }

  @action
  updateCategoryfilter (categoryFilter) {
    this.categoryFilter = categoryFilter;

    this.updateIntegrationServicesPerCategory(categoryFilter);
  }

  @action
  updateRuleSpecificTeamIntegrationsDataStatus (loading, error, errorDetails, classId) {
    this.ruleSpecificTeamIntegrationErrors[classId] = {
      loading,
      error,
      errorDetails
    };
  }

  readRuleSpecificTeamIntegrationsDataStatus (ruleID) {
    // initially each rule will have loading as undefined, making it true for initial loading
    return { loading: true, ...(toJS(this.ruleSpecificTeamIntegrationErrors[ruleID])) };
  }

  @action
  setRuleSpecificTeamIntegrationsMeta (ruleID, totalPages, totalCount) {
    this.ruleSpecificTeamIntegrationsMeta[ruleID] = {
      totalPages,
      totalCount
    };
  }

  @action
  resetRuleSpecificTeamIntegrationsMeta (ruleID) {
    delete this.ruleSpecificTeamIntegrationsMeta[ruleID];
  }

  @action
  getRuleSpecificTeamIntegrationData (serviceId, classId) {
    this.updateRuleSpecificTeamIntegrationsDataStatus(true, false, '', classId); // resetting the error state
    return Promise.all([
      IntegrationClassService.fetchIntegrationClassDetails(classId),
      IntegrationClassService.fetchIntegrationServiceDetails(serviceId),
      IntegrationService.getTeamIntegrationInstances(classId)
    ])
    .then(([integrationClassDetails, integrationServiceDetails, teamIntegrations]) => {

      const listOfTeamIntegrationsInDetail = _.get(teamIntegrations, 'body.data', []),
        teamIntegrationsMeta = _.get(teamIntegrations, 'body.meta.pagination', []),
        classSpecificDetails = _.get(integrationClassDetails, 'body.data', {}),
        serviceSpecificDetails = _.get(integrationServiceDetails, 'body.data', {});

      // non 2xx response with empty body or no error leads to error state
      if (integrationClassDetails.status !== 200 || teamIntegrations.status !== 200 || integrationServiceDetails.status !== 200
        || _.isEmpty(classSpecificDetails) || _.isEmpty(serviceSpecificDetails)) {

        let errorDetails = {};
        if (integrationClassDetails.status !== 200 || _.isEmpty(classSpecificDetails)) {
          errorDetails['errorFetchingClassDetails'] = integrationClassDetails;
        }
        if (integrationServiceDetails.status !== 200 || _.isEmpty(serviceSpecificDetails)) {
          errorDetails['errorFetchingServiceDetatils'] = integrationServiceDetails;
        }
        if (teamIntegrations.status !== 200) {
          errorDetails['errorFetchingTeamIntegrations'] = teamIntegrations;
        }

        this.updateRuleSpecificTeamIntegrationsDataStatus(false, true, errorDetails, classId);
      }

      else {

        // there are team integrations
        if (!_.isEmpty(listOfTeamIntegrationsInDetail)) {
          let data = _.map(listOfTeamIntegrationsInDetail, (integration) => {
            return {
              id: integration.id,
              name: integration.name,
              userId: integration.createdBy.id,
              userName: _.get(integration, 'createdBy.name') || _.get(integration, 'createdBy.username')
                        || _.get(integration, 'createdBy.email') || 'A user',
              isUserNameClickable: Boolean(_.get(integration, 'createdBy.name') || _.get(integration, 'createdBy.username')
              || _.get(integration, 'createdBy.email')),
              userProfileUrl: integration.createdBy.profilePicUrl,
              status: integration.status,
              apiDetails: integration.entity.api,
              entityType: integration.entity.type,
              entityId: integration.entity.id,
              entityName: integration.entity.name,
              lastRunAt: integration.lastRunAt,
              clientCategories: integration.clientCategories
            };
          });
          this.setRuleSpecificTeamIntegrations(classId, data);
        }

        // no team integrations added
        else {
          this.setRuleSpecificTeamIntegrations(classId, []);
        }
        this.setRuleSpecificTeamIntegrationsMeta(classId, _.get(teamIntegrationsMeta, 'totalPages'), _.get(teamIntegrationsMeta, 'totalCount'));

        this.setServiceDetails(serviceId, serviceSpecificDetails);
        this.setRuleDetails(classId, classSpecificDetails);
        this.updateRuleSpecificTeamIntegrationsDataStatus(false, false, '', classId);
      }
    })
    .catch((error) => {
      this.updateRuleSpecificTeamIntegrationsDataStatus(false, true, error, classId);
      this.resetRuleSpecificTeamIntegrations(classId);
      this.resetRuleSpecificTeamIntegrationsMeta(classId);
      this.resetServiceDetails(serviceId);
    });
  }

  @action
  getTeamIntegrationsData () {
    this.setLoadingIntegrationClasses(true);
    this.setLoadingIntegrationServices(true);

    return Promise.all([
      IntegrationClassService.fetchAvailableClasses(),
      IntegrationClassService.fetchAvailableServices(),
      IntegrationService.getTeamIntegrations()
    ])
    .then(([integrationClassesData, integrationServicesData, teamIntegrations]) => {
      const integrationServices = _.get(integrationServicesData, 'body.data', []),
        integrationClasses = _.get(integrationClassesData, 'body.data', []),
        listOfTeamIntegrations = _.get(teamIntegrations, 'body.data', []);

      // non 2xx response with empty body or with no error leads to error state
      if (integrationClassesData.status !== 200 || teamIntegrations.status !== 200 || integrationServicesData.status !== 200
        || _.isEmpty(integrationServices) || _.isEmpty(integrationClasses)
      ) {
        let errorDetails = {};
        if (integrationServicesData.status !== 200 || _.isEmpty(integrationServices)) {
          errorDetails['errorFetchingServices'] = integrationServicesData;
        }
        if (integrationClassesData.status !== 200 || _.isEmpty(integrationClasses)) {
          errorDetails['errorFetchingClasses'] = integrationClassesData;
        }
        if (teamIntegrations.status !== 200) {
          errorDetails['errorFetchingTeamIntegrations'] = teamIntegrations;
        }

         // erasing team integrations data incase of error
        this.updateData(false, []);
        this.updateState(false, true, errorDetails);
      }
      else {
        // there are team integrations
        if (!_.isEmpty(listOfTeamIntegrations)) {
          const ruleWiseGrouped = _.groupBy(listOfTeamIntegrations, 'rule'),

            teamIntegrations = _.map(ruleWiseGrouped, (integration, classId) => {
              const serviceId = integration[0].service,
                integrationClassDetails = _.find(integrationClasses, (integrationClass) => {
                  return integrationClass.id === classId;
                }),
                integrationServiceDetails = _.find(integrationServices, (integrationService) => {
                  return integrationService.id === serviceId;
                });

              return {
                  ruleName: _.get(integrationClassDetails, 'name'),
                  ruleId: classId,
                  serviceId,
                  serviceName: _.get(integrationServiceDetails, 'name', ''),
                  serviceIconURL: _.get(integrationServiceDetails, 'iconUrl', ''),
                  serviceDarkIconURL: _.get(integrationServiceDetails, 'darkIconUrl', ''),
                  statusCount: _.countBy(integration, (n) => {
                    return n.status;
                  }),
                  totalIntegrations: integration.length
                };
              }),
              sortedTeamIntegrations = this.defaultSortTeamIntegrations(teamIntegrations);

            this.updateData(false, sortedTeamIntegrations);
            this.updateState(false, false, '');
        }

        // no team integrations added
        else {
          this.updateData(true, []);
          this.updateState(false, false, '');
        }

        const updatedIntegrationClasses = this.addDisplayNameToIntegrationClasses(integrationClasses);

        this.setAvailableIntegrationClasses(updatedIntegrationClasses);
        this.setAvailableIntegrationServices(integrationServices);
        this.getIntegrationCategoriesFromServices();
        this.updateIntegrationServicesPerCategory(this.categoryFilter);
        this.updateFilteredIntegrationServices(this.availableIntegrationServicesByCategory);
      }

      this.setLoadingIntegrationServices(false);
      this.setLoadingIntegrationClasses(false);
      this.updateAvailableIntegrationsStatus(false, false, '');
    })
    .catch((error) => {
      pm.logger.error('Error in fetching team integrations', error);

      // erasing team integrations data incase of error
      this.updateData(false, []);
      this.updateState(false, true, error);
      this.setLoadingIntegrationServices(false);
      this.setLoadingIntegrationClasses(false);
      this.updateAvailableIntegrationsStatus(false, true, error);
    });
  }

  @action
  setIsHydrating (state) {
    this.isHydrating = state;
  }

  @action
  updateState (loading, error, errorDetails) {
    this.loadingTeamIntegrations = loading;
    this.errorInFetchingTeamIntegrations = error;
    this.errorDetails = errorDetails;
  }

  @action
  updateData (noTeamIntegrations, data) {
    this.noTeamIntegrations = noTeamIntegrations;
    this.teamIntegrations = data;
  }

  @action
  updateAvailableIntegrations (availableIntegrations) {
    this.availableIntegrations = availableIntegrations;
  }

  @action
  updateIntegrationCategories () {
    this.integrationCategories = IntegrationsUtils.getAllCategories(this.availableIntegrations);
  }

  @action
  setAvailableIntegrationServices (services) {
    this.availableIntegrationServices = services;
  }

  /**
   * Mutates list of integrationClasses
   *
   * @param {*} integrationClasses
   * @returns
   */
  addDisplayNameToIntegrationClasses (integrationClasses) {
    const serviceToNumberOfIntegrations = {};

    _.forEach(integrationClasses, (integrationClass) => {
      const services = _.get(integrationClass, 'services', []);

      _.forEach(services, (service) => {
        if (_.get(serviceToNumberOfIntegrations, service.id)) {
          serviceToNumberOfIntegrations[service.id].push(integrationClass.id);
        } else {
          serviceToNumberOfIntegrations[service.id] = [integrationClass.id];
        }
      });
    });

    _.forEach(integrationClasses, (integrationClass) => {
      const service = _.get(integrationClass, 'services.0', {});
      integrationClass.displayName = _.size(serviceToNumberOfIntegrations[service.id]) > 1 ? integrationClass.name : service.name;
    });

    return integrationClasses;
  }

  @action
  setAvailableIntegrationClasses (integrationClasses) {
    this.availableIntegrationClasses = integrationClasses;
  }

  @action
  getIntegrationCategoriesFromServices () {
    this.integrationCategories =
      IntegrationsUtils.getAllIntegrationCategories(this.availableIntegrationServices);
  }

  @action
  updateIntegrationServicesPerCategory (category) {
    let filteredServices = IntegrationsUtils.filterByCategory(category, toJS(this.availableIntegrationServices));

    // If invalid category was sent, revert to 'all' category.
    // Note: empty check only works here because we get the list of categories from services and not a different API.
    // (see IntegrationsUtils.getAllCategories)
    if (_.isEmpty(filteredServices) && !_.isEmpty(toJS(this.availableIntegrationServices))) {
      this.updateCategoryfilter(CATEGORY_ALL);
      filteredServices = toJS(this.availableIntegrationServices);
    }

    this.availableIntegrationServicesByCategory = filteredServices;
  }

  @action
  updateFilteredIntegrationServices (filteredServices) {
    this.filteredIntegrationServices = filteredServices;
  }

  @action
  updateAvailableIntegrationsStatus (loading, error, errorDetails) {
    this.loadingAvailableIntegrations = loading;
    this.errorFetchingAvailableIntegrations = error;
    this.availableIntegrationsErrorDetails = errorDetails;
  }

  @action
  setLoadingIntegrationServices (value) {
    this.loadingIntegrationServices = value;
  }

  @action
  setLoadingIntegrationClasses (value) {
    this.loadingIntegrationClasses = value;
  }

  @action
  updateFilteredAvailableIntegrations (filteredServices) {
    this.filteredAvailableIntegrations = filteredServices;
  }

  @action
  updateFilterByCategoryAvailableIntegrations (category) {
    let filteredIntegrations = IntegrationsUtils.filterByCategory(category, toJS(this.availableIntegrations));

    // If invalid category was sent, revert to 'all' category.
    // Note: empty check only works here because we get the list of categories from services and not a different API.
    // (see IntegrationsUtils.getAllCategories)
    if (_.isEmpty(filteredIntegrations) && !_.isEmpty(toJS(this.availableIntegrations))) {
      this.updateCategoryfilter(CATEGORY_ALL);
      filteredIntegrations = toJS(this.availableIntegrations);
    }

    this.filterByCategoryAvailableIntegrations = filteredIntegrations;
  }

  @action
  initializeFetchingTeamIntegrations () {
    resolveStoreInstance(SyncStatusStore)
      .onSyncAvailable({ timeout: SYNC_STATUS_TIMEOUT })
      .then(() => {
        this.setIsHydrating(true);
        return this.getTeamIntegrationsData();
      })
      .catch(() => {
        this.updateState(false, false, '');
      })
      .finally(() => {
        this.setIsHydrating(false);
      });
  }

  @action
  initializeRuleSpecificTeamIntegrations (serviceId, classId) {
    resolveStoreInstance(SyncStatusStore)
      .onSyncAvailable({ timeout: SYNC_STATUS_TIMEOUT })
      .then(() => {
        return this.getRuleSpecificTeamIntegrationData(serviceId, classId);
      })
      .catch((err) => {
        this.updateRuleSpecificTeamIntegrationsDataStatus(false, true, err, classId);
      });
  }

  @action
  refetchRuleSpecificTeamIntegrations (serviceId, classId) {
    resolveStoreInstance(SyncStatusStore)
      .onSyncAvailable({ timeout: SYNC_STATUS_TIMEOUT })
      .then(() => {
        return this.getRuleSpecificTeamIntegrationData(serviceId, classId);
      })
      .catch((err) => {
        this.updateRuleSpecificTeamIntegrationsDataStatus(false, true, err, classId);
      });
  }

  @action
  refetchTeamIntegrations () {
    resolveStoreInstance(SyncStatusStore)
    .onSyncAvailable({ timeout: SYNC_STATUS_TIMEOUT })
    .then(() => {
      this.setIsHydrating(true);
      return this.getTeamIntegrationsData();
    })
    .catch(() => {
      this.updateState(false, false, '');
    })
    .finally(() => {
      this.setIsHydrating(false);
    });
  }

  @action
  getAvailableIntegrationClasses () {
    this.setLoadingIntegrationClasses(true);

    // Calling /integration-classes endpoint
    return IntegrationClassService.fetchAvailableClasses()
      .then((response) => {
        const integrationClasses = this.addDisplayNameToIntegrationClasses(_.get(response, 'body.data', []));

        this.setAvailableIntegrationClasses(integrationClasses);
        this.updateAvailableIntegrationsStatus(false, false, '');
        this.setLoadingIntegrationClasses(false);
      })
      .catch((e) => {
        this.updateAvailableIntegrationsStatus(false, true, e);
        this.setLoadingIntegrationClasses(false);
        pm.logger.error('Error occurred while fetching available integration classes', e);
      });
  }

  @action
  getAvailableIntegrationServices () {
    this.setLoadingIntegrationServices(true);

    return IntegrationClassService.fetchAvailableServices()
    .then((response) => {
      this.setAvailableIntegrationServices(_.get(response, 'body.data', []));
      this.updateAvailableIntegrationsStatus(false, false, '');
      this.getIntegrationCategoriesFromServices();
      this.updateIntegrationServicesPerCategory(this.categoryFilter);
      this.updateFilteredIntegrationServices(this.availableIntegrationServicesByCategory);
      this.setLoadingIntegrationServices(false);
    })
    .catch((e) => {
      this.updateAvailableIntegrationsStatus(false, true, e);
      this.setLoadingIntegrationServices(false);
      pm.logger.error('Error occurred while fetching available integration services', e);
    });
  }

  @action
  populateIntegrationClasses () {
    this.updateAvailableIntegrationsStatus(true, false, '');

    return resolveStoreInstance(SyncStatusStore)
      .onSyncAvailable({ timeout: SYNC_STATUS_TIMEOUT })
      .then(() => {
        this.updateAvailableIntegrationsStatus(true, false, '');
        return this.getAvailableIntegrationClasses();
      })
      .catch(() => {
        this.updateAvailableIntegrationsStatus(false, false, '');
      });
  }

  @action
  populateIntegrationServices () {
    this.updateAvailableIntegrationsStatus(true, false, '');

    return resolveStoreInstance(SyncStatusStore)
      .onSyncAvailable({ timeout: SYNC_STATUS_TIMEOUT })
      .then(() => {
        this.updateAvailableIntegrationsStatus(true, false, '');
        return this.getAvailableIntegrationServices();
      })
      .catch(() => {
        this.updateAvailableIntegrationsStatus(false, false, '');
      });
  }

  @action
  setActiveIntegrationService (serviceId) {
    this.activeServiceDetailsId = serviceId;
  }

  @action
  setActiveIntegrationCreateForm (serviceId, classId) {
    this.createFormActiveServiceId = serviceId;
    this.createFormActiveRuleId = classId;
  }

  @action
  setSelectedWorkspace (workspace) {
    this.selectedWorkspace = workspace;
  }

  @action
  setSelectedEntity (entity) {
    this.selectedEntity = entity;
  }

  @action
  setSelectedEntityVersion (entityVersion) {
    this.selectedEntityVersion = entityVersion;
  }

  @action
  resetSelectedWorkspace () {
    this.selectedWorkspace = null;
  }

  @action
  resetSelectedEntity () {
    this.selectedEntity = null;
  }

  @action
  resetSelectedEntityVersion () {
    this.selectedEntityVersion = null;
  }

  @action
  resetAddIntegrationDependencies () {
    this.resetSelectedEntityVersion();
    this.resetSelectedEntity();
    this.resetSelectedWorkspace();
  }

  @action
  updateIsAdding (isAdding) {
    this.isAdding = isAdding;
  }

  @action
  updateSourceConflicting (sourceConflicting) {
    this.sourceConflicting = sourceConflicting;
  }

  readTeamIntegrations () {
    return toJS(this.teamIntegrations);
  }

  @action
  updateIdentityResponse (response) {
    this.authDetails = _.get(response, 'body.handover');
  }

  @action
  updateIdentityResponseStatus (error, errorDetails) {
    this.errorFetchingAuthDetails = error;
    this.authDetailsError = errorDetails;
  }

  @action
  setAuthDetails (auth) {
    this.authDetails = auth;
  }

  readAuthDetails () {
    return toJS(this.authDetails);
  }

  defaultSortTeamIntegrations (data) {
    const failedIntegrations = _.filter(data, (integration) => {
      return integration.statusCount.failed ? true : false;
    }),
    sortedFailedIntegrations = _.sortBy(failedIntegrations, (integration) => _.toLower(integration['ruleName'])),
    successfulIntegrations = _.sortBy(_.difference(data, sortedFailedIntegrations), (integration) => _.toLower(integration['ruleName']));

    return _.concat(sortedFailedIntegrations, successfulIntegrations);
  }

  @action
  updateRunsPageResponseStatus (loading, error, errorDetails) {
    this.loadingRunLogPage = loading;
    this.errorInFetchingRunLogPageData = error;
    this.runLogPageErrorDetails = errorDetails;
  }

  @action
  updateRunsPageResponse (data) {
    this.runLogPageData = data;
  }

  readIntegrationRunLogData () {
    return toJS(this.runLogPageData);
  }

  @action
  updateRefreshingRunLog (refreshingRunLog) {
    this.refreshingRunLog = refreshingRunLog;
  }

  @action
  addPreferredEntity (entityType, entityId) {
    const entityObj = {
      entityType,
      entityId
    };

    // If the array doesn't already contain the entity, add to it
    if (!_.some(this.preferredEntities, entityObj)) {
      this.preferredEntities.push(entityObj);
    }
  }

  @action
  getIntegrationClassDetails (classId) {
    this.loadingIntegrationClass = true;

    return new Promise((resolve, reject) => {
      IntegrationClassService.fetchIntegrationClassDetails(classId)
        .then((response) => {
          const classData = _.get(response, 'body.data');

          if (response.status !== 200 || _.isEmpty(classData)) {
            this.errorFetchingIntegrationClass = response;
          }
          else {
            this.integrationClass = classData;
            this.errorFetchingIntegrationClass = false;
          }

          this.loadingIntegrationClass = false;
          resolve();
        })
        .catch((error) => {
          pm.logger.error('Error in fetching integration class details', classId, error);
          this.errorFetchingIntegrationClass = error;
          this.loadingIntegrationClass = false;
          reject(error);
        });
    });
  }

  @action
  getIntegrationInstanceDetails (instanceId) {
    this.loadingIntegrationInstance = true;
    IntegrationInstanceService.fetchIntegrationInstanceDetails(instanceId)
      .then((response) => {
        const instanceData = _.get(response, 'body.data');

        if (response.status !== 200 || _.isEmpty(instanceData)) {
          this.errorFetchingIntegrationInstance = response;
        }
        else {
          instanceData.allowedActions = _.get(response, 'body.meta.allowedActions');
          this.integrationInstance = instanceData;
          this.errorFetchingIntegrationInstance = false;
        }

        this.loadingIntegrationInstance = false;
      })
      .catch((error) => {
        pm.logger.error('Error in fetching integration instance details', instanceId, error);
        this.errorFetchingIntegrationInstance = error;
        this.loadingIntegrationInstance = false;
      });
  }

  getIntegrationClasses () {
    if (_.isEmpty(this.availableIntegrationClasses)) {
      this.populateIntegrationClasses();
    }

    return this.availableIntegrationClasses;
  }

  getIntegrationServices () {
    if (_.isEmpty(this.availableIntegrationServices)) {
      this.populateIntegrationServices();
    }

    return this.availableIntegrationServices;
  }
}
