import { getMeta as getEnvironmentMeta, getOne as getEnvironment } from '@@runtime-repl/environment/_api/EnvironmentStoreV2';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import ActiveWorkspaceStore from '@postman-app-monolith/renderer/js/stores/ActiveWorkspaceStore';
import { sanitizeVariableValue } from './util';

const ENVIRONMENT = 'environment',
      EXPORTED_USING_PREFIX = 'Postman/',
      FILE_NAME_EXT = '.postman_environment.json',
      TAB = '\t',
      allowedEnvFields = ['id', 'name', 'values', 'timestamp', '_postman_variable_scope'];

/**
 * Gets the environment from the db
 * @param {Object} criteria
 */
async function getFromDb (criteria) {
  const environmentStore = await getEnvironmentMeta(resolveStoreInstance(ActiveWorkspaceStore).id)
    .catch((err) => {
      pm.logger.error('environment~getFromDb - Error occurred while fetching workspace environments', err);
    });

  // Return undefined if the environment is not found
  if (!environmentStore) {
    return;
  }

  const environmentUid = environmentStore.findOne(criteria.id).id;

  if (!environmentUid) {
    return;
  }

  const environment = await getEnvironment(environmentUid)
    .catch((err) => {
      pm.logger.error('environment~getFromDb - Error occurred while fetching environment', err);
    });

  return environment && environment.toJSONV1();
}

/**
 * Sanitizes the environment before exporting
 * @param {Object} environment
 */
function sanitize (environment) {
  let sanitizedEnv = _.pick(environment, allowedEnvFields);
  sanitizedEnv.values = _.map(sanitizedEnv.values, sanitizeVariableValue);

  return sanitizedEnv;
}

/**
 * This transforms the environment into the exportable format
 * It adds few meta properties
 * @param {Object} environment
 * @returns {Promise} Which resolves in the transformed environment
 */
function transform (environment, context = {}) {
  return Promise.resolve(
    _.merge({}, environment, {
      _postman_variable_scope: ENVIRONMENT,
      _postman_exported_at: (new Date()).toISOString(),
      _postman_exported_using: EXPORTED_USING_PREFIX + context.appVersion
    })
  );
}

/**
 * Returns the entity name
 * @param {Object} environment
 */
function getEntityName (environment) {
  return environment.name || ENVIRONMENT;
}

/**
 * Returns the fileName that can be used if exporting to a file
 * @param {Object} environment
 */
function getFileName (environment) {
  return this.getEntityName(environment) + FILE_NAME_EXT;
}

/**
 * Serializes the environment
 * @param {Object} environment
 * @returns {String} Stringified environment
 */
function serialize (environment) {
  return JSON.stringify(environment, null, TAB);
}

export default {
  getFromDb,
  sanitize,
  transform,
  getEntityName,
  getFileName,
  serialize
};
