import React, { Component } from 'react';
import { Text, Heading, Flex, Button as AetherButton } from '@postman/aether';
import Link from '@postman-app-monolith/renderer/appsdk/components/link/Link';
import AnalyticsService from '@postman-app-monolith/renderer/js/modules/services/AnalyticsService';
import { openExportData } from '../../models/services/DashboardService';
import GetUniqueIdHelper from '../../utils/GetUniqueIdHelper';
import { Button } from '../base/Buttons';
import { DropZone } from '../../../js/components/base/DropZone';
import { withTranslation } from 'react-i18next';
import exportAll, { EXPORT_STATE } from '@postman-app-monolith/renderer/js/services/conversion/export-all-scratchpad';
import handleExportZip from '@postman-app-monolith/renderer/js/services/conversion/export-all-scratchpad-zip';
import { LD_CONFIG_EXPORT_SCRATCHPAD_DATA } from '@postman-app-monolith/renderer/runtime-repl/_common/experiments';
import { launchDarkly } from '@postman-app-monolith/renderer/onboarding/src/common/LaunchDarkly';
import styled from 'styled-components';
import { PostmanModalFactory } from '@postman/app-lazy-modals';

/* services */
import OfflineAPIClientService from '@postman-app-monolith/renderer/appsdk/scratchpad/services/OfflineAPIClientService';

export const StyledHeading = styled(Heading)`
  margin-top: var(--spacing-s);
  //placing important here since Heading component itself has a margin-bottom with !important
  margin-bottom: var(--spacing-l) !important;
`;

class SettingsData extends Component {
  constructor (props) {
    super(props);
    this.state = {
      uniqueName: GetUniqueIdHelper.generateUniqueId(),
      showTooltip: false,
      showWarning: true,
      dataExportInProgress: false
    };

    this.handleImportData = this.handleImportData.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handleScratchpadMigration = this.handleScratchpadMigration.bind(this);
  }

  handleImportData (value) {
    this.props?.onImportData(value);
    AnalyticsService.addEventV2({
      category: 'ad_import',
      action: 'data_import_started',
      label: 'data_settings'
    });
  }

  handleExportData (event) {
    event.preventDefault();
    AnalyticsService.addEventV2({
      category: 'im_export',
      action: 'export_data',
      label: 'data_settings'
    });
    openExportData();
  }

  handleScratchpadMigration () {
    if (__SDK_PLATFORM__ === 'desktop') {
      AnalyticsService.addEventV2({
        category: 'signed_in_settings_modal',
        action: OfflineAPIClientService.isEnabled ? 'LAC_user_clicked_migrate_button' : 'scratchpad_user_clicked_migrate_button',
        label: 'signed_in_settings_migrate_action',
        value: 1
      });
      pm.mediator.trigger('closeSettingsModal');
      PostmanModalFactory.openModal({
      lazyModalContent: React.lazy(() => import('@postman-app-monolith/renderer/appsdk/scratchpad/components/ScratchpadSignInSetupModal')),
      modalProps: {
        size: 'small',
        className: 'scratchpad-migration-modal-container',
        'data-testid': 'scratchpad-migration-modal-container'
      }
    });
    }
  }

  toggleTooltip () {
    this.setState((prevState) => {
      return { showTooltip: !prevState.showTooltip };
    });
  }

  exportScratchpadData = () => {

    AnalyticsService.addEventV2({
        category: 'signed_in_settings_modal',
        action: OfflineAPIClientService.isEnabled ? 'LAC_user_clicked_export_button' : 'scratchpad_user_clicked_export_button',
        label: 'signed_in_settings_export_action',
        value: 1
    });
    const { t } = this.props;
    this.setState({ dataExportInProgress: true });

    const { exportDataAsZip } = launchDarkly.getFlag(LD_CONFIG_EXPORT_SCRATCHPAD_DATA.key,
      LD_CONFIG_EXPORT_SCRATCHPAD_DATA.defaultValue);

    if (exportDataAsZip) {

      handleExportZip() // If there were no errors, show success alert
      .then((state) => {
        pm.logger.info('SettingsData~ExportAll: show success message');
        state === EXPORT_STATE.SUCCESS && pm.toasts.success(t('settings:data.export_data.export_data_success_message'));
      })

      // If there was some error, show error alert and log the error
      .catch((err) => {
        pm.toasts.error(t('settings:data.export_data.export_data_error_message'));
        pm.logger.error('SettingsData~ExportAll: Error in pipeline', err);
      })

      // set the flag back to false
      .finally(() => {
        this.setState({ dataExportInProgress: false });
      });
    }
    else {
      exportAll({ exportLevel: 'user' })

      // If there were no errors, show success alert
      .then((state) => {
        state === EXPORT_STATE.SUCCESS && pm.toasts.success(t('settings:data.export_data.export_data_success_message'));
      })

      // If there was some error, show error alert and log the error
      .catch((err) => {
        pm.toasts.error(t('settings:data.export_data.export_data_error_message'));
        pm.logger.error('SettingsData~ExportAll: Error in pipeline', err);
      })

      // set the flag back to false
      .then(() => {
        this.setState({ dataExportInProgress: false });
      });
    }
  }

  render () {

    // i18n translation function
    const { t } = this.props;

    return (
      <Flex direction='column' gap='spacing-xl' className='settings-data-wrapper'>
        <Heading type='h2' text={t('settings:data.label')} />
        <Flex direction='column' gap='spacing-l'>
          <Flex gap='spacing-xl' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }} padding={{ paddingTop: 'spacing-l' }}>
            <Flex shrink={1} grow={1} direction='column'>
              <Text>{t('settings:data.export_data.label')}</Text>
              <Text color='content-color-secondary'>
                <Text type='small'>{t('settings:data.export_data.description')}</Text>
              </Text>
            </Flex>
            {
              this.props.downloadInprogress &&
              <div className='settings-data-export-button-wrapper'>
                <AetherButton type='outline' className='settings-data-export-button' text={t('settings:data.export_data.exporting_label')} />
              </div>
            }
            {
              !this.props.downloadInprogress &&
              <Link to={`${pm.artemisUrl}/me/export`} onClick={this.handleExportData}>
                <Text type='link-button-outline' className='settings-data-export-button'>{t('settings:data.export_data.request_data_export_label')}</Text>
              </Link>
            }
          </Flex>
          <Flex gap='spacing-xl' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }} padding={{ paddingTop: 'spacing-l' }}>
            <Flex shrink={1} grow={1} direction='column'>
              <Text>{t('settings:data.import_data.label')}</Text>
              <Text color='content-color-secondary'>
                <Text type='small'>{t('settings:data.import_data.description')}</Text>
                </Text>
            </Flex>
            <Button type='secondary' className='settings-data-import-button settings-upload-button'>
              <DropZone
                multiple
                ref='importData'
                type='file'
                onDropFile={this.handleImportData}
              >
                {t('settings:data.import_data.upload_files_label')}
              </DropZone>
            </Button>
          </Flex>
          {
              __SDK_PLATFORM__ === 'desktop' && (
            <React.Fragment>
              <Flex direction='column' gap='spacing-xl'>
                <StyledHeading text={t('settings:data.scratchpad_data_heading')} hasBottomSpacing type='h4' />

                <Flex gap='spacing-xl' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }} padding={{ paddingTop: 'spacing-l' }}>
                  <Flex shrink={1} grow={1} direction='column'>
                    <Text>{t('settings:data.migrate_data.label')}</Text>
                    <Text color='content-color-secondary'>
                      <Text type='small'>{t('settings:data.migrate_data.description')}</Text>
                      </Text>
                  </Flex>
                  <AetherButton
                    type='outline'
                    className='settings-data-export-button'
                    onClick={this.handleScratchpadMigration}
                    data-testid='settings-data-export-migrate-data-button'
                    text={t('settings:data.migrate_data.migrate_data_button_label')}
                  />
                </Flex>

                <Flex gap='spacing-xl' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }} padding={{ paddingTop: 'spacing-l' }}>
                  <Flex shrink={1} grow={1} direction='column'>
                    <Text>{t('settings:data.export_scratchpad_data.label')}</Text>
                    <Text color='content-color-secondary'>
                      <Text type='small'>{t('settings:data.export_scratchpad_data.description')}</Text>
                      </Text>
                  </Flex>
                  <AetherButton
                    type='outline'
                    className='settings-data-export-button'
                    onClick={this.exportScratchpadData}
                    data-testid='settings-data-export-migrate-data-button'
                    text={t('settings:data.export_scratchpad_data.export_data_button_label')}
                    isLoading={this.state.dataExportInProgress}
                  />
                </Flex>
              </Flex>
            </React.Fragment>

          )
        }
        </Flex>
      </Flex>
    );
  }
}

export default withTranslation('settings')(SettingsData);
