import React, { Component } from 'react';
import { CERTIFICATES_DOC } from '@postman-app-monolith/renderer/js/constants/AppUrlConstants';
import {
  Button, ButtonGroup, Flex, Heading, Label, TextInput, Text
} from '@postman/aether';
import ProcessedFileInput from '@@runtime-repl/_common/components/ProcessedFileInput';
import Link from '@postman-app-monolith/renderer/appsdk/components/link/Link';
import styled from 'styled-components';
import { Trans, withTranslation } from 'react-i18next';

const StyledInputSeparatorText = styled(Text)`
  margin-top: 6px; // Using absolute value to center align the colon between the two "Host" TextInputs
  font-weight: var(--text-weight-bold);
`;

class AddCertificate extends Component {
  handleFileSelect (field, files) {
    this.props.onChange(field, _.get(files, '[0].path', ''));
  }

  openCertificatesDocumentation () {
    pm.app.openExternalLink(CERTIFICATES_DOC);
  }

  getValueForFileInput (filePath) {
    // If path doesn't exist then return empty array
    if (!filePath) {
      return [];
    }

    // Value exist then create array with single element
    return [filePath];
  }

  render () {
    const { t } = this.props;

    return (
      <Flex direction='column' gap='spacing-l'>
        <Heading text={t('settings:certificates.add_certificate.heading')} type='h2' />
        <Flex direction='column' gap='spacing-l'>
          <Flex gap='spacing-xs' direction='column'>
            <Label text={t('settings:certificates.add_certificate.host.label')} fieldType='required' />
            <Flex gap='spacing-xs' grow={1} shrink={1} alignItems='flex-start'>
              <TextInput
                data-testid='add-certificate-host-name-input'
                value={this.props.hostname}
                placeholder={t('settings:certificates.add_certificate.host.placeholder')}
                validationStatus={this.props.hostnameValidationMessage && 'error'}
                validationMessage={this.props.hostnameValidationMessage}
                onChange={(event) => this.props.onChange('hostname', event.target.value)}
                prefix={t('settings:certificates.add_certificate.host.prefix')}
              />
              <StyledInputSeparatorText color='content-color-tertiary'>:</StyledInputSeparatorText>
              <Flex width='80px'>
                <TextInput
                  data-testid='add-certificate-host-port-input'
                  value={this.props.port}
                  placeholder={t('settings:certificates.add_certificate.port.placeholder')}
                  onChange={(event) => this.props.onChange('port', event.target.value)}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex direction='column' className='add-certificate-fileuploader-wrapper' gap='spacing-xs'>
            <Label>{t('settings:certificates.add_certificate.crt_file')}</Label>
            <ProcessedFileInput
              dataTestId='ca-certificate-crt-file-input'
              value={this.getValueForFileInput(this.props.pemPath)}
              onSelect={this.handleFileSelect.bind(this, 'pemPath')}
              onClear={this.handleFileSelect.bind(this, 'pemPath')}
            />
          </Flex>
          <Flex direction='column' className='add-certificate-fileuploader-wrapper' gap='spacing-xs'>
            <Label>{t('settings:certificates.add_certificate.key_file')}</Label>
            <ProcessedFileInput
              dataTestId='ca-certificate-key-file-input'
              value={this.getValueForFileInput(this.props.keyPath)}
              onSelect={this.handleFileSelect.bind(this, 'keyPath')}
              onClear={this.handleFileSelect.bind(this, 'keyPath')}
            />
          </Flex>
          <Flex direction='column' className='add-certificate-fileuploader-wrapper' gap='spacing-xs'>
            <Label>{t('settings:certificates.add_certificate.pfx_file')}</Label>
            <ProcessedFileInput
              dataTestId='ca-certificate-pfx-file-input'
              value={this.getValueForFileInput(this.props.pfxPath)}
              onSelect={this.handleFileSelect.bind(this, 'pfxPath')}
              onClear={this.handleFileSelect.bind(this, 'pfxPath')}
            />
          </Flex>
          <TextInput
            data-testid='ca-certificate-passphrase-input'
            label={<Label text={t('settings:certificates.add_certificate.passphrase')} />}
            type='password'
            value={this.props.passphrase}
            onChange={(event) => this.props.onChange('passphrase', event.target.value)}
          />
        </Flex>
        <Flex justifyContent='space-between' alignItems='center' padding={{ paddingTop: 'spacing-s' }} gap='spacing-xxl'>
          <ButtonGroup>
            <Button
              data-testid='ca-certificate-add-button'
              type='primary'
              onClick={this.props.onSubmit}
              isDisabled={this.props.hostnameValidationMessage}
              text={t('settings:certificates.add_certificate.add_button_label')}
            />
            <Button
              data-testid='ca-certificate-cancel-button'
              type='secondary'
              onClick={this.props.onCancel}
              text={t('settings:certificates.add_certificate.cancel_button_label')}
            />
          </ButtonGroup>
          <Text type='para'>
            <Trans t={t} i18nKey='settings:certificates.add_certificate.working_with_certificates'>
              Learn more about&nbsp;
              <Link
                to={CERTIFICATES_DOC}
                onClick={this.openCertificatesDocumentation}
                target='_blank'
              >
                <Text type='link-default' isExternal>working with certificates</Text>
              </Link>
            </Trans>
          </Text>
        </Flex>
      </Flex>
    );
  }
}

export default withTranslation('settings')(AddCertificate);
