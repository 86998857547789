/**
 * Returns default font family of the text editor based on the current platform
 */
export function getDefaultTextEditorFontFamily () {
  // Taken from monaco-editor/esm/vs/editor/common/config/editorOptions.js
  var DEFAULT_WINDOWS_FONT_FAMILY = 'IBMPlexMono, \'Courier New\', monospace';
  var DEFAULT_MAC_FONT_FAMILY = 'IBMPlexMono, Monaco, \'Courier New\', monospace';
  var DEFAULT_LINUX_FONT_FAMILY = 'IBMPlexMono, monospace, \'Droid Sans Fallback\'';

  return (global.process.platform === 'darwin' ? DEFAULT_MAC_FONT_FAMILY :
      (global.process.platform === 'linux' ? DEFAULT_LINUX_FONT_FAMILY : DEFAULT_WINDOWS_FONT_FAMILY));
}
