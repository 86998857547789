export const TEXT_EDITOR_VALIDATION_MESSAGE_LEVEL = {
  INFO: 1,
  WARNING: 2,
  ERROR: 3
};

export const TEXT_EDITOR_DEFAULT_SETTINGS = {
  FONT_SIZE: 12,
  INDENT_COUNT: 4,
  INDENT_TYPE: 'space',
  AUTO_CLOSE_BRACKETS: true,
  AUTO_CLOSE_QUOTES: true,
  RENDER_NON_PRINTABLE: false
};
