import _ from 'lodash';
import moment from 'moment';
import async from 'async';
import IntegrationService from '../../../onboarding/src/features/Homepage/pages/IntegrationsPage/services/IntegrationService';
import { NEW_RELIC_SERVICE_ACTION_SLUGS } from '@postman-app-monolith/renderer/integrations/monitoring/constants';

export default {

  listServices: (payload) => {
    return IntegrationService.fetchActionDetailsWithoutIntegration(payload)
      .then((res) => {
        if (res.status && res.status === 200) {
          return Promise.resolve(res.body);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  listDashboards: (payload) => {
    return IntegrationService.fetchActionDetailsWithoutIntegration(payload)
      .then((res) => {
        if (res.status && res.status === 200) {
          return Promise.resolve(res.body);
        } else {
          throw res;
        }
      }).catch((err) => {
        return Promise.reject(err);
      });
  },

  fetchChartUrls: (integrationId, serviceGuid, timeframe, callback) => {
    let fetchedChartUrls = {};
    async.parallel([
      // fetch web transactions time chart url
      function (next) {
        IntegrationService.fetchActionDetails(integrationId, {
          type: 'fetchWebTransactionsChartUrl',
          service_guid: serviceGuid,
          time: timeframe
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            fetchedChartUrls['webTransactionsChartUrl'] = _.get(res.body, 'data.data.actor.entity.nrdbQuery.embeddedChartUrl');
            return next();
          } else {
            throw res;
          }
        })
        .catch((err) => {
          return next(err);
        });
      },
      function (next) {
        IntegrationService.fetchActionDetails(integrationId, {
          type: 'fetchApdexScoreChartUrl',
          service_guid: serviceGuid,
          time: timeframe
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            fetchedChartUrls['apdexScoreChartUrl'] = _.get(res.body, 'data.data.actor.entity.nrdbQuery.embeddedChartUrl');
            return next();
          } else {
            throw res;
          }
        })
        .catch((err) => {
          return next(err);
        });
      },
      function (next) {
        IntegrationService.fetchActionDetails(integrationId, {
          type: 'fetchErrorRateChartUrl',
          service_guid: serviceGuid,
          time: timeframe
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            fetchedChartUrls['errorRateChartUrl'] = _.get(res.body, 'data.data.actor.entity.nrdbQuery.embeddedChartUrl');
            return next();
          } else {
            throw res;
          }
        })
        .catch((err) => {
          return next(err);
        });
      },
      function (next) {
        IntegrationService.fetchActionDetails(integrationId, {
          type: 'fetchThroughputChartUrl',
          service_guid: serviceGuid,
          time: timeframe
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            fetchedChartUrls['throughputChartUrl'] = _.get(res.body, 'data.data.actor.entity.nrdbQuery.embeddedChartUrl');
            return next();
          } else {
            throw res;
          }
        })
        .catch((err) => {
          return next(err);
        });
      }
    ], (err) => {
      if (err) {
        return callback(err);
      }
      return callback(null, fetchedChartUrls);
    });
  },

  fetchStatistics: (integrationId, serviceGuid, timeframe, callback) => {
    let fetchedStatistics = {};
    async.parallel([
      // fetch web transactions time chart url
      function (next) {
        IntegrationService.fetchActionDetails(integrationId, {
          type: 'fetchDeployments',
          service_guid: serviceGuid
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            let deployments = _.map(_.get(res.body, 'data.data.actor.entity.deployments'), (deployment) => {
              return {
                ...deployment,
                timestamp: moment(_.get(deployment, 'timestamp')).format('MMM DD h:mm A')
              };
            });
            fetchedStatistics['deployments'] = deployments;
            return next();
          } else {
            throw res;
          }
        })
        .catch((err) => {
          return next(err);
        });
      },
      function (next) {
        IntegrationService.fetchActionDetails(integrationId, {
          type: 'fetchViolationEvents',
          service_guid: serviceGuid
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            let violationEvents = _.map(_.get(res.body, 'data.data.actor.entities[0].recentAlertViolations'), (violationEvent) => {
              return {
                ...violationEvent,
                openedAt: moment(_.get(violationEvent, 'openedAt')).format('MMM DD h:mm A'),
                closedAt: _.get(violationEvent, 'closedAt') ? moment(_.get(violationEvent, 'closedAt')).format('MMM DD h:mm A') : '-'
              };
            });
            fetchedStatistics['violationEvents'] = violationEvents;
            return next();
          } else {
            throw res;
          }
        })
        .catch((err) => {
          return next(err);
        });
      },
      function (next) {
        IntegrationService.fetchActionDetails(integrationId, {
          type: 'fetchSlowestWebTransactions',
          service_guid: serviceGuid,
          time: timeframe
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            let slowestTranscations = _.map(_.get(res.body, 'data.data.actor.entity.nrdbQuery.results'), (transaction) => {
              return ({
                name: _.replace(transaction.name, 'WebTransaction/Custom/', ''),
                avg_time: `${_.round(transaction.avg_time, 2)} ms`,
                error_rate: `${_.round(transaction.error_rate, 1)}%`
              });
            });
            fetchedStatistics['slowestTransactions'] = slowestTranscations;
            return next();
          } else {
            throw res;
          }
        })
        .catch((err) => {
          return next(err);
        });
      }
    ], (err) => {
      if (err) {
        return callback(err);
      }
      return callback(null, fetchedStatistics);
    });
  },

  fetchMetricsForLinkedServices: (integrationId) => {
    return IntegrationService.fetchActionDetails(integrationId, { type: 'fetchMetricsForLinkedServices' })
      .then((res) => {
        if (res.status && res.status === 200) {
          return Promise.resolve(res.body);
        } else {
          throw res;
        }
      }).catch((err) => {
        return Promise.reject(err);
      });
  },

  fetchLinkedDashboards: (integrationId) => {
    return IntegrationService.fetchActionDetails(integrationId, { type: 'fetchLinkedDashboards' })
      .then((res) => {
        if (res.status && res.status === 200) {
          return Promise.resolve(res.body);
        } else {
          throw res;
        }
      }).catch((err) => {
        return Promise.reject(err);
      });
  },

  listSevicesWithIntegrationId: (integrationId, payload = {}) => {
    return IntegrationService.fetchActionDetails(integrationId, {
      type: NEW_RELIC_SERVICE_ACTION_SLUGS.LIST_SERVICES,
      ...payload
    })
    .then((res) => {
      if (res.status && res.status === 200) {
        return Promise.resolve(_.get(res, 'body.data'));
      } else {
        throw res;
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  },

  listDashboardsWithIntegrationId: (integrationId, payload = {}) => {
    return IntegrationService.fetchActionDetails(integrationId, {
      'type': NEW_RELIC_SERVICE_ACTION_SLUGS.LIST_DASHBOARDS,
      ...payload
    })
      .then((res) => {
        if (res.status && res.status === 200) {
          return Promise.resolve(_.get(res, 'body.data'));
        } else {
          throw res;
        }
      }).catch((err) => {
        return Promise.reject(err);
      });
  },

  authenticateApiKey: (payload) => {
    return IntegrationService.fetchActionDetailsWithoutIntegration(payload)
      .then((res) => {
        if (res.status && res.status === 200) {
          return Promise.resolve(res.body);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
};
