import React, { Component } from 'react';
import AddCertificate from '@@runtime-repl/certificates/AddCertificate';
import { withTranslation } from 'react-i18next';

class AddCertificateContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      hostname: '',
      port: '',
      pemPath: '',
      keyPath: '',
      pfxPath: '',
      passphrase: '',
      hostnameValidationMessage: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkHostnameEmpty = this.checkHostnameEmpty.bind(this);
  }

  checkHostnameEmpty (hostname) {
    return _.isEmpty(hostname);
  }

  handleSubmit () {
    const {
      hostname,
      port,
      pemPath,
      pfxPath,
      keyPath,
      passphrase
    } = this.state;

    if (this.checkHostnameEmpty(hostname)) {
      this.setState({
        hostnameValidationMessage: 'Hostname is required'
      });

      return;
    }

    const host = this.sanitizeHost(hostname, port);

    pm.certificateManager.addCertificate(host, pemPath, keyPath, pfxPath, passphrase);
    this.props.onCancel();
  }

  sanitizeHost (host, port) {
    // If port is given, strip any port in the host

    const sanitizedHost = _.trim(
      host.replace(/.*?:\/\//g, '') // Strip protocol
        .replace(/\?.*/, '') // Strip query
        .replace(/\/.*/, '') // Strip patch
        .replace(/^\./, '') // Strip leading period
    );

    if (!_.isEmpty(port)) {
      const hostNamePort = sanitizedHost.split(':');

      return (`${hostNamePort[0]}:${port}`);
    }

    return sanitizedHost;
  }

  handleChange (key, value) {
    const nextState = _.assign({}, this.state),
      { t } = this.props;

    nextState[key] = value;
    this.setState(nextState, () => {
      if (key === 'hostname') {
        if (this.checkHostnameEmpty(this.state[key])) {
          this.setState({
            hostnameValidationMessage: t('settings:certificates.add_certificate.host.error_message')
          });
        } else {
          this.setState({
            hostnameValidationMessage: ''
          });
        }
      }
    });
  }

  render () {
    return (
      <AddCertificate
        {...this.state}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
      />
    );
  }
}

export default withTranslation('settings')(AddCertificateContainer);
