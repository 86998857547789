import React from 'react';
import { Tooltip } from '@postman/aether';
import styled from 'styled-components';

const StyledShortcutValidationTooltip = styled(Tooltip)`
  & .tippy-content {
    box-sizing: border-box;
    width: 344px;
    text-align: left;
    background-color: ${(props) => props.theme['background-color-secondary']};
    padding: ${(props) =>
      `${props.theme['spacing-s']} ${props.theme['spacing-m']}`};
  }
`;

/**
 * This functional component renders a tooltip which appears whenever the custom shortcut entered by the user has
 * a conflict with existing other shortcuts.
 */
function ShortcutValidationTooltip ({ children, isVisible, content }) {
  return (
    <StyledShortcutValidationTooltip
      isOpen={isVisible}
      content={content}
      isInteractive
      placement='bottom-end'
      maxWidth={344}
    >
      {children}
    </StyledShortcutValidationTooltip>
  );
}

export default ShortcutValidationTooltip;
