import { HTTPGatewayService } from './HTTPGatewayService';
import { CLIENT_CATEGORY } from '../../../../../../../integrations/constants/notificationCenter';
import _ from 'lodash';
import { PRE_CONFIG, POST_CONFIG } from '../../../../../../../integrations/api-observability/constants';
const NUMBER_OF_INTEGRATIONS = 500, NUMBER_OF_RULE_SPECIFIC_INTEGRATIONS = 200, NUMBER_OF_INTEGRATIONS_FOR_SERVICE_DETAILS_PAGE = 200, NUMBER_OF_SERVICE_SPECIFIC_INTEGRATIONS = 500, INTEGRATION_RUNS = 20, API_GIT_INTEGRATION_PREFIX = 'api-git-', INTEGRATION_INSTANCE_BASE_URL = '/integration-instances';
export default {
    /**
     * Returns all available integrations
     */
    getAvailableIntegrations(param = {}) {
        const path = '/integrations/available' + (param.service ? `?service=${param.service}` : '');
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path
            }
        });
    },
    /**
     * Returns all team integrations for an user
     */
    getTeamIntegrations() {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: '/v2/integration',
                query: {
                    count: NUMBER_OF_INTEGRATIONS,
                    lite: true
                }
            }
        });
    },
    /**
     * Returns integrations that satisfies the criteria object
     * Criteria is passed as query params to the call
     *
     * @param {Object} criteria
     * @returns {Promise}
     */
    getNotificationCenterIntegrations(userId) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: '/v2/integration',
                query: {
                    entityType: 'user',
                    entityId: userId,
                    clientCategory: CLIENT_CATEGORY
                }
            }
        });
    },
    getServiceDetails(serviceId) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/services/${serviceId}`
            }
        });
    },
    /**
     *
     * @param {string} ruleId - integration rule id
     * Returns the list of team integrations specific to given ruleID
     * by populating the details about user and the entity
     */
    getTeamIntegrationInstances(ruleId, page) {
        let pageParam = '';
        if (!_.isNil(page)) {
            pageParam = `&page=${page}`;
        }
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/v2/integration?rule=${ruleId}&populateEntities=true&populateUsers=true&count=${NUMBER_OF_RULE_SPECIFIC_INTEGRATIONS}${pageParam}`
            }
        });
    },
    /**
     *
     * @param {string} ruleId - integration rule id
     * Returns the list of team integrations specific to given ruleID
     * @param {boolean} lite - make an optimized call if set to true.
     */
    getConfiguredIntegrationsPerRule: function (ruleId, lite) {
        let params = `?rule=${ruleId}&count=${NUMBER_OF_INTEGRATIONS_FOR_SERVICE_DETAILS_PAGE}`;
        if (!_.isNil(lite)) {
            params += `&lite=${lite}`;
        }
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/v2/integration${params}`
            }
        });
    },
    /**
     *
     * @param {string} serviceId - integration service id
     * Returns the list of team integrations specific to given serviceId
     * @param {boolean} lite - make an optimized call if set to true.
     */
    getConfiguredIntegrationsPerService: function (serviceId) {
        let params = `?serviceId=${serviceId}&count=${NUMBER_OF_SERVICE_SPECIFIC_INTEGRATIONS}&lite=true`;
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/v2/integration${params}`
            }
        });
    },
    /**
     * Add a new integration
     */
    createIntegration(workspaceId, payload) {
        let path = '/integration';
        workspaceId && (workspaceId !== 'none') && (path += `?workspace=${workspaceId}`);
        return new Promise((resolve, reject) => {
            return HTTPGatewayService.request({
                data: {
                    service: 'integrations',
                    method: 'post',
                    path,
                    body: payload
                }
            }).then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    // @ts-ignore
                    pm.windowEvents.emit('integration-added', {
                        classId: payload.rule
                    });
                }
                resolve(res);
            }).catch((res) => {
                reject(res);
            });
        });
    },
    getConfiguredIntegrationsByEntityAndClientCategory(entityType, entityId, categories, internal) {
        const getRemoteRequest = (entityType, entityId, category, internal) => {
            return HTTPGatewayService.request({
                data: {
                    service: 'integrations',
                    method: 'get',
                    path: `/v2/integration?entityType=${entityType}&entityId=${entityId}&clientCategory=${category}&internal=${internal}`
                }
            });
        };
        internal = internal || (entityType === 'apiversion');
        return Promise.all(_.map(categories, (category) => getRemoteRequest(entityType, entityId, category, internal)))
            .then((values) => {
            const resp = Object.create({});
            _.forEach(categories, (category, index) => resp[category] = _.get(values[index], 'body.data', []));
            return resp;
        });
    },
    /**
     * Get Integration rule details
     * @param {string} ruleId
     */
    getIntegrationRuleDetails(ruleId) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/integrations/available?rule=${ruleId}`
            }
        });
    },
    fetchCreatePermissions(rule, entity, entities) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'post',
                path: '/integration/permission-check/create',
                body: {
                    rule,
                    entity,
                    entities
                }
            }
        });
    },
    /**
     * Fetch last 20 integration runs
     * @param {String} integrationId
     */
    getIntegrationRuns(integrationId, runs = INTEGRATION_RUNS) {
        // no run logs needs to be fetched for API Git integrations
        if (_.includes(integrationId, API_GIT_INTEGRATION_PREFIX)) {
            return Promise.resolve({
                status: 200,
                body: {
                    data: []
                }
            });
        }
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/integration/${integrationId}/runs?count=${runs}`
            }
        });
    },
    /**
     * Fetch the configured integration data
     * given integrationId
     * @param {string} integrationId
     */
    getIntegrationData(integrationId) {
        let path = `/integration/${integrationId}?populateStatus=1`;
        if (_.includes(integrationId, API_GIT_INTEGRATION_PREFIX)) {
            integrationId = integrationId.replace(API_GIT_INTEGRATION_PREFIX, '');
            path = `/git/integration?id=${integrationId}`;
        }
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: path
            }
        });
    },
    /**
     * update integration details given integrationId
     * @param {string} integrationId
     * @param {*} payload
     */
    updateIntegration(integrationId, payload) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'put',
                path: `/integration/${integrationId}`,
                body: payload
            }
        });
    },
    /**
     * update integration details given integrationId
     * @param {string} integrationId
     *
     */
    fetchIntegrationRelations(integrationId) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/integration/${integrationId}/relations`
            }
        });
    },
    /**
     * Returns repositories, branches etc
     * according to action specified for a particular
     * integration rule and integrationId
     * @param {*} payload
     * @param {string} payload.type - action like fetchBranches, fetchRepositories
     * according to integration rules
     */
    fetchActionDetails(integrationId, payload) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'post',
                path: `/integration/${integrationId}/actions`,
                body: payload
            }
        });
    },
    /**
     * Returns repositories, branches etc
     * according to action specified for a particular
     * integration rule
     * @param {*} payload
     * @param {string} payload.type - action like fetchBranches, fetchRepositories
     * according to integration rules
     */
    fetchActionDetailsWithoutIntegration(payload) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'post',
                path: '/integration/actions',
                body: payload
            }
        });
    },
    /**
     *
     * Deletes an integration
     * @param {string} integrationId - id of integration to be deleted
     */
    deleteIntegration(integrationId) {
        let path = `/integration/${integrationId}`;
        if (_.includes(integrationId, API_GIT_INTEGRATION_PREFIX)) {
            integrationId = integrationId.replace(API_GIT_INTEGRATION_PREFIX, '');
            path = `/git/api-repository?id=${integrationId}`;
        }
        return new Promise((resolve, reject) => {
            return HTTPGatewayService.request({
                data: {
                    service: 'integrations',
                    method: 'delete',
                    path: path
                }
            }).then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    // @ts-ignore
                    pm.windowEvents.emit('integration-deleted');
                }
                resolve(res);
            }).catch((res) => {
                reject(res);
            });
        });
    },
    /**
     * Fetches the api git integration object configured on the given api version
     *
     * @param apiVersion - the id of api version
     * @returns a promise object
     */
    fetchApiGitIntegration(apiVersion) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/git/api-repository?apiVersion=${apiVersion}`
            }
        });
    },
    /**
     * Fetches the api git integration object configured on the given api version using the v2 API
     *
     * @param apiVersion - the id of api version
     * @returns a promise object
     */
    fetchV2ApiGitIntegration(apiVersion) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'get',
                path: `/v2/git/api-repository?apiVersion=${apiVersion}`
            }
        });
    },
    /**
     * Fetches gateway service action details after the integration has been created
     * @param actionId - the gateway action id
     * @param integrationId - the integration id for which action will execute
     * @param payload - the payload to be sent to action
     * @returns {Promise<{body: *, status: *}>}
     */
    fetchPostConfigurationGatewayActionDetails(actionId, integrationId, payload) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'post',
                path: `/gateway-integrations/${integrationId}/actions/${actionId}`,
                body: payload
            }
        });
    },
    /**
     * Fetches gateway service action details befor the integration is created
     * @param actionId - the gateway action id
     * @param payload - the payload to be sent to action
     * @returns {Promise<{body: *, status: *}>}
     */
    fetchPreConfigurationGatewayActionDetails(actionId, payload) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'post',
                path: `/gateway-integrations/actions/${actionId}`,
                body: payload
            }
        });
    },
    /**
     * A common gateway actions handler which will execute gateway endpoint based upon the actionType
     * @param actionType - the type of action (pre-configuration or post-configuration)
     * @param actionId - the gateway action id
     * @param payload - the payload to be sent to action
     * @param integrationId - the integration id for which action will execute (only required in case of post-configuration action)
     * @returns {Promise<{body: *, status: *}>}
     */
    fetchGatewayActionDetails(actionType, actionId, payload, integrationId) {
        if (actionType === PRE_CONFIG) {
            return this.fetchPreConfigurationGatewayActionDetails(actionId, payload);
        }
        else if (actionType === POST_CONFIG) {
            return this.fetchPostConfigurationGatewayActionDetails(actionId, integrationId, payload);
        }
    },
    /**
     * Triggers the on-demand integrations collection.
     *
     * @param {String} integrationId
     * @param {String} actionSlug The action to be performed , e.g. trigger_build, fetch_repositories
     * @param {Object} payload The payload required apart from the integration variables.
     * @param {Object} payload.inputs The additional inputs required by the on-demand trigger. e.g. {inputs: { branch:'main' } }
     */
    triggerIntegrationOnDemand(integrationId, actionSlug, payload) {
        return HTTPGatewayService.request({
            data: {
                service: 'integrations',
                method: 'post',
                path: `${INTEGRATION_INSTANCE_BASE_URL}/${integrationId}/actions/${actionSlug}`,
                body: payload
            }
        });
    }
};
