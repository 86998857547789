import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Text, Flex, Heading, Button, ToggleSwitch, Spinner } from '@postman/aether';
import ProgressBar from '../base/ProgressBar';
import Markdown from '../base/Markdown';
import AppSettingsDefaults from '../../constants/AppSettingsDefaults';
import GetUniqueIdHelper from '../../utils/GetUniqueIdHelper';
import { launchDarkly } from '@postman-app-monolith/renderer/onboarding/src/common/LaunchDarkly';

const autoDownload = AppSettingsDefaults.autoDownload;

class SettingsUpdate extends Component {
  constructor (props) {
    super(props);

    this.setAppOnline = this.setAppOnline.bind(this);
    this.setAppOffline = this.setAppOffline.bind(this);
    this.handleAutoDownloadChange = this.handleAutoDownloadChange.bind(this);
    this.getLastUpdateCheckedMessage = this.getLastUpdateCheckedMessage.bind(this);

    this.state = {
      uniqueName: GetUniqueIdHelper.generateUniqueId(),
      isAppOnline: navigator.onLine
    };
  }

  UNSAFE_componentWillMount () {
    window.addEventListener('online', this.setAppOnline);
    window.addEventListener('offline', this.setAppOffline);
  }

  componentWillUnmount () {
    window.removeEventListener('online', this.setAppOnline);
    window.removeEventListener('offline', this.setAppOffline);
  }

  handleAutoDownloadChange () {
    let oldValue = this.props.autoDownloadUpdateStatus;

    this.props.onAutoDownloadEnabledChanged(oldValue === autoDownload.ALL ? autoDownload.MINOR : autoDownload.ALL);
  }

  setAppOnline () {
    this.setState({ isAppOnline: true });
  }

  setAppOffline () {
    this.setState({ isAppOnline: false });
  }

  getLastUpdateCheckedMessage () {
    const { t } = this.props;

    if (!this.props.lastUpdateCheckedTimestamp) {
      return '';
    }

    return t('settings:update.update_status_update_not_available.last_checked', { lastUpdateCheckedTimestamp: this.props.lastUpdateCheckedTimestamp });
  }

  renderUpdateStatus (status) {
    var currentVersion = this.props.currentVersion;
    const { t } = this.props;

    switch (status) {
      case 'idle':
        return (
          <Flex justifyContent='space-between' gap='spacing-xl' grow={1} shrink={1} alignItems='center'>
            <Flex shrink={1}>
              <Text>{t('settings:update.update_status_idle.heading', { currentVersion })}</Text>
            </Flex>
            <Flex>
              <Button
                type='primary'
                onClick={this.props.onCheckForUpdate}
                className='update-idle__button'
                isDisabled={!this.state.isAppOnline}
                tooltip={!this.state.isAppOnline ? t('settings:update.update_status_idle.cta_tooltip') : null}
                text={t('settings:update.update_status_idle.cta')}
              />
            </Flex>
          </Flex>
        );
      case 'updateAvailable':
        return (
          <Flex gap='spacing-xl' grow={1} shrink={1} justifyContent='space-between'>
            <Flex shrink={1} direction='column'>
              <Text>{t('settings:update.update_status_update_available.heading')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s'
                }}
              >
                {t('settings:update.update_status_update_available.desc', { currentVersion })}
              </Text>
            </Flex>
            <Flex>
            <Button
              type='primary'
              onClick={this.props.onDownloadUpdate}
              className='update-idle__button'
              isDisabled={!this.state.isAppOnline}
              tooltip={!this.state.isAppOnline ? t('settings:update.update_status_update_available.cta_tooltip') : null}
              text={t('settings:update.update_status_update_available.cta')}
            />
            </Flex>
          </Flex>
        );
      case 'checking':
        return (
          <Flex direction='column' padding='spacing-xxxl' gap='spacing-s' alignItems='center' grow={1}>
            <Spinner />
            <Text>{t('settings:update.update_status_checking')}</Text>
          </Flex>
        );
      case 'updateNotAvailable':
        return (
          <Flex gap='spacing-xl' justifyContent='space-between' grow={1} shrink={1}>
            <Flex shrink={1} direction='column'>
              <Text>{t('settings:update.update_status_update_not_available.heading')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s'
                }}
              >
                {t('settings:update.update_status_update_not_available.desc', { currentVersion })} <br />{this.getLastUpdateCheckedMessage()}
              </Text>
            </Flex>
            <Flex>
            <Button
              type='primary'
              onClick={this.props.onCheckForUpdate}
              className='update-not-available__button'
              isDisabled={!this.state.isAppOnline}
              tooltip={!this.state.isAppOnline ? t('settings:update.update_status_update_not_available.cta_tooltip') : null}
              text={t('settings:update.update_status_update_not_available.cta')}
            />
            </Flex>
          </Flex>
        );
      case 'downloading':
        return (
          <Flex direction='column' grow={1}>
            <Text>{t('settings:update.update_status_downloading')}</Text>
            <ProgressBar />
          </Flex>
        );
      case 'downloaded':
        return (
          <Flex justifyContent='space-between' gap='spacing-xl' grow={1} shrink={1}>
              <Flex direction='column' shrink={1}>
                <Text>{t('settings:update.update_status_downloaded.heading')}</Text>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s'
                  }}
                >
                  {t('settings:update.update_status_downloaded.desc', { currentVersion })}
                </Text>
              </Flex>
            <Flex>
            <Button
              type='primary'
              onClick={this.props.onApplyUpdate}
              text={t('settings:update.update_status_downloaded.cta')}
            />
            </Flex>
          </Flex>
        );
      case 'error':
        return (
          <Flex justifyContent='space-between' gap='spacing-xxxl' grow={1} shrink={1}>
            <Flex shrink={1}>
              <Text type='para' color='content-color-primary'>
                {t('settings:update.update_status_error.desc')}
              </Text>
            </Flex>
            <Flex>
            <Button
              type='primary'
              onClick={(event) => this.props.onCheckForUpdate(event, true)}
              isDisabled={!this.state.isAppOnline}
              tooltip={!this.state.isAppOnline ? t('settings:update.update_status_error.cta_tooltip') : null}
              text={t('settings:update.update_status_error.cta')}
            />
            </Flex>
          </Flex>
        );
      default: {
        return false;
      }
    }
  }

  renderUpdateOptions () {
    let autoDownloadUpdateStatus = this.props.autoDownloadUpdateStatus;
    const { t } = this.props;

    return (
        <Flex
          justifyContent='space-between'
          padding='spacing-m spacing-zero'
          border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
          gap='spacing-xl'
        >
          <Flex direction='column' shrink={1}>
            <Text>{t('settings:update.auto_update_major_version_toggle.label')}</Text>
            <Text
              color='content-color-secondary'
              typographyStyle={{
                fontSize: 'text-size-s',
                lineHeight: 'line-height-s'
              }}
            >
              {t('settings:update.auto_update_major_version_toggle.help')}
            </Text>
          </Flex>
          <Flex>
            <ToggleSwitch
              isChecked={autoDownloadUpdateStatus === autoDownload.ALL}
              onChange={this.handleAutoDownloadChange}
            />
          </Flex>
        </Flex>
    );
  }

  renderReleaseNotes (updateStatus, releaseNotes) {
    if (!_.includes(['updateAvailable', 'downloaded'], updateStatus) || _.isEmpty(releaseNotes)) {
      return false;
    }
    return (
      <Flex padding={{ paddingTop: 'spacing-l' }} border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
        <Markdown
          className={('settings-update-changelog-container', 'changelog-content')}
          source={releaseNotes}
        />
      </Flex>
    );
  }

  render () {
    const { t } = this.props;

    let updateStatus = this.props.updateStatus;
    return (
      <div>
      <Flex direction='column' gap='spacing-xl'>
        <Heading type='h2' text={t('settings:update.page_heading')} />
        <Flex direction='column'>
          {launchDarkly.getFlag('webfn_1616_show_major_version_auto_update_ui', true) && this.renderUpdateOptions()}
          <Flex border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }} grow={1} padding='spacing-l spacing-zero'>
            {this.renderUpdateStatus(updateStatus)}
          </Flex>
          {this.renderReleaseNotes(updateStatus, this.props.releaseNotes)}
        </Flex>
      </Flex>
      </div>

    );
  }
}

SettingsUpdate.defaultProps = { autoDownloadUpdateStatus: 0 };

export default withTranslation('settings')(SettingsUpdate);
