/**
 * Constants to be used for integrations inside API builder view
 */
export const API_OBSERVABILITY = 'api-gateway-observability', AWS_ACCOUNT_ID = '258201882842', DEPLOYMENT_TAB = 'deployment', DEPLOYMENTS_SCREEN = 'deployments', DEPLOYMENTS_PER_PAGE = 5, AUTH_SUCCESS = 'AUTH_SUCCESS', AUTH_PENDING = 'AUTH_PENDING', AUTH_FAILED = 'AUTH_FAILED', PERMISSION_ERROR = 'You don\'t have permission to take this action', INTEGRATION_CONFIGURED_ERROR = 'This action is not allowed as an API Gateway Integration ' +
    'is already configured on some other service.', AVAILABLE_API_GATEWAY_TYPES = [
    {
        label: 'HTTP',
        value: 'http'
    },
    {
        label: 'REST',
        value: 'rest'
    }
], AWS_REST_API = AVAILABLE_API_GATEWAY_TYPES[1].value, AVAILABLE_AUTH_TYPES = [
    {
        label: 'IAM',
        value: 'aws_iam_role'
    },
    {
        label: 'Access Key',
        value: 'aws_keys'
    }
], AWS_IAM_ROLE = AVAILABLE_AUTH_TYPES[0]['value'], AWS_KEYS = AVAILABLE_AUTH_TYPES[1]['value'], AWS_TOTAL_DEPLOYMENTS = 10, FETCH_CLOUDWATCH_AGGREGATE_METRICS = 'fetch-cloudwatch-aggregate-metrics', FETCH_CLOUDWATCH_TIMESERIES_METRICS = 'fetch-cloudwatch-timeseries-metrics', AWS_EXPORT_API_ACTION = 'export-api', DEFAULT_GATEWAY_EXPORT_API_ACTION = 'export-api', AWS_FETCH_API_GATEWAYS = 'fetchAWSGatewayAPIs', AWS_FETCH_EXTERNAL_ID = 'fetchExternalId', AWS_FETCH_STAGES = 'fetch-stages', EDIT_INTEGRATION = {
    value: 'edit',
    label: 'Edit Integration'
}, DELETE_INTEGRATION = {
    value: 'delete',
    label: 'Delete Integration'
}, DEPLOYMENT_TO_STATUS = { FAILED: 'critical', PENDING: 'neutral' }, API_GATEWAY_TYPES = {
    REST: 'rest',
    HTTP: 'http'
}, API_DEFINITION = 'Definition', SCHEMA_SOURCE_TYPE = {
    SCHEMA: 'schema',
    BRANCH: 'branch',
    RELEASE: 'release',
    HEAD: 'head',
    API_DEFINITION: 'apiDefinition'
}, API_VERSION_LINKED_ENTITY_TYPES = {
    RELEASE: 'release',
    BRANCH: 'branch'
}, SOURCE_TO_ICON = {
    schema: 'icon-descriptive-versions-stroke',
    branch: 'icon-descriptive-branch-stroke',
    release: 'icon-descriptive-release-stroke'
}, RELEASE_VIEW = 'initiate-deploy-release-view', DEPLOYMENT_VIEW = 'initiate-deploy-deployments-tab', RELEASE_CONTEXTBAR = 'initiate-deploy-release-contextbar', SOURCE_TO_DEPLOY_ACTION = {
    schema: 'deploy-head',
    branch: 'deploy-branch',
    release: 'deploy-release'
}, SOURCE_TO_EXPORT_ACTION = {
    schema: 'export-head',
    branch: 'export-branch',
    release: 'export-release'
}, AVAILABLE_GATEWAYS_DROPDOWN = [
    {
        ruleName: 'Observe API deployments',
        serviceName: 'AWS API Gateway',
        rule: 'api_gateway_aws',
        friendlyName: 'AWS Gateway',
        icon_url: 'https://static.getpostman.com/assets/aws_api_gateway.png',
        dark_icon_url: 'https://static.getpostman.com/assets/aws_api_gateway.png',
        service: 'api_gateway_aws'
    }
], CLOUDWATCH_METRICS = {
    LATENCY: {
        id: 'Latency',
        label: 'Latency',
        yAxis: 'Average latency (in milliseconds)',
        info: 'The time between when API Gateway receives a request from a client and when it returns a response to the client.' +
            'The latency includes the integration latency and other API Gateway overhead.'
    },
    INTEGRATION_LATENCY: {
        id: 'IntegrationLatency',
        label: 'Integration Latency',
        yAxis: 'Average integration latency (in milliseconds)',
        info: 'The time between when API Gateway relays a request to the backend and when it receives a response from the backend.'
    },
    COUNT: {
        id: 'Count',
        label: 'Count',
        yAxis: 'Total number of API calls',
        info: 'The total number of API requests in a given period.'
    },
    '4XX_ERROR': {
        id: '4XXError',
        label: '4XX Error Count',
        yAxis: 'Number of client-side errors',
        info: 'The number of client-side errors captured in a given period.'
    },
    '5XX_ERROR': {
        id: '5XXError',
        label: '5XX Error Count',
        yAxis: 'Number of server-side errors',
        info: 'The number of server-side errors captured in a given period.'
    }
}, TIMEFRAMES = [
    {
        label: 'Since 1 hour',
        value: '1 hour',
        unit: 'minute',
        period: 60,
        duration: 60 * 60 * 1000 // milliseconds
    },
    {
        label: 'Since 3 hours',
        value: '3 hours',
        unit: 'hour',
        period: 60 * 60,
        duration: 3 * 60 * 60 * 1000 // milliseconds
    },
    {
        label: 'Since 1 day',
        value: '1 day',
        unit: 'hour',
        period: 60 * 60,
        duration: 24 * 60 * 60 * 1000 // milliseconds
    },
    {
        label: 'Since 3 days',
        value: '3 days',
        unit: 'day',
        period: 60 * 60,
        duration: 3 * 24 * 60 * 60 * 1000 // milliseconds
    },
    {
        label: 'Since 1 week',
        value: '1 week',
        unit: 'day',
        period: 24 * 60 * 60,
        duration: 7 * 24 * 60 * 60 * 1000 // milliseconds
    }
], CLOUDWATCH_METRICS_INFO = {
    LATENCY: 'The average latency(in milliseconds) in the last seven days.',
    '5XX_ERROR': 'The number of server-side errors captured in the last seven days.',
    '4XX_ERROR': 'The number of client-side errors captured in the last seven days.'
}, CLOUDWATCH_METRICS_DOCS_LINK = {
    [AVAILABLE_AUTH_TYPES[0]['value']]: 'https://go.pstmn.io/docs-aws-iam-cloudwatch-metrics',
    [AVAILABLE_AUTH_TYPES[1]['value']]: 'https://go.pstmn.io/docs-aws-cloudwatch-metrics'
}, CLOUDWATCH_METRICS_BANNER = {
    title: 'You can now view CloudWatch Metrics.',
    description: 'Please assign required permissions to fetch CloudWatch metrics.'
}, CLOUDWATCH_BANNER_SETTING = 'showCloudWatchOnboardingBanner', AWS_CREDENTIALS_DOCS_LINK = 'https://go.pstmn.io/docs-aws-api-gateway-keys', AWS_IAM_ROLE_EXTERNAL_ID_LINK = 'https://go.pstmn.io/docs-apigateway-integration-confused-duty-problem', AWS_IAM_ROLE_DOCS_LINK = 'https://go.pstmn.io/docs-apigateway-integration-iam', GENERIC_CLOUDWATCH_METRICS_ERROR = 'Something went wrong while fetching CloudWatch metrics. Please try again.', AWS_API_GATEWAY_BASE_URL = 'https://console.aws.amazon.com/apigateway', ADD = 'add', EDIT = 'edit', INITIATED = 'initiated', FAILED = 'failed', SUCCESS = 'success', OAUTH2 = 'oauth2', API_VERSION_IDENTIFIER = 'build.apiVersion', API_VERSION_RELEASE_IDENTIFIER = 'build.release', FETCH_ORGANIZATIONS = 'fetch-organizations', FETCH_API_PROXIES = 'fetch-api-proxies', FETCH_ENVIRONMENTS = 'fetch-environments', FETCH_API_PROXY_META = 'fetch-api-proxy-meta', FETCH_DEPLOYMENTS = 'fetch-deployments', FETCH_ENVIRONMENTS_META = 'fetch-environments-meta', FETCH_LATEST_REVISION_META = 'fetch-latest-revision-meta', LIST_SUBSCRIPTIONS = 'list-subscriptions', LIST_RESOURCE_GROUPS = 'list-resource-groups', LIST_SERVICES = 'list-apim-services', LIST_APIS = 'list-apis', PRE_CONFIG = 'pre-configuration', POST_CONFIG = 'post-configuration', DEPLOYED_REVISION_INFO = 'A Deployed revision is a numbered, version-controlled package of configuration ' +
    'and policies bundled into an API Proxy which is deployed on an environment.', STATUS_INFO = 'Status shows the state of the environment. Values other than ACTIVE means the resource is not ready to use.', AUTHENTICATE_STEP_HEADING = 'Authenticate with Apigee X', AUTHENTICATE_STEP_DESCRIPTION_1 = 'Postman uses ‘Service Accounts’ and ‘Private’ Key to connect with Apigee X.', AUTHENTICATE_STEP_DESCRIPTION_2 = 'to create a Service Account and Private Key on Apigee X.', AZURE_GATEWAY_ACTIONS = {
    'GET_SERVICE_META': 'get-service-meta',
    'GET_API_META': 'get-api-meta',
    'GET_SUBSCRIPTION_META': 'get-subscription-meta',
    'LIST_API_RELEASES': 'list-api-releases',
    'LIST_API_REVISIONS': 'list-api-revisions',
    'PERFORM_REVISION_ACTION': 'perform-revision-action',
    'GET_REVISION_SOURCE_MAPPING': 'get-revision-source-mapping',
    'EXPORT_API': 'export-api'
}, AWS_GATEWAY_ACTIONS = {
    'EXPORT_API': 'export-api'
}, IMPORT_FORM_SOURCES = {
    'INTEGRATION_SETUP': 'integration-setup',
    'DEPLOY_DASHBOARD': 'deploy-dashboard'
}, SCHEMA_TYPES = [
    {
        label: 'OpenAPI 3.0',
        value: 'openapi3'
    },
    {
        label: 'OpenAPI 2.0',
        value: 'openapi2'
    }
], API_BUILDER_ROUTE_IDENTIFIERS = {
    API_VERSION: 'build.apiVersion'
}, AETHER_STEPPER_STATUS = {
    PROGRESS: 'progress',
    PENDING: 'pending',
    COMPLETED: 'completed'
}, DROPDOWN_WIDTH = 440, DEPLOYMENTS_VIEW = 'deployments', SYNC_STATUS_TIMEOUT = 8 * 1000, categoryToEntityType = {
    [API_OBSERVABILITY]: 'apiversion'
}, categoryToClientCategory = {
    [API_OBSERVABILITY]: API_OBSERVABILITY
}, SETUP_AZURE_API_MANAGEMENT_SERVICES = 'Setup Azure API Management Services', GATEWAY_INTEGRATION_ANALYTICS_EVENT_CATEGORY = 'gateway-integration', AZURE_APIM = {
    SERVICE_ANALYTICS_ACTION: 'services-added'
};
