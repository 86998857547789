import GlobalsController from '@@runtime-repl/globals/datastores/controllers/GlobalsController';
import WorkspaceController from '../../modules/controllers/WorkspaceController';
import { sanitizeVariableValue } from './util';

const GLOBALS = 'globals',
      GLOBALS_NAME_SUFFIX = 'Globals',
      EXPORTED_USING_PREFIX = 'Postman/',
      FILE_NAME_EXT = '.postman_globals.json',
      TAB = '\t',
      allowedGlobalsFields = ['id', 'values'];

/**
 * Gets the context which will be used by other steps in exporting the globals
 * @param {Object} criteria
 * @returns {Promise} which resolves into the context
 */
function getContext (criteria) {
  let workspaceId = criteria.workspace;

  return WorkspaceController.get({ id: workspaceId })
      .then((workspace) => {
        return workspace;
      })

      // Do not crash the export even if we couldn't get the workspace
      .catch((err) => {
        pm.logger.error('Could not get workspace while exporting globals', err);
        return {}; // if workspace was not found we store empty object
      })

      .then((workspace) => {
        return {
          modelId: workspaceId,
          workspace: workspace || {}
        };
      });
}

/**
 * Gets the globals from the db
 * @param {Object} criteria
 */
function getFromDb (criteria) {
  return GlobalsController.get(criteria);
}

/**
 * Sanitizes the globals before exporting
 * @param {Object} globals
 */
function sanitize (globals) {
  let sanitizedGlobals = _.pick(globals, allowedGlobalsFields);
  sanitizedGlobals.values = _.map(sanitizedGlobals.values, sanitizeVariableValue);

  return sanitizedGlobals;
}


/**
 * This transforms the globals into the exportable format
 * It adds few meta properties
 * @param {Object} globals
 * @param {Object} context
 * @returns {Object} Transformed globals
 */
function transform (globals, context = {}) {
  let workspaceName = _.get(context, 'workspace.name', ''),
      globalsName = _.trim(`${workspaceName} ${GLOBALS_NAME_SUFFIX}`);

  return _.merge({}, globals, {
    name: globalsName,
    _postman_variable_scope: GLOBALS,
    _postman_exported_at: (new Date()).toISOString(),
    _postman_exported_using: EXPORTED_USING_PREFIX + context.appVersion
  });
}

/**
 * Returns the entity name
 * @param {Object} globals
 * @param {Object} context
 */
function getEntityName (globals, context) {
  return _.get(context, 'workspace.name', 'workspace');
}

/**
 * Returns the fileName that can be used if exporting to a file
 * @param {Object} globals
 * @param {Object} context
 */
function getFileName (globals, context) {
  return this.getEntityName(globals, context) + FILE_NAME_EXT;
}

/**
 * Serializes the globals
 * @param {Object} globals
 * @returns {String} Stringified globals
 */
function serialize (globals) {
  return JSON.stringify(globals, null, TAB);
}

export default {
  getContext,
  getFromDb,
  sanitize,
  transform,
  getEntityName,
  getFileName,
  serialize
};
