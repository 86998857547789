
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Modal, ModalContent, ModalHeader } from '@postman/aether';
import SettingsTabs from '../../components/settings/SettingsTabs';
import SettingsTabContents from '../../components/settings/SettingsTabContents';
import SettingsTabContent from '../../components/settings/SettingsTabContent';
import SettingsGeneralContainer from './SettingsGeneralContainer';
import SettingsThemesContainer from './SettingsThemesContainer';
import SettingsProxyContainer from '@@runtime-repl/proxy/SettingsProxyContainer';
import SettingsConnectedAccountsContainer from '@postman-app-monolith/renderer/js/containers/settings/SettingsConnectedAccountsContainer';
import SettingsUpdateContainer from './SettingsUpdateContainer';
import SettingsCertificatesContainer from '@@runtime-repl/certificates/SettingsCertificatesContainer';
import SettingsDataContainer from './SettingsDataContainer';
import SettingsShortcutsContainer from './SettingsShortcutsContainer';
import SettingsAddonsContainer from './SettingsAddonsContainer';
import SettingsAboutContainer from './SettingsAboutContainer';
import SettingsDevOptions from '../../components/settings/SettingsDevOptions';
import SettingsModalUIStore from '@postman-app-monolith/renderer/js/stores/SettingsModalUIStore';
import PlanFeaturesStore from '@postman-app-monolith/renderer/billing/stores/PlanFeaturesStore';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import { TRACK_SUPPORTED_CHANNELS } from '../../constants/TrackSupportConstants';
import { shouldShowConnectedAccountsTab } from '../../utils/connectedAccountsUtil';
import { isEnterpriseApplication } from '../../utils/enterpriseUtil';

const enterpriseApplication = isEnterpriseApplication();

import {
  SETTINGS_GENERAL,
  SETTINGS_THEMES,
  SETTINGS_SHORTCUTS,
  SETTINGS_DATA,
  SETTINGS_ADDONS,
  SETTINGS_SYNC,
  SETTINGS_CERT,
  SETTINGS_PROXY,
  SETTINGS_UPDATE,
  SETTINGS_ABOUT,
  SETTINGS_DEV_OPTIONS,
  SETTINGS_CONNECTED_ACCOUNTS
} from '../../constants/SettingsTypeConstants';


@observer
export default class SettingsModalContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      settingIDToScroll: null,
      shouldShowConnectedAccounts: false
    };

    this.store = resolveStoreInstance(SettingsModalUIStore);
    this.planFeaturesStore = resolveStoreInstance(PlanFeaturesStore);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleOnAfterOpen = this.handleOnAfterOpen.bind(this);
    this.getCustomStyles = this.getCustomStyles.bind(this);
    this.updateConnectedAccountsTab = this.updateConnectedAccountsTab.bind(this);
  }

  /**
   * If the user has opened the settings modal with no connected account and during the session attaches one,
   * they would see the width of the connected accounts as 720px because the state of the component is not updated.
   * The below method would be invoked to update the shouldShowConnectedAccounts state when the tab is
   * eligible to be rendered thus fixing the width.
   *
   * @param {Boolean} shouldShowConnectedAccounts Flag to determine if the connected account tab is eligible to be dispalayed.
   */
  updateConnectedAccountsTab (shouldShowConnectedAccounts) {
    this.setState({ shouldShowConnectedAccounts });
  }

  async componentDidMount () {
    const shouldShowConnectedAccounts = await shouldShowConnectedAccountsTab();

    this.setState({ shouldShowConnectedAccounts });

    if (this.store.isSettingsModalOpen) {
      this.handleOpen(this.props.tab, this.props.settingID);
    }
  }


  /**
   * opens the settings modal
   * @param {string} tab - name of the tab to be shown
   * @param {string} settingID - id of the setting in the active tab to which the settings modal should scroll to
   */
  handleOpen (tab, settingID = null) {
    this.setState({ settingIDToScroll: settingID }, () => {
      if (tab && this.isValidTab(tab)) {
        this.store.setActiveTab(tab);
      }
      else {
        this.store.setActiveTab(this.store.activeTab);
      }

      pm.mediator.trigger('modalOpened', {
        name: 'settings',
        activeTab: tab || this.store.activeTab
      });
      this.handleOnAfterOpen();
    });
  }

  handleTabSelect (id) {
    if (id === this.store.activeTab) {
      return;
    }

    this.store.setActiveTab(id);

    pm.mediator.trigger('modalOpened', {
      name: 'settings',
      activeTab: id
    });

    // @todo Add a new option to the transition to replace the url and not add it
    // to the history when internal tabs are switched
  }

  /**
   * Scrolls to the selected setting after the modal is opened
   */
  handleOnAfterOpen () {
    if (this.state.settingIDToScroll) {
      const elementToScroll = document.getElementById(this.state.settingIDToScroll);
      elementToScroll?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.setState({ settingIDToScroll: null });
    }
  }

  isValidTab (tab) {
    switch (tab) {
      case SETTINGS_GENERAL:
      case SETTINGS_THEMES:
      case SETTINGS_SHORTCUTS:
      case SETTINGS_DATA:
      case SETTINGS_ADDONS:
      case SETTINGS_SYNC:
      case SETTINGS_CERT:
      case SETTINGS_PROXY:
      case SETTINGS_CONNECTED_ACCOUNTS:
      case SETTINGS_UPDATE:
      case SETTINGS_ABOUT:
      case SETTINGS_DEV_OPTIONS:
        return true;
    }
    return false;
  }

  componentWillUnmount () {
    this.settingsPageFlagReactionDisposer && this.settingsPageFlagReactionDisposer();
  }

  getCustomStyles () {
    // If the Connected Accounts tab is visible, it causes the tabs section
    // to show a horizontal scroll. Hence we add a conditional width as Connected
    // Accounts is applicable only for a specific set of users
    const { shouldShowConnectedAccounts } = this.state;

    return {
      marginTop: '20vh',
      width: shouldShowConnectedAccounts ? '860px' : '720px',
      height: '65vh'
    };
  }

  render () {
    const isIntegrationsEnabled = _.get(this.planFeaturesStore, ['plan', 'features', 'integrations_enabled', 'value'], true);

    return (
      <>
        <ModalHeader
          className='settings-modal--header'
          heading='' // Adding empty heading to render close button in the modal
          onClose={this.props.closeModal}
        />
        <ModalContent className='settings-modal--content'>
          <div className='settings-container'>
            <SettingsTabs
              onSelect={this.handleTabSelect}
              activeTab={this.store.activeTab}
              updateConnectedAccountsTab={this.updateConnectedAccountsTab}
            />
            <SettingsTabContents
              activeKey={this.store.activeTab}
            >
              <SettingsTabContent key={SETTINGS_GENERAL}>
                <SettingsGeneralContainer />
              </SettingsTabContent>
              <SettingsTabContent key={SETTINGS_THEMES}>
                <SettingsThemesContainer />
              </SettingsTabContent>
              <SettingsTabContent key={SETTINGS_SHORTCUTS}>
                <SettingsShortcutsContainer />
              </SettingsTabContent>
              <SettingsTabContent key={SETTINGS_DATA}>
                <SettingsDataContainer />
              </SettingsTabContent>
              <SettingsTabContent key={SETTINGS_ADDONS}>
                <SettingsAddonsContainer />
              </SettingsTabContent>
              {/* <SettingsTabContent key={SETTINGS_SYNC}>
                <SettingsSyncContainer />
              </SettingsTabContent> */}
              <SettingsTabContent key={SETTINGS_CERT}>
                <SettingsCertificatesContainer />
              </SettingsTabContent>
              <SettingsTabContent key={SETTINGS_PROXY}>
                <SettingsProxyContainer />
              </SettingsTabContent>
              {
                isIntegrationsEnabled &&
                <SettingsTabContent key={SETTINGS_CONNECTED_ACCOUNTS}>
                  <SettingsConnectedAccountsContainer />
                </SettingsTabContent>
              }
              {
                  __SDK_PLATFORM__ !== 'browser' && !enterpriseApplication &&
                  <SettingsTabContent key={SETTINGS_UPDATE}>
                    <SettingsUpdateContainer />
                  </SettingsTabContent>
              }
              <SettingsTabContent key={SETTINGS_ABOUT}>
                <SettingsAboutContainer />
              </SettingsTabContent>
              {
                (TRACK_SUPPORTED_CHANNELS.has(window.RELEASE_CHANNEL)) &&
                <SettingsTabContent key={SETTINGS_DEV_OPTIONS}>
                  <SettingsDevOptions />
                </SettingsTabContent>
              }
            </SettingsTabContents>
          </div>
        </ModalContent>
      </>
    );
  }
}
