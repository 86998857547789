const allowedVariableFieldKeys = ['key', 'value', 'type', 'contentType', 'description', 'enabled'];

/**
 * Sanitizes the environment/globals values
 * @TODO Update this to follow the schema
 * @param {Array<Object>} values
 * @returns {Object} sanitized variable value
 */
export function sanitizeVariableValue (value) {
  return _.pick(value, allowedVariableFieldKeys);
}
