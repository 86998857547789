import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Flex, SingleSelect, Text } from '@postman/aether';
import AnalyticsService from '../../modules/services/AnalyticsService';
import {
  NETWORK_CONNECTION_MODE_POLLING,
  NETWORK_CONNECTION_MODE_WEBSOCKET,
  NETWORK_SETTINGS_CATEGORY
} from '../../constants/ConnectionModeConstants';
import ConnectionModeStore from '../../stores/ConnectionModeStore';

/**
 * This component renders a Settings menu to configure connection mode
 * It enables users to use long polling mode as alternative connection mode to
 * connect to Bifrost
 */
const SettingsConnectionMode = observer(() => {
  useEffect(() => {
    const disposer = ConnectionModeStore.setupReaction();
    return disposer;
  }, []);

  const { t } = useTranslation('settings');

  const labelMap = {
    [NETWORK_CONNECTION_MODE_WEBSOCKET]: {
      label: t('settings:general.application.connection_mode_auto'),
      value: NETWORK_CONNECTION_MODE_WEBSOCKET
    },
    [NETWORK_CONNECTION_MODE_POLLING]: {
      label: t('settings:general.application.connection_mode_http'),
      value: NETWORK_CONNECTION_MODE_POLLING
    }
  };

  let value = labelMap[ConnectionModeStore.connectionMode];

  return (
    ConnectionModeStore.enableConnectionModeSettings && (
      <Flex
        gap='spacing-xl'
        justifyContent='space-between'
        padding='spacing-l spacing-zero'
        border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
      >
        <Flex direction='column' shrink={1}>
          <Text>{t('settings:general.application.connection_mode')}</Text>
          <Text
            color='content-color-secondary'
            typographyStyle={{
              fontSize: 'text-size-s',
              lineHeight: 'line-height-s',
            }}
          >
            {t('settings:general.application.connection_mode_explanation')}
          </Text>
        </Flex>
        <Flex grow={0} shrink={0}>
          <SingleSelect
            value={value}
            options={Object.values(labelMap)}
            onChange={(selectedOption) => {
              let { value } = selectedOption;
              ConnectionModeStore.setConnectionMode(value);
              AnalyticsService.addEventV2({
                category: NETWORK_SETTINGS_CATEGORY,
                action: 'network_mode_updated',
                label: `network_mode_${value}`
              });
            }}
            isClearable={false}
            isSearchable={false}
            triggerWidth='100px'
            menuPlacement='top-end'
            menuMinWidth='120px'
          />
        </Flex>
      </Flex>
    )
  );
});

export default SettingsConnectionMode;
