/**
 * Constants to be used for integrations inside API builder view
 */
import { INTEGRATION_CLASS_NAME as SENTRY_CLASS_NAME,
  SERVICE_ID as SENTRY_SERVICE_ID
} from '@postman-app-monolith/renderer/integrations/monitoring/sentry/constants';
import { launchDarkly } from '@postman-app-monolith/renderer/onboarding/src/common/LaunchDarkly';

export const API_MONITORING = 'apm_api-observability',
API_PERFORMANCE_INTEGRATION = 'api-performance-integrations',
ENTITY = 'jobTemplate',
CREATE_SOURCE = 'api_builder_create_forms',
EDIT_SOURCE = 'api_builder_edit_forms',
SERVICES_CONTAINER = 'services_container',
APM_DASHBOARD = 'apm_dashboad',
AUTH_SUCCESS = 'AUTH_SUCCESS',
AUTH_PENDING = 'AUTH_PENDING',
AUTH_FAILED = 'AUTH_FAILED',
AUTH_PRESENT = 'AUTH_PRESENT',
NEW_RELIC_APM_CLASS_ID = 'apm_new_relic',
NEW_RELIC_SERVICE_ID = 'apm_new_relic',
DATADOG_APM_CLASS_ID = 'apm_datadog',
NEW_RELIC_MONITOR_CLASS_ID = 'monitor_run_new_relic',
MONITOR_RUN_NR_INTEGRATION_NAME = 'Monitor to NewRelic',
DATADOG_MONITOR_CLASS_ID = 'monitor_run_datadog',
APM_DATADOG_INTEGRATION_NAME = 'APM with Datadog',
MONITOR_RUN_DATADOG_INTEGRATION_NAME = 'Monitor to Datadog',
APM_NR_INTEGRATION_NAME = 'APM with NewRelic',
ONLY_ONE_APM_INTEGRATION_ERROR = 'You can\'t create more than one APM integrations for this service.',
SERVICE_NOT_SUPPORTED_ON_OLD_APP_ERROR = 'Please update the app to install this integration.',
RULE_TO_TITLE = {
  apm_new_relic: 'New Relic APM',
  apm_datadog: 'Datadog APM',
  [SENTRY_CLASS_NAME]: 'Sentry',
  monitor_run_splunk: 'Splunk',
  monitor_run_victorops: 'Splunk On-Call',
  monitor_run_bigpanda: 'BigPanda',
  monitor_run_datadog: 'Datadog',
  monitor_run_microsoft_flow: 'Microsoft Power Automate',
  monitor_run_microsoft_teams: 'Microsoft Teams',
  monitor_run_opsgenie: 'Opsgenie',
  monitor_run_pagerduty: 'PagerDuty',
  monitor_run_slack: 'Slack',
  monitor_run_statuspage: 'Statuspage',
  monitor_run_keen_io: 'Keen',
  monitor_run_coralogix: 'Coralogix',
  monitor_run_new_relic: 'New Relic',
  monitor_run_custom_webhooks: 'Custom Webhooks'
},
SERVICE_TO_LABEL = {
  microsoft_flow: 'Microsoft Power Automate',
  microsoft_teams: 'Microsoft Teams',
  victorops: 'Splunk On-Call',
  bigpanda: 'BigPanda',
  statuspage: 'Statuspage',
  pagerduty: 'PagerDuty',
  opsgenie: 'Opsgenie',
  slack: 'Slack',
  splunk: 'Splunk',
  datadog: 'Datadog',
  keen_io: 'Keen',
  coralogix: 'Coralogix',
  apm_new_relic: 'New Relic APM',
  [SENTRY_SERVICE_ID]: 'Sentry',
  new_relic: 'New Relic',
  custom: 'Custom Webhooks'
},
RULES = {
  SLACK: 'monitor_run_slack'
},
NEW_RELIC_SERVICE_ACTION_SLUGS = {
  LIST_SERVICES: 'listServices',
  LIST_DASHBOARDS: 'listDashboards',
  GET_USER_INFO: 'getUserInfo'
},
OAUTH_ENABLED_RULES = [],
AUTH_STATES = {
  AUTHORISING: 'authorising',
  AUTHORISED: 'authorised',
  FAILED: 'failed'
},
SHOW_INTEGRATION_RUN_DROPDOWNS = false,
UPTIME_MONITOR_INTEGRATIONS_LD_FLAG = 'IS_2321_api_uptime_monitor_enabled',
SKIP_BUTTON_TEXT = {
  'configureAPM': 'Skip',
  'configureMonitoring': 'Maybe Later'
},
DATADOG_MONITOR_STATUSES = {
  ALERT: 'Alert',
  WARN: 'Warn',
  NO_DATA: 'No Data',
  OK: 'OK',
  NO_MONITORS: 'No Monitors',
  UNAVAILABLE: 'Unavailable'
},
TIMESTAMP = 'MMM DD h:mm A',
EDIT_MODE = 'edit',
ADD_MODE = 'add',

AUTH_SUPPORTED_CLASSES = [NEW_RELIC_APM_CLASS_ID],
AUTHENTICATION_CLASSES = {
  NEW_RELIC_USER: 'new_relic_user'
},
/** Display names of properties for API Performance power-up section */
APM_POWER_UP_FIELDS = ['Services', 'Source'],

AUTHENTICATION_INSTANCE_SCOPES = {
  USER: 'user',
  TEAM: 'team',
  API: 'api'
},
TEAM_AUTH_FORBIDDEN_ERROR = 'AuthenticationInstanceForbiddenError',

ADD_NEW_RELIC_TO_TEAM = {
  LABEL: 'Enable New Relic for your team',
  HELPER_TEXT: 'Let team members connect to services from New Relic using this authenticated account. After enabling, you can manage this account from settings.'
},

NEW_RELIC_REGIONS = {
  US: 'us',
  EU: 'eu'
},

INSTALLED_APP_FEATURE_FLAG = {
  [NEW_RELIC_APM_CLASS_ID]: {
    key: 'is-4251-team-scope-auth-enabled',
    defaultValue: false
  }
},

isFeatureFlagEnabled = (integrationClass) => {
  return launchDarkly.getFlag(INSTALLED_APP_FEATURE_FLAG[integrationClass].key, INSTALLED_APP_FEATURE_FLAG[integrationClass].defaultValue);
},

ERROR_ADDING_NEW_RELIC_TO_TEAM = 'Something went wrong while adding New Relic for your team, please try again.',
ADD_NEW_RELIC_TO_TEAM_FORBIDDEN = 'You don\'t have the required permission to add New Relic for team';
