import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Text, Radio, RadioGroup, Heading, SingleSelect, Badge, Banner, Button } from '@postman/aether';
import { POSTMAN_THEMES_ENUM } from '../../themes/utils';
import { observer } from 'mobx-react';
import SystemDefaultTheme from '@postman-app-monolith/renderer/js/components/settings/SystemDefaultThemeIllustration';
import { IconActionLightModeStroke, IconActionDarkModeStroke } from '@postman/aether-icons';
import ThumbnailIllustration from './ThumbnailIllustration';
import ThemeManager from '../../controllers/theme/ThemeManager';
import ThemesList from '../../apps/shared/theme';
import { toJS } from 'mobx';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import ActiveWorkspaceThemeStore from '@postman-app-monolith/renderer/js/stores/ActiveWorkspaceThemeStore';
import Link from '@postman-app-monolith/renderer/appsdk/components/link/Link';

const DAY_THEMES = [
  POSTMAN_THEMES_ENUM.LIGHT,
  POSTMAN_THEMES_ENUM.HIGH_CONTRAST_LIGHT,
  POSTMAN_THEMES_ENUM.AYU_LIGHT,
  POSTMAN_THEMES_ENUM.NIGHT_OWL_LIGHT,
  POSTMAN_THEMES_ENUM.SOLARIZED_LIGHT
];

const NIGHT_THEMES = [
  POSTMAN_THEMES_ENUM.DARK,
  POSTMAN_THEMES_ENUM.HIGH_CONTRAST_DARK,
  POSTMAN_THEMES_ENUM.AYU_DARK,
  POSTMAN_THEMES_ENUM.DRACULA,
  POSTMAN_THEMES_ENUM.MONOKAI,
  POSTMAN_THEMES_ENUM.NIGHT_OWL_DARK,
  POSTMAN_THEMES_ENUM.SOLARIZED_DARK
];

const DAY_THEMES_ORDER = [...DAY_THEMES, ...NIGHT_THEMES];
const NIGHT_THEMES_ORDER = [...NIGHT_THEMES, ...DAY_THEMES];

/**
 * @param {*} themes - list of available themes
 * @param {*} SORT_ORDER - order based on which themes will be sorted
 * @returns a new array with updated themes order
 */
function sortThemesByOrder (themes, SORT_ORDER) {
  const sortedThemes = [];

  for (const orderValue of SORT_ORDER) {
      const matchingTheme = themes.find((theme) => theme.value === orderValue);
      if (matchingTheme) {
          sortedThemes.push(matchingTheme);
      }
  }

  return sortedThemes;
}

const StyledIllustrationContainer = styled('div')`
  position: relative;
  border-radius: var(--border-radius-default);
  width: 100%;
  height: 80px;
  overflow: hidden;
`;

const StyledDisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
`;

const StyledDescriptionText = styled(Text)`
  max-inline-size: 68ch;
`;

const StyledIconContainer = styled(Flex)`
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
`;

const StyledThemeContainer = styled('div')`
  cursor: pointer;

  &.active-theme {
    > div:first-child { // to add highlight to the illustration wrapper div
        box-shadow: 0 0 0px 2px var(--background-color-primary), 0 0 0px 4px var(--base-color-brand); // To add inset space inside the active theme orange highlighter
    }
  }
`;

const StyledThemeItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--spacing-xl);
`;

const StyledSystemThemeHeadingContainer = styled.div`
  margin-bottom: var(--spacing-l);
`;

const StyledSystemThemePreviewContainer = styled.div`
  position: relative;
  margin-bottom: var(--spacing-m);
  border-radius: 6px;
  overflow: hidden;
  width: 248px;
  height: 125px;
`;

const StyledHeadingSection = styled(Flex)`
  &.settings-themes-heading {
    margin-bottom: var(--spacing-m);
  }
`;

/**
 * Renders the actual theme settings view
 */
 function SettingsThemes ({ currentTheme, dayNightConfig, handleThemeSelect, handleDayNightConfigChange, isSystemDayActive }) {

  const [workspaceThemeInfoBanner, setWorkspaceThemeBannerVisibility] = useState(false);
  const { t } = useTranslation('settings');
  const userPreferenceTheme = ThemeManager.getCurrentTheme();
  const themesErrors = toJS(ThemesList.error);
  const [needToRetry, setNeedToRetry] = React.useState(false);
  const [needToReload, setNeedToReload] = React.useState(false);
  const [displayRetryBanner, setDisplayRetryBanner] = React.useState(true);
  const [displayReloadBanner, setDisplayReloadBanner] = React.useState(true);

  const retryHandler = () => {
    ThemesList.hydrateAllThemes();
  };

  const reloadHandler = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    if (!ThemesList[userPreferenceTheme]) {
      setNeedToReload(true);
    }
    else {
      setNeedToReload(false);
    }
  }, []);

  React.useEffect(() => {
    const entries = Object.entries(themesErrors);

    // If any one of the entries is not undefined, we still need to retry
    // We need to exclude the current theme though because
    // if the current theme fails to load, we already have a banner for that.
    const errorIndex = entries.findIndex((entry) => {
      return entry[0] !== userPreferenceTheme && entry[1];
    });

    if (errorIndex > -1) {
      setNeedToRetry(true);
    }
    else {
      setNeedToRetry(false);
    }
  }, [themesErrors]);

  let themeItems = [
    { value: POSTMAN_THEMES_ENUM.LIGHT, label: t('settings:themes.theme_option.light'), displayName: t('settings:themes.theme_option.light') },
    { value: POSTMAN_THEMES_ENUM.DARK, label: t('settings:themes.theme_option.dark'), displayName: t('settings:themes.theme_option.dark') }
  ];

  themeItems.push({ value: POSTMAN_THEMES_ENUM.HIGH_CONTRAST_LIGHT, label: t('settings:themes.theme_option.high_contrast_light'), displayName: t('settings:themes.theme_option.high_contrast_light') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.HIGH_CONTRAST_DARK, label: t('settings:themes.theme_option.high_contrast_dark'), displayName: t('settings:themes.theme_option.high_contrast_dark') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.AYU_LIGHT, label: t('settings:themes.theme_option.ayu_light'), displayName: t('settings:themes.theme_option.ayu_light') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.AYU_DARK, label: t('settings:themes.theme_option.ayu_dark'), displayName: t('settings:themes.theme_option.ayu_dark') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.DRACULA, label: t('settings:themes.theme_option.dracula'), displayName: t('settings:themes.theme_option.dracula') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.MONOKAI, label: t('settings:themes.theme_option.monokai'), displayName: t('settings:themes.theme_option.monokai') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.NIGHT_OWL_LIGHT, label: t('settings:themes.theme_option.night_owl_light'), displayName: t('settings:themes.theme_option.night_owl_light') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.NIGHT_OWL_DARK, label: t('settings:themes.theme_option.night_owl_dark'), displayName: t('settings:themes.theme_option.night_owl_dark') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.SOLARIZED_LIGHT, label: t('settings:themes.theme_option.solarized_light'), displayName: t('settings:themes.theme_option.solarized_light') });
  themeItems.push({ value: POSTMAN_THEMES_ENUM.SOLARIZED_DARK, label: t('settings:themes.theme_option.solarized_dark'), displayName: t('settings:themes.theme_option.solarized_dark') });

  // Excluding out the theme options from the system theme dropdowns
  // so that they cannot be selected as an option.
  let dayThemeDropdownOptions = sortThemesByOrder(themeItems, DAY_THEMES_ORDER).filter((themeItem) => ThemesList[themeItem.value]);
  let nightThemeDropdownOptions = sortThemesByOrder(themeItems, NIGHT_THEMES_ORDER).filter((themeItem) => ThemesList[themeItem.value]);

  const ThemeItemsView = () => (
    <StyledThemeItemsContainer padding={{ paddingBottom: 'spacing-xl' }} gap='spacing-xl' wrap='wrap'>
      {
        themeItems.map((themeItem, index) => {
          const { value: theme, displayName: themeLabel, view: View } = themeItem;
          const isDisabled = theme !== POSTMAN_THEMES_ENUM.SYSTEM && !ThemesList[theme];
          const Illustration = View ? <View /> : <ThumbnailIllustration theme={theme} />;

          return (
            <StyledThemeContainer
              onClick={(evt) => {
                evt.stopPropagation();

                if (isDisabled) {
                  return;
                }

                handleThemeSelect(theme);
              }}
              className={currentTheme === theme ? 'active-theme' : ''}
              key={index}
            >
              <StyledIllustrationContainer>
                { isDisabled && <StyledDisabledOverlay /> }
                {Illustration}
              </StyledIllustrationContainer>
              <Flex padding='spacing-xs spacing-zero spacing-s spacing-zero'>
                <RadioGroup
                  onChange={handleThemeSelect}
                  value={currentTheme === theme ? theme : false}
                >
                  <Radio value={theme} label={themeLabel} isDisabled={isDisabled} />
                </RadioGroup>
              </Flex>
            </StyledThemeContainer>
          );
        })
      }
    </StyledThemeItemsContainer>
  );

  useEffect(() => {
    const isWorkspaceThemeDefined = Boolean(
      resolveStoreInstance(ActiveWorkspaceThemeStore).accentColor ||
      resolveStoreInstance(ActiveWorkspaceThemeStore).themeColor
    );
    setWorkspaceThemeBannerVisibility(isWorkspaceThemeDefined);
  }, []);

  return (
    <Flex direction='column' gap='spacing-xl'>
      <StyledHeadingSection className='settings-themes-heading' direction='column' gap='spacing-s'>
        <Heading type='h2' text={t('settings:themes.label')} />
        <div className='settings-page-description'>
          <StyledDescriptionText type='para'>{t('settings:themes.description')}</StyledDescriptionText>
        </div>
        {!displayReloadBanner && !displayRetryBanner && workspaceThemeInfoBanner && (
          <Banner status='info' onDismiss={() => setWorkspaceThemeBannerVisibility(false)}>
            <Text type='para' color='content-color-primary'>
              <Trans t={t} i18nKey='settings:themes.banners.workspace_theme_applied_note'>
                This workspace has its own theme, so you can't apply or preview your selected themes here. No worries, your preference will be saved.
                <Link
                  target='_blank'
                  to='https://learning.postman.com/docs/collaborating-in-postman/using-workspaces/managing-workspaces/#changing-workspace-themes'
                >
                  <Text type='link-default'>Learn more</Text>
                </Link>
              </Trans>
            </Text>
          </Banner>
        )}
      </StyledHeadingSection>
      <>
      {
          needToReload && displayReloadBanner &&
          (
            <Banner status='error' onDismiss={() => setDisplayReloadBanner(false)}>
              <Text>
                <Trans t={t} i18nKey='settings:themes.reload_banner_text'>
                  We've encountered an error while applying the theme. Reload the page to try again.&nbsp;
                  <Button type='monochrome-plain' onClick={reloadHandler} text='Reload' />
                </Trans>
              </Text>
            </Banner>
          )
        }
        {
          needToRetry && displayRetryBanner &&
          (
            <Banner status='error' onDismiss={() => setDisplayRetryBanner(false)}>
              <Text>
                <Trans t={t} i18nKey='settings:themes.banner_text'>
                  We’re having trouble loading themes.&nbsp;<Button type='monochrome-plain' onClick={retryHandler} text='Try again' />
                </Trans>
              </Text>
            </Banner>
          )
        }
        <Flex justifyContent='space-between'
          padding='spacing-s spacing-zero'
          border={{ borderBottom: 'border-width-default' }}
          alignItems='flex-end'
        >
          <Text type='para'>{t('settings:themes.mode')}</Text>
          <RadioGroup
            value={currentTheme === POSTMAN_THEMES_ENUM.SYSTEM ? 'system' : 'not-system'}
            onChange={(value) => {
              const isSystem = value === 'system';

              if (isSystem) {
                handleThemeSelect(POSTMAN_THEMES_ENUM.SYSTEM);
              }
              else {
                handleThemeSelect(ThemeManager.getCurrentTheme());
              }

            }}
          >
            <Radio value='system' label={t('settings:themes.mode_options.system')} />
            <Radio value='not-system' label={t('settings:themes.mode_options.single')} />
          </RadioGroup>
        </Flex>
        {
          currentTheme !== POSTMAN_THEMES_ENUM.SYSTEM ?
          <ThemeItemsView /> :
          (
            <Flex justifyContent='space-between'>
              <div>
                <StyledSystemThemeHeadingContainer>
                  <Flex gap='spacing-s'>
                    <StyledIconContainer
                      justifyContent='center'
                      alignItems='center'
                      height='36px'
                      width='36px'
                    >
                      <IconActionLightModeStroke />
                    </StyledIconContainer>
                    <div>
                      <Flex alignItems='baseline'>
                        <Heading color='content-color-primary' type='h4' text={t('settings:themes.mode_system.headings.day')} />
                        {isSystemDayActive && <Badge status='info' text={t('settings:themes.mode_system.badge')} />}
                      </Flex>
                      <Text type='body-small' color='content-color-secondary'>{t('settings:themes.mode_system.descriptions.day')}</Text>
                    </div>
                  </Flex>
                </StyledSystemThemeHeadingContainer>
                <StyledSystemThemePreviewContainer>
                  <ThumbnailIllustration theme={dayNightConfig.DAY} />
                </StyledSystemThemePreviewContainer>
                <SingleSelect
                  viaPortal
                  menuMaxHeight={200}
                  onChange={(selection) => {
                    handleDayNightConfigChange({
                      ...dayNightConfig,
                      DAY: selection.value
                    });
                  }}
                  options={dayThemeDropdownOptions}
                  value={themeItems.find((current) => current.value === dayNightConfig.DAY)}
                  isSearchable={false}
                  isClearable={false}
                  menuPlacement='bottom-end'
                  triggerWidth='parent'
                  menuWidth='parent'
                />
              </div>
              <div>
                <StyledSystemThemeHeadingContainer>
                  <Flex gap='spacing-s'>
                    <StyledIconContainer
                      justifyContent='center'
                      alignItems='center'
                      height='36px'
                      width='36px'
                    >
                      <IconActionDarkModeStroke />
                    </StyledIconContainer>
                    <div>
                      <Flex alignItems='baseline'>
                        <Heading color='content-color-primary' type='h4' text={t('settings:themes.mode_system.headings.night')} />
                          {!isSystemDayActive && <Badge status='info' text={t('settings:themes.mode_system.badge')} />}
                      </Flex>
                      <Text type='body-small' color='content-color-secondary'>{t('settings:themes.mode_system.descriptions.night')}</Text>
                    </div>
                  </Flex>
                </StyledSystemThemeHeadingContainer>
                <StyledSystemThemePreviewContainer>
                  <ThumbnailIllustration theme={dayNightConfig.NIGHT} />
                </StyledSystemThemePreviewContainer>
                <SingleSelect
                  menuMaxHeight={200}
                  viaPortal
                  onChange={(selection) => {
                    handleDayNightConfigChange({
                      ...dayNightConfig,
                      NIGHT: selection.value
                    });
                  }}
                  options={nightThemeDropdownOptions}
                  value={themeItems.find((current) => current.value === dayNightConfig.NIGHT)}
                  isSearchable={false}
                  isClearable={false}
                  menuPlacement='bottom-end'
                  triggerWidth='parent'
                  menuWidth='parent'
                />
              </div>
            </Flex>
          )
        }
      </>
    </Flex>
  );
}

export default observer(SettingsThemes);
