export const LEVEL_TO_BADGE_STATUS_MAPPING = {
  error: 'critical',
  fatal: 'critical',
  info: 'info',
  warning: 'warning',
  debug: 'neutral',
  default: 'neutral'
},
ERROR_MESSAGES = {
  NO_ACCESS_TOKEN_ORG_SLUG_FOR_PROJECT: 'Please enter the organization slug and access token',
  UNABLE_TO_FETCH_PROJECTS: 'Error occurred while fetching projects',
  UNABLE_TO_FETCH_API: 'Something went wrong while fetching APIs, please try again',
  UNABLE_TO_FETCH_API_VERSIONS: 'Something went wrong while fetching API versions, please try again',
  ONLY_ONE_SENTRY_INTEGRATION_ALLOWED: 'You can\'t create more than one Sentry integration for this service.',
  UNABLE_TO_FETCH_ISSUES_DETAILS: 'Unable to load issue details.',
  DEFAULT: 'Something went wrong, please try again.'
},
SENTRY_ONDEMAND_TRIGGERS = {
  FETCH_ISSUES: 'fetch_issues',
  FETCH_ISSUE_DETAILS: 'fetch_issue_details',
  FETCH_ENVIRONMENTS: 'fetch_environments',
  FETCH_PROJECT_DETAILS: 'fetch_project_details'
},
ANALYTICS_EVENT_CATEGORY = 'sentry-integration',
SOURCE = {
  MONITORING_TAB: 'monitoring-tab',
  DETAILD_VIEW: 'detailed-view'
},
SYNC_STATUS_TIMEOUT = 5 * 1000,
INTEGRATION_CLASS_NAME = 'api_version_sentry',
SERVICE_ID = 'sentry',
DETAILED_ISSUES_ROUTE_IDENTIFIER = 'build.detailedSentryIssuesView',
LOADING_PROJECT_NAME_MESSAGE = 'Loading project name...',
LOADING_ISSUE_DETAILS = 'Loading issue details...',
ENVIROMENT_LOADING_FAILED_TOAST = {
  title: 'Unable to load Sentry environments',
  description: 'Refresh to try again.'
},
SINGLE_API_CALL_ISSUE_LIMIT = 15,
DURATION_OPTIONS = [
  {
    label: 'Last Hour',
    value: '1h'
  },
  {
    label: 'Last 24 hours',
    value: '24h'
  },
  {
    label: 'Last 7 days',
    value: '7d'
  },
  {
    label: 'Last 14 days',
    value: '14d'
  },
  {
    label: 'Last 30 days',
    value: '30d'
  },
  {
    label: 'Last 90 days',
    value: '90d'
  }
],
ALL_ENVIRONEMENT_OPTION_VALUE = 'all',
ENVIRONMENT_DEFAULT_OPTION = [
  {
    label: 'All Environments',
    value: ALL_ENVIRONEMENT_OPTION_VALUE
  }
],
DEFAULT_DURATION_INDEX = 3;
