import { KeyboardKey } from '@postman/aether';
import React from 'react';

/**
 * This component renders the keyboard key labels
 */
function ShortcutKey (props) {
  const { keyLabel, isDisabled } = props;
  return (
    <KeyboardKey keyLabel={keyLabel} isDisabled={isDisabled} />
  );
}

export default ShortcutKey;
