import React from 'react';
import styled from 'styled-components';
import { Flex, Heading } from '@postman/aether';
import ShortcutItem from './ShortcutItem';

const StyledHeading = styled(Heading)`
  margin-bottom: var(--spacing-s) !important;
`;

/**
 * Renders individual shortcut groups like request, sidebar etc.
 */
function ShortcutGroup ({ group, areShortcutsEnabled }) {
  if (!group.name || _.isEmpty(group.shortcuts)) {
    return null;
  }

  return (
    <Flex direction='column' padding={{ paddingTop: 'spacing-xl' }}>
      <StyledHeading
        type='h4'
        text={group.name}
        color={areShortcutsEnabled ? 'content-color-primary' : 'content-color-tertiary'}
      />
      {
        group.shortcuts.map((shortcut, index) => {
          if (!shortcut) {
            return null;
          }

          return (
            <ShortcutItem
              key={`${group.name}-${index}`}
              shortcut={shortcut}
              areShortcutsEnabled={areShortcutsEnabled}
            />
          );
        })
      }
    </Flex>
  );
}

export default ShortcutGroup;
