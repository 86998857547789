import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Table, Text, Flex, Button, TextInput, Modal,
  ModalHeader, ModalContent, ModalFooter, Avatar } from '@postman/aether';
import {
  IconActionEditStroke,
  IconActionDeleteStroke,
  IconActionViewStrokeSmall,
  IconActionHideStrokeSmall,
  IconBrandLogoGithub,
  IconBrandLogoBitbucket,
  IconBrandLogoGitlab,
  IconBrandLogoDevOps,
  IconBrandLogoNewRelic
} from '@postman/aether-icons';

import CurrentUserStore from '@postman-app-monolith/renderer/js/stores/CurrentUserStore';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import { isUserAdmin } from '@postman-app-monolith/renderer/js/utils/UserHelper';
import { OAUTH_TOKEN } from '@@renderer/integrations/git/services/OnPrem/constants';
import { isGitAuthenticationClass } from '@postman-app-monolith/renderer/integrations/git/stores/helpers';

const StyledFlex = styled(Flex)`
  min-height: 24px;
`,
  StyledRowContainer = styled(Flex)`
    min-height: 24px;

    .auth-instance-actions-container {
      display: none;
    }

    :hover .auth-instance-actions-container {
      display: flex;
    }
  `,
  StyledEntityContainer = styled(Flex)`
    max-width: 50%;
  `,
  StyledEntityContent = styled(Flex)`
    max-width: 100%;
  `;

/**
 *
 * @param {string} [authenticationClass] - Authentication class
 * @returns {boolean} - True if edit and delete CTA needs to be disabled otherwise false
 */
const isEditAndDeleteDisabled = (authenticationClass, authenticationInstance) => {
  // On-prem Git tokens are always allowed to edit and delete
  if (!authenticationClass) return false;

  const currentUserStore = resolveStoreInstance(CurrentUserStore) || {};

  // Check if the user has the required scope to delete and edit the auth instance ie. user scope
  if (_.find(authenticationInstance.scopes, { type: 'user', identifier: currentUserStore.id, authenticationInstance: authenticationInstance.id })) {
    return false;
  }

  // If the above checks are not met then it means that the token is of team scope.
  // Only team admins are allowed to edit and delete team scoped authentication instances
  return !isUserAdmin(currentUserStore);
};

/**
 * Returns true if the edit icons are to be shown for a specific auth instance
 *
 * @param {String} authenticationClass - Id of the authentication class
 */
const shouldShowEditIcon = (authenticationClass) => {
  // We hide the edit icon if the auth class is a git based auth class as the flow is OAuth based
  // and the client side is not exposed to OAuth based tokens
  if (isGitAuthenticationClass(authenticationClass)) {
    return false;
  }

  return true;
};

/**
 * Retrieve service object
 * @param {I18nextProviderProps} i18n
 * @returns
 */
function getServices (i18n) {
  return {
    github: {
      label: i18n.t('integrations:token_table.service.github'),
      Icon: IconBrandLogoGithub
    },
    bitbucket: {
      label: i18n.t('integrations:token_table.service.bitbucket'),
      Icon: IconBrandLogoBitbucket
    },
    gitlab: {
      label: i18n.t('integrations:token_table.service.gitlab'),
      Icon: IconBrandLogoGitlab
    },
    azure: {
      label: i18n.t('integrations:token_table.service.azure'),
      Icon: IconBrandLogoDevOps
    },
    apm_new_relic: {
      label: i18n.t('integrations:token_table.service.apm_new_relic.label'),
      Icon: IconBrandLogoNewRelic,
      errorMessage: i18n.t('integrations:token_table.service.apm_new_relic.error_msg')
    }
  };
}

/**
 * The column structure to be rendered in the table.
 * Includes actions that needs to be performed.
 */
function getColumns (i18n) {
  const SERVICES = getServices(i18n);
  return [
    {
      Header: i18n.t('integrations:token_table.table_header.service'),
      accessor: (token) => {
        return token;
      },
      Cell: ({ value }) => {
        const { customDomain = '', service } = value,
          { label, Icon } = SERVICES[service],
          serviceName = customDomain ? customDomain : label;

        return (
          <StyledFlex direction='row' gap='spacing-s' alignItems='center' height='fit-content'>
            {Icon && <Icon />}
            <Text isTruncated color='content-color-primary'>{serviceName}</Text>
          </StyledFlex>
        );
      },
      align: 'left',
      cellWidth: '40%'
    },
    {
      Header: i18n.t('integrations:token_table.table_header.authorized_as'),
      accessor: (token) => {
        return token;
      },
      Cell: ({ value }) => {
        /**
         * This column renders the fetched list of auths based on the type of auth. The combinations are as follows
         * 1. If the auth belongs to a cloud Git based service
         *   - Shows avatar and the name of the user
         *   - These details are stored in the auth instance storage
         * 2. If the auth is of type PAT and is stored locally
         *   - Then we show masked view for the token
         *   - Show eye icon to make the token visible
         * 3. If the auth belongs to a cloud Git based service but doesn't have the user data stored
         *   - We show the masked view for the token only
         * 4. If the auth belongs to NR integration
         *  - We show the same view as PAT local without the eye icon
         */
        const { id, accessToken = '', service, customDomain = '', authenticationClass, deleteToken, updateToken, data = {},
          type: authType, user: onPremUser, } = value,
          { user: userData = {} } = data,
          { avatar, name, username } = _.merge({}, userData, onPremUser),
          showAuthenticatedAs = avatar && (name || username),
          isOnPremOAuthType = !_.isEmpty(customDomain) && authType === OAUTH_TOKEN,
          showEditIcon = shouldShowEditIcon(authenticationClass) && !isOnPremOAuthType,
          [isTokenHidden, setIsTokenHidden] = useState(true),
          [isEditMode, setIsEditMode] = useState(false),
          [token, setToken] = useState(accessToken),
          [isSaving, setIsSaving] = useState(false),
          [inputError, setInputError] = useState(''),
          [inputErrorMessage, setInputErrorMessage] = useState(''),
          [isDeleting, setIsDeleting] = useState(false),
          [showModal, setShowModal] = useState(false),
          onSaveToken = async () => {
            setIsSaving(true);
            setInputError('');
            setInputErrorMessage('');

            const { status: isSaveSuccess, reason: validationError } = await updateToken({
              id, accessToken: token, customDomain, service, authenticationClass, data
            });

            if (isSaveSuccess) {
              setIsEditMode(false);
              setInputError('');
              setInputErrorMessage('');
            }
            else {
              let errorMessage;

              if (validationError === 'invalidToken') {
                errorMessage = _.get(SERVICES[service], 'errorMessage', i18n.t('integrations:token_table.error_message.invalid_token'));
              }
              else if (validationError === 'serverUnreachable') {
                errorMessage = i18n.t('integrations:token_table.error_message.server_unreachable');
              }
              else {
                errorMessage = i18n.t('integrations:token_table.error_message.updating_auth');
              }

              setInputError('error');
              setInputErrorMessage(errorMessage);
            }

            setIsSaving(false);
          },
          onDeleteToken = async () => {
            setIsDeleting(true);
            // The deleteToken will re-render the table component to reflect the updated state.
            await deleteToken({ id, service });

            setIsDeleting(false);
            setShowModal(false);
          },
          isDisabled = isEditAndDeleteDisabled(authenticationClass, value),
          renderColumnContent = () => {
            if (showAuthenticatedAs) {
              return (
                <StyledEntityContent gap='spacing-s' alignItems='center'>
                  <Avatar
                    size='xs'
                    src={avatar}
                  />
                  <Text color='content-color-primary' isTruncated>{name || username}</Text>
                </StyledEntityContent>
              );
            }

            /* Token can be empty for authentication instances */
            return (
              <Text isTruncated color='content-color-secondary'>
                {isTokenHidden ? '•'.repeat(10) : token}
              </Text>
            );
          };

        if (isEditMode) {
          return (
            <Flex direction='row' gap='spacing-s' alignItems='flex-start'>
              <TextInput
                type='text'
                value={token}
                onChange={(e) => {
                  setToken(e.target.value);
                }}
                validationStatus={inputError}
                validationMessage={inputErrorMessage}
                size='small'
              />
              <Button
                type='secondary'
                size='small'
                text={i18n.t('integrations:token_table.edit_mode.cancel_button_label')}
                onClick={() => {
                  setToken(accessToken);
                  setIsEditMode(false);
                  setInputError('');
                }}
              />
              <Button type='primary' size='small' text={i18n.t('integrations:token_table.edit_mode.save_button_label')} isLoading={isSaving} onClick={onSaveToken} />
            </Flex>
          );
        }

        return (
          <StyledRowContainer direction='row' justifyContent='space-between'>
            {/* This shows the delete modal */}
            {
              showModal && (
                <Modal size='small' isOpen={showModal} onClose={() => setShowModal(false)}>
                  <ModalHeader heading={i18n.t('integrations:token_table.delete_confirmation.heading')} />
                  <ModalContent>
                    <Text type='para'>
                      {i18n.t('integrations:token_table.delete_confirmation.desc')}
                    </Text>
                  </ModalContent>
                  <ModalFooter>
                    <Flex justifyContent='flex-end' grow={1} shrink={1} gap='spacing-m'>
                      <Button
                        type='secondary'
                        text={i18n.t('integrations:token_table.delete_confirmation.cancel_button_label')}
                        onClick={() => setShowModal(false)}
                      />
                      <Button
                        type='destructive'
                        text={i18n.t('integrations:token_table.delete_confirmation.delete_button_label')}
                        isLoading={isDeleting}
                        onClick={onDeleteToken}
                      />
                    </Flex>
                  </ModalFooter>
                </Modal>
              )
            }
            <StyledEntityContainer direction='row' alignItems='center'>
              { showEditIcon &&
                <Button
                  size='small'
                  type='tertiary'
                  icon={isTokenHidden ? <IconActionViewStrokeSmall /> : <IconActionHideStrokeSmall />}
                  onClick={() => { setIsTokenHidden(!isTokenHidden); }}
                />
              }
              {renderColumnContent()}
            </StyledEntityContainer>
            <Flex direction='row' className='auth-instance-actions-container'>
              {
                showEditIcon &&
                  <Button
                    isDisabled={!!isDisabled}
                    type='tertiary'
                    size='small'
                    tooltip={isDisabled ? i18n.t('integrations:token_table.row_actions.disabled_action_tooltip') : i18n.t('integrations:token_table.row_actions.edit_authentication_tooltip')}
                    icon={<IconActionEditStroke />}
                    onClick={() => { setIsEditMode(true); }}
                  />
              }
              <Button
                isDisabled={!!isDisabled}
                type='tertiary'
                size='small'
                isLoading={isDeleting}
                tooltip={isDisabled ? i18n.t('integrations:token_table.row_actions.disabled_action_tooltip') : i18n.t('integrations:token_table.row_actions.delete_authentication_tooltip')}
                icon={<IconActionDeleteStroke />}
                onClick={() => setShowModal(true)}
              />
            </Flex>
          </StyledRowContainer>
        );
      },
      align: 'left',
      cellWidth: '60%'
    }
  ];
}

/**
 * Component to render the token table in settings page and the settings modal
 */
export default function TokenTable (props) {
  const { i18n } = useTranslation('integrations');
  const columns = getColumns(i18n);
  return (
    <Table data={props.tokenList} columns={columns} />
  );
}

TokenTable.propTypes = {
  tokenList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      service: PropTypes.string.isRequired,
      accessToken: PropTypes.string.isRequired,
      customDomain: PropTypes.string,
      authenticationClass: PropTypes.string,

      // Optional as we do not have support to edit tokens that are not stored in local storage
      updateToken: PropTypes.func,
      deleteToken: PropTypes.func.isRequired
    }).isRequired
  ).isRequired
};


