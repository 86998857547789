import React, { Component } from 'react';
import SettingsAddons from '../../components/settings/SettingsAddons';

export default class SettingsAddonsContainer extends Component {
  constructor (props) {
    super(props);
  }

  render () {

    return (
      <SettingsAddons />
    );
  }
}
