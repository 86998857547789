import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from '@postman/react-dnd-html5-backend';
import { Button } from './Buttons';
import PropTypes from 'prop-types';
import { trackAmplitudeEvent, Events } from '@postman/app-amplitude';

const folderTarget = {
  canDrop (props, monitor) {
    return props.canDrop;
  },

  drop (props, monitor, component) {
    const files = monitor.getItem().files;
    if (files && files.type === props.type) {
      props && props.onDropFile(files.list);
    }
    else if (files && props.onlyDirectory && files.type !== props.type) {
      pm.toasts.error('Dropped content contains files. You can only drop folders here.');
    }
  }
};

@DropTarget(NativeTypes.FILE, folderTarget, (connect, monitor) => {
  return ({
    connectDropTarget: connect.dropTarget(),
    isDragOver: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
  });
})

export class DropZone extends Component {
  constructor (props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  UNSAFE_componentWillMount () {
    // this iframe is required for the drag and drop to work
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }

  handleClick (e) {
    if (!this.props.disableFileInput) {
      this.open();
    }
  }

  open () {
    const fileInput = this.refs.fileInput;

    fileInput.value = null;
    fileInput.click();
  }

  handleChange (e) {
    this.props.onDropFile && this.props.onDropFile(Array.prototype.slice.call(e.target.files));
  }

  handleClickEvent (e) {
    e && _.isFunction(e.stopPropagation) && e.stopPropagation();
    const source = _.get(this.props, 'source');

    source && trackAmplitudeEvent(Events.InviteModalAddFromFileClicked, {
      source: source,
    });
  }

  render () {
    const { children } = this.props;
    const { connectDropTarget, disableFileInput, accept, multiple, name } = this.props;
    const hideStyle = { display: 'none' };
    return (
      connectDropTarget(<div className={this.props.className} onClick={this.handleClick}>
          {
            _.isFunction(children)
              ? children({ isDragOver: this.props.isDragOver })
              : children
          }
          <input
            type='file'
            style={hideStyle}
            className='upload-input'
            accept={accept}
            onChange={this.handleChange}
            onClick={this.handleClickEvent}
            ref='fileInput'
            multiple={multiple}
            name={name}
          />
        </div>)
    );
  }

  componentDidMount () {
    // https://github.com/facebook/react/issues/3468
    if (!this.props.disableFileInput && this.props.type === 'directory') {
      this.refs.fileInput.setAttribute('webkitdirectory', '');
      this.refs.fileInput.setAttribute('directory', '');
    }
  }
}

DropZone.defaultProps = {
  canDrop: true,
  multiple: true,
  disableFileInput: false
};

DropZone.propTypes = {
  accept: PropTypes.string,
  disableFileInput: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  canDrop: PropTypes.bool
};
