var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-ignore
import { NEW_RELIC_APM_CLASS_ID, NEW_RELIC_SERVICE_ACTION_SLUGS } from '@postman-app-monolith/renderer/integrations/monitoring/constants';
// @ts-ignore
import NewRelicService from '@postman-app-monolith/renderer/integrations/monitoring/services/NewRelicService';
import { getErrorContextObject } from '@postman-app-monolith/renderer/integrations/utils/CommonHelperMethods';
const context = getErrorContextObject('TokenManagerUtils');
export const 
/**
 * Verifies the apiKey provided by the user during edit is valid
 */
validateNewRelicApiKey = (apiKey, data, authenticationClass, service) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield NewRelicService.authenticateApiKey({
            rule: NEW_RELIC_APM_CLASS_ID,
            type: NEW_RELIC_SERVICE_ACTION_SLUGS.GET_USER_INFO,
            api_key: apiKey,
            region: _.get(data, 'region')
        });
        return true;
    }
    catch (error) {
        pm.logger.error('TokenManagerContainer ~ isAuthInputValid: Could not verify API key', error, {
            context,
            service,
            authenticationClass
        });
        return false;
    }
});
