import async from 'async';
import React, { Component } from 'react';
import {
  Text, Button, Flex, Tooltip
} from '@postman/aether';
import { IconActionDeleteStroke, IconStateWarningStrokeSmall } from '@postman/aether-icons';
import PasswordInput from '@postman-app-monolith/renderer/js/components/base/PasswordInput';
import { CERTIFICATES_DOC } from '@postman-app-monolith/renderer/js/constants/AppUrlConstants';
import styled from 'styled-components';
import { Trans, withTranslation } from 'react-i18next';
import Link from '../../appsdk/components/link/Link';

const StyledCertificateListItemContainer = styled(Flex)`
  max-width: 100%;
`,
  StyledCertificateListItemContent = styled(Flex)`
  max-width: 90%;
`;

class CertificateList extends Component {
  constructor (props) {
    super(props);

    this.state = { warningList: [] };
  }

  componentDidMount () {
    this.computeWarningList(this.props);
  }

  computeWarningList (props) {
    async.map(props.certificates, (cert, cb) => {
      async.parallel({
        crt: (next) => {
          pm.runtime.pathAccessible(cert.pemPath, false, (err) => {
            next(null, !!err);
          });
        },
        key: (next) => {
          pm.runtime.pathAccessible(cert.keyPath, false, (err) => {
            next(null, !!err);
          });
        },
        pfx: (next) => {
          pm.runtime.pathAccessible(cert.pfxPath, false, (err) => {
            next(null, !!err);
          });
        }
      }, cb);
    }, (err, result) => {
      this.setState({ warningList: err ? [] : result });
    });
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.computeWarningList(nextProps);
  }

  handleDelete (host) {
    this.props.onDelete && this.props.onDelete(host);
  }

  render () {
    const { t } = this.props,
      WorkingWithCertificatesLink = ({ children }) => (
        <Link
          className='learn-more-button'
          to={CERTIFICATES_DOC}
          target='_blank'
        >
          <Text type='link-default' isExternal>
            {children}
          </Text>
        </Link>
      );

    return (
      <div className='certificate-list-wrapper'>
        <Flex gap='spacing-xxl' className='certificate-list-wrapper__heading' justifyContent='space-between'>
          <Flex direction='column' shrink={1} data-testid='certificate-list-wrapper-desc'>
            <Text>{t('settings:certificates.client_certificates.heading')}</Text>
            <Text
              color='content-color-secondary'
              typographyStyle={{
                fontSize: 'text-size-s',
                lineHeight: 'line-height-s'
              }}
            >
              <Trans
                t={t}
                i18nKey='settings:certificates.client_certificates.add_and_manage_ssl_certificates'
                components={[
                  <WorkingWithCertificatesLink>working with certificates</WorkingWithCertificatesLink>
                ]}
              />
            </Text>
          </Flex>
          <Flex direction='column' shrink={0}>
            <Button
              data-testid='add-client-certificate-button'
              type='outline'
              onClick={this.props.onAddToggle}
              className='certificate-list-wrapper__heading__add'
              text={t('settings:certificates.client_certificates.add_certificate_label')}
            />
          </Flex>
        </Flex>

        <div className='certificate-list'>
          {
            _.map(this.props.certificates, (certificate, index) => (
              <Flex key={certificate.host} padding='spacing-zero spacing-zero spacing-zero spacing-xl'>
                <StyledCertificateListItemContainer grow={1} alignItems='center' padding='spacing-m spacing-zero' border={{ borderTop: 'border-width-default' }}>
                  <StyledCertificateListItemContent direction='column' grow={1}>
                    <Flex padding='spacing-xs spacing-zero' gap='spacing-m'>
                      <Flex width='20%' justifyContent='flex-end'>
                        <Text color='content-color-secondary'>{t('settings:certificates.client_certificates.host')}</Text>
                      </Flex>
                      <Flex width='80%' grow={1} shrink={1} minWidth='0px'>
                        <Text isTruncated>{certificate.host}</Text>
                      </Flex>
                    </Flex>
                    {
                        !_.isEmpty(certificate.pemPath) && (
                          <Flex padding='spacing-xs spacing-zero' gap='spacing-m'>
                            <Flex width='20%' justifyContent='flex-end'>
                              <Text color='content-color-secondary'>{t('settings:certificates.client_certificates.crt_file.label')}</Text>
                            </Flex>
                            <Flex width='80%' grow={0} shrink={1} minWidth='0px' gap='spacing-xs' alignItems='center'>
                              {
                                _.get(this.state.warningList, [index, 'crt'], false) &&
                                  <Tooltip content={t('settings:certificates.client_certificates.crt_file.error_message')}><IconStateWarningStrokeSmall color='content-color-warning' /></Tooltip>
                              }
                              <Text isTruncated>{certificate.pemPath}</Text>
                            </Flex>
                          </Flex>
                        )
                    }
                    {
                        !_.isEmpty(certificate.keyPath) && (
                          <Flex padding='spacing-xs spacing-zero' gap='spacing-m'>
                            <Flex width='20%' justifyContent='flex-end'>
                              <Text color='content-color-secondary'>{t('settings:certificates.client_certificates.key_file.label')}</Text>
                            </Flex>
                            <Flex width='80%' grow={0} shrink={1} minWidth='0px' gap='spacing-xs' alignItems='center'>
                              {
                                _.get(this.state.warningList, [index, 'key'], false) &&
                                  <Tooltip content={t('settings:certificates.client_certificates.key_file.error_message')}><IconStateWarningStrokeSmall color='content-color-warning' /></Tooltip>
                              }
                              <Text isTruncated>{certificate.keyPath}</Text>
                            </Flex>
                          </Flex>
                        )
                    }
                    {
                        !_.isEmpty(certificate.pfxPath) && (
                          <Flex padding='spacing-xs spacing-zero' gap='spacing-m'>
                            <Flex width='20%' justifyContent='flex-end'>
                              <Text color='content-color-secondary'>{t('settings:certificates.client_certificates.pfx_file.label')}</Text>
                            </Flex>
                            <Flex width='80%' grow={0} shrink={1} minWidth='0px' gap='spacing-xs' alignItems='center'>
                              {
                                _.get(this.state.warningList, [index, 'pfx'], false) &&
                                  <Tooltip content={t('settings:certificates.client_certificates.pfx_file.error_message')}><IconStateWarningStrokeSmall color='content-color-warning' /></Tooltip>
                              }
                              <Text isTruncated>{certificate.pfxPath}</Text>
                            </Flex>
                          </Flex>
                        )
                    }
                    {
                        !_.isEmpty(certificate.passphrase) && (
                          <Flex gap='spacing-m' alignItems='center'>
                            <Flex width='20%' justifyContent='flex-end'>
                              <Text color='content-color-secondary'>{t('settings:certificates.client_certificates.passphrase')}</Text>
                            </Flex>
                            <Flex width='auto'>
                              <PasswordInput
                                className='certificate-list-item__value-password'
                                value={certificate.passphrase}
                                readOnly
                              />
                            </Flex>
                          </Flex>
                        )
                    }
                  </StyledCertificateListItemContent>
                  <Button
                    type='tertiary'
                    onClick={this.handleDelete.bind(this, certificate.host)}
                    tooltip={t('settings:certificates.client_certificates.remove_certificate_tooltip')}
                    icon={<IconActionDeleteStroke />}
                  />
                </StyledCertificateListItemContainer>
              </Flex>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withTranslation('settings')(CertificateList);
