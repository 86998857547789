var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import async from 'async';
const { HTTPGatewayService } = require('@postman-app-monolith/renderer/onboarding/src/features/Homepage/pages/IntegrationsPage/services/HTTPGatewayService');
import CurrentUserDetailsService from '@postman-app-monolith/renderer/js/services/CurrentUserDetailsService';
const HTTP_GATEWAY_SERVICE_ID = 'integrations', AUTHENTICATION_INSTANCE_BASE_URL = '/authentication-instances', AUTHENTICATION_INSTANCE_ENDPOINTS = {
    LIST: '/list'
}, 
// List of authentication classes
NEW_RELIC_USER = 'new_relic_user', 
// List of Git specific authentication classes
AZURE_V3 = 'azurev3', GITLAB = 'gitlab', BITBUCKET_V3 = 'bitbucketv3', GITHUB = 'github', 
// Allowed classes for fetching authentication instances to be shown in the settings UI
ALLOWED_AUTHENTICATION_CLASSES_FOR_SETTINGS_PAGE = [
    NEW_RELIC_USER,
    AZURE_V3,
    GITHUB,
    BITBUCKET_V3,
    GITLAB
], 
// Sensitive data keys for authentication classes shown in the settings UI
AUTHENTICATION_CLASSES_SENSITIVE_DATA_KEY_MAP = {
    [NEW_RELIC_USER]: 'user_key'
};
const EMPTY_AUTHENTICATION_INSTANCES_RESPONSE = {
    body: {
        data: [],
        meta: {}
    },
    status: 200
};
const NO_OF_INSTANCES_PER_PAGE = 10;
/**
 * Authentication instances are supported only for teams.
 * Personal, non-team users cannot use them.
 * @returns True if authentication instances are enabled. Otherwise false.
 */
const canLoadAuthenticationInstances = () => {
    /**
     * Using CurrentUserDetailsService instead of CurrentUserStore as CurrentUserDetailsService
     * is initialized as soon as app is booted, hence, the teamId will be available before
     * executing this code.
     */
    const teamId = CurrentUserDetailsService.teamId;
    return !!(teamId && teamId !== '0');
};
export default {
    /**
     * Updates an authentication instance
     *
     * @param authenticationInstanceId - Identifier for the authentication instance to be updated
     * @param payload - Data to be updated
     */
    updateAuthenticationInstance(authenticationInstanceId, payload) {
        const path = `${AUTHENTICATION_INSTANCE_BASE_URL}/${authenticationInstanceId}`;
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'put',
                path,
                body: payload
            }
        });
    },
    /**
     * Deletes an authentication instance
     *
     * @param authenticationInstanceId - Identifier for the authentication instance to be deleted
     */
    deleteAuthenticationInstance(authenticationInstanceId) {
        const path = `${AUTHENTICATION_INSTANCE_BASE_URL}/${authenticationInstanceId}`;
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'delete',
                path
            }
        });
    },
    /**
     * Lists authentication instances, supporting advanced filters and pagination
     *
     * @param filter - object containing the filters that need to be made
     * */
    listAuthenticationInstances(filter, perPage) {
        // For situations where loading of authentication instances is not supported, we return an empty response.
        if (!canLoadAuthenticationInstances()) {
            return Promise.resolve(EMPTY_AUTHENTICATION_INSTANCES_RESPONSE);
        }
        const path = AUTHENTICATION_INSTANCE_BASE_URL + AUTHENTICATION_INSTANCE_ENDPOINTS.LIST, pageSize = perPage || NO_OF_INSTANCES_PER_PAGE;
        let pageNumber = 1, error = {}, authenticationInstances = [], isLastPage = false, currentResponse;
        return new Promise((resolve, reject) => {
            async.doUntil((next) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const response = yield HTTPGatewayService.request({
                        data: {
                            service: HTTP_GATEWAY_SERVICE_ID,
                            method: 'post',
                            path,
                            body: { filter, pageNumber, pageSize }
                        }
                    }), authenticationInstanceList = _.get(response, ['body', 'data'], []);
                    authenticationInstances = _.concat([], authenticationInstances, authenticationInstanceList);
                    isLastPage = _.get(response, ['body', 'meta', 'isLastPage'], true);
                    currentResponse = response;
                    pageNumber++;
                    return next();
                }
                catch (e) {
                    error = e;
                    return next(e);
                }
            }), 
            // Breaker function which checks if we need to perform the above mentioned function more times or not
            () => {
                // condition to check if the current page is the last, then no need to make further api calls
                return isLastPage === true;
            }, (err) => {
                if (err) {
                    return reject(error);
                }
                currentResponse.body.data = authenticationInstances;
                return resolve(currentResponse);
            });
        });
    },
    /**
     * Fetches authentication instances scoped to a given API
     *
     * @param {String} apiId - ID of the API
     * */
    listAuthenticationInstancesOnApi(apiId) {
        return this.listAuthenticationInstances({ scope: [{ type: 'api', id: apiId }] });
    },
    /**
     * Creates authentication instance for given auth class
     *
     * @param authenticationClass - authentication class like `new_relic_user`, `github`, etc.
     * @param scopes - contains scopes to be added
     * @param data - auth data that contains API key, etc.
     * @throws {AuthenticationInstanceForbiddenError} when the user doesn't have permission to create auth for given scope
     */
    createAuthenticationInstance(authenticationClass, scopes, data) {
        var _a, _b;
        const path = AUTHENTICATION_INSTANCE_BASE_URL, 
        // TODO: Remove this from the payload once multi scope auth is enabled. Tracker - [IS-4379]
        scopeType = (_a = _.head(scopes)) === null || _a === void 0 ? void 0 : _a.type, scopeId = (_b = _.head(scopes)) === null || _b === void 0 ? void 0 : _b.id;
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'post',
                path,
                body: {
                    authenticationClass,
                    scopes,
                    data,
                    scopeType,
                    scopeId
                }
            }
        });
    },
    /**
     * Fetches the details of an authentication instance
     *
     * @param authenticationInstanceId - The authentication instance's id
     */
    getAuthenticationInstance(authenticationInstanceId) {
        const path = `${AUTHENTICATION_INSTANCE_BASE_URL}/${authenticationInstanceId}`;
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'get',
                path
            }
        });
    },
    /**
     * Fetches authentication instances to be shown in settings UI
     *
     * @note Add an entry to the ALLOWED_AUTHENTICATION_CLASSES_FOR_SETTINGS_PAGE
     * in order to show them in connected accounts section in the settings page/modal.
     */
    listAuthenticationInstancesForSettingsPage() {
        const teamId = CurrentUserDetailsService.teamId, userId = CurrentUserDetailsService.userId, filter = {
            scope: [
                {
                    type: 'team',
                    id: teamId
                },
                {
                    type: 'user',
                    id: userId
                }
            ],
            authenticationClass: ALLOWED_AUTHENTICATION_CLASSES_FOR_SETTINGS_PAGE
        };
        return this.listAuthenticationInstances(filter);
    },
    AUTHENTICATION_CLASSES_SENSITIVE_DATA_KEY_MAP,
    ALLOWED_AUTHENTICATION_CLASSES_FOR_SETTINGS_PAGE
};
