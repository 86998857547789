import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Input } from '../base/Inputs';
import { Button } from '../base/Buttons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@postman/aether-icons';
import { Flex, Text } from '@postman/aether';
import { DropdownMenu, MenuItem, MenuItemHeader } from './Dropdowns';
import onClickOutside from '@postman/react-click-outside';
import styled from 'styled-components';

const StyledFlex = styled(Flex)`
  background-color: var(--background-color-secondary);
`;

/**
 * @private
 * A component that supports showing a list. Any filtering logic or rendering action items has
 * to be handled by the consumer
 * Usage:
 * <InputSelectV2
 *  placeholder={'Filter Colors'}
 *  getFilteredList={(query, options) => !options.firstRender ? _.filter(items, someFilterCriteria) : items}
 *  selectedItem={items[0]}
 *  getInputValue={(item) => item.name}
 *  optionRenderer={
 *   ({ item, search, options }) => item // Could be a DOM element
 *  }
 *  onSelect={(item) => {}} // Called when user selects an item (by clicking or pressing enter)
 *  onChange={(value) => {}} // Called on every key stroke, value is the current query string
 *  />
 * set property addDivider true for items below which you need a divider/separator
 */

@onClickOutside
export class InputSelectV2 extends Component {

  constructor (props) {
    super(props);
    this.state = {
      focusedItem: null,
      isOpen: false,
      inputValue: props.getInputValue(props.selectedItem),
      selectedItem: props.selectedItem
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleToggleList = this.handleToggleList.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  renderOptions () {
    const { optionRenderer, getFilteredList } = this.props,
          { inputValue, selectedItem, focusedItem } = this.state,
          searchQuery = inputValue;

    this._visibleItems = getFilteredList(searchQuery, { firstRender: this.state.disableFiltering });

    // convert the item objects to MenuItems
    return _.chain(this._visibleItems).flatMap((item) => {
      let isFocussed = item.id === (focusedItem && focusedItem.id),
          isSelected = item.id === (selectedItem && selectedItem.id),
          option = optionRenderer(
            item,
            searchQuery,
            { firstRender: this.state.disableFiltering, isFocussed, isSelected }
          ),
          menuList = [];

      if (!option) {
        return menuList;
      }
      if (item.addTopDivider) {
        menuList.push(item.dividerElement);
      }


      if (item.type === 'custom-type') {
        menuList.push(option);
      }
      else if (item.type === 'parent') {
        menuList.push(
          <MenuItemHeader
            key={item.id}
            refKey={item.id}
          >
            {option}
          </MenuItemHeader>
        );
      }
      else {
        menuList.push(
          <MenuItem
            className={classnames({
              'item': true,
              'is-focused': isFocussed,
              'is-selected': isSelected
            })}
            key={item.id}
            disabled={item.disabled}
            refKey={item.id}
            onMouseEnter={() => {
              this.handleMouseEnter(item);
            }}
            onMouseLeave={() => {
              this.handleMouseLeave();
            }}
            infoTooltip={item.name}
            enableTruncation
          >
            {option}
          </MenuItem>
        );
      }


      if (item.addDivider) {
        menuList.push(<div className='divider divider--space' />);
      }
      return menuList;
    }).compact().value();
  }

  handleMouseEnter (item) {
    this.focusOption(item);
  }

  handleMouseLeave () {
    this.focusOption(null);
  }

  handleCancel () {
   // the x icon has mouseDown event which is called before 'blur' event
   // so we need to focus only after 'blur' event was handled
   // @TODO fix this by changing the mouseDown handler to click handler

   // handles the case when the selected collection is cleared
   this.props.handleCancel && this.props.handleCancel();

   setImmediate(() => {
     this.focus();
   });
  }

  focus () {
    if (!this.refs.input) {
      return;
    }
    this.refs.input.focus();
  }

  blur () {
    if (!this.refs.input) {
      return;
    }
    this.refs.input.blur();

    if (this.props.resetSearchFilterOnBlur || (this.props.resetEmptyFilterOnBlur && !this.state.inputValue)) {
      this.setState({ inputValue: this.props.getInputValue(this.props.selectedItem) });
    }

    // blur will cause the body to be in focus, this will bring back the focus to the active tab
    pm.mediator.trigger('focusBuilder');

    this.props.onBlur && this.props.onBlur();
    this.setState({
      focusedItem: null
    });
  }

  handleOpen () {
    this.setState({
      isOpen: true,
      disableFiltering: true // at this point we want to show the complete list even though the query is present
    }, this.focus);
  }

  handleToggleList () {
    if (this.state.isOpen) {
      this.handleClose();
    } else {
      this.handleOpen();
    }
  }

  handleClickOutside (e) {
    const menu = this.dropdownMenuRef && findDOMNode(this.dropdownMenuRef);

    // bail if clicked inside the dropdown menu
    if (menu && menu.contains(e.target)) {
      return;
    }
    this.handleClose();
  }

  handleClose () {
    // bail if already closed
    if (!this.state.isOpen) {
      return;
    }

    this.setState({
      isOpen: false
    }, this.props.onClose && this.props.onClose());
  }

  handleSelect (refKey) {
    let item = _.find(this._visibleItems, { id: refKey });
    item && !item.notSelectable && this.selectFocusedOption(item);
  }

  handleKeyDown (e) {
    let focusedItem = this.state.focusedItem;

    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        if (!this.state.isOpen) {
          this.handleOpen();
        }
        else {
          this.focusAdjacentOption('next');
        }
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        if (!this.state.isOpen) {
          this.handleOpen();
        }
        else {
          this.focusAdjacentOption('previous');
        }
        break;
      }
      case 'Enter': {
        e.preventDefault();

        // If user pressed Enter after focussing on an item (an option from list or 'create new option')
        if (focusedItem) {
          this.handleSelect(focusedItem.id);
        }

        if (!focusedItem?.shouldNotCloseDropdownOnSelect) {
          this.handleClose();
        }

        break;
      }
      case 'Escape': {
        // Important: as it empties out the input field
        e.preventDefault();
        break;
      }
      case 'Tab' : {
        this.handleClose();

        break;
      }
      case 'Meta': {
        e.preventDefault();
        break;
      }
      case 'Shift' : {
        e.preventDefault();
        break;
      }
      case 'Control': {
        e.preventDefault();
        break;
      }
      case 'Alt': {
        e.preventDefault();
        break;
      }
      case 'ArrowRight': {
        break;
      }
      case 'ArrowLeft': {
        break;
      }
      default: {
        // Immediately after toggling the list we don't filter the list
        // But once user presses any key-stroke (other than the above special keys), we will do the filtering
        if (this.state.disableFiltering) {
          this.setState({
            disableFiltering: false
          });
        }
        break;
      }
    }
  }

  handleFocus () {
    if (this.props.openOnFocus) {
      this.setState({
        isOpen: true,
        disableFiltering: true
      });
    }
    this.props.onFocus && this.props.onFocus();
  }

  getNextVisibleItem (list, item) {
    // if none of item are focusable then bail
    if (_.every(list, { disableFocus: true })) {
      return null;
    }

    let index = _.findIndex(list, { id: item && item.id }),
        nextItem;

    // If item not found or we reached the last item, reset to first item
    if (index === -1 || index === list.length - 1) {
      nextItem = list[0];
      return nextItem.disableFocus ? this.getNextVisibleItem(list, nextItem) : nextItem;
    }

    nextItem = list[index + 1];
    return nextItem.disableFocus ? this.getNextVisibleItem(list, nextItem) : nextItem;
  }

  getPreviousVisibleItem (list, item) {
    // if none of item are focusable then bail
    if (_.every(list, { disableFocus: true })) {
      return null;
    }

    let index = _.findIndex(list, { id: item && item.id }),
        prevItem;

    // If item not found, or if we reached the first item, reset to last item
    if (index === -1 || index === 0) {
      prevItem = _.last(list);
      return prevItem.disableFocus ? this.getPreviousVisibleItem(list, prevItem) : prevItem;
    }

    prevItem = list[index - 1];
    return prevItem.disableFocus ? this.getPreviousVisibleItem(list, prevItem) : prevItem;
  }

  focusOption (item) {
    this.setState({ focusedItem: item });
  }

  focusAdjacentOption (dir) {
    let focusedItem = this.state.focusedItem,
        visibleItems = this._visibleItems,
        focusedElement;

    if (dir === 'next') {
      focusedItem = this.getNextVisibleItem(visibleItems, focusedItem);
    }
    else if (dir === 'previous') {
      focusedItem = this.getPreviousVisibleItem(visibleItems, focusedItem);
    }

    focusedElement = document.querySelector(`.dropdown-menu-item--${focusedItem.id}`);
    focusedElement && focusedElement.scrollIntoView({ block: 'nearest' });

    this.setState({ focusedItem: focusedItem });
  }

  selectFocusedOption (item) {
    const focusedItem = item ? item : this.state.focusedItem;

    this.props.onSelect && this.props.onSelect(focusedItem);

    if (!focusedItem?.shouldNotCloseDropdownOnSelect) {
      this.handleClose();
    }
  }

  handleChange (inputValue) {
    this.setState({
      inputValue: inputValue,
      isOpen: true,
      focusedItem: null
    }, () => {
      this.props.onChange && this.props.onChange(inputValue);
    });
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.selectedItem !== nextProps.selectedItem ||
        nextProps.getInputValue(nextProps.selectedItem) !== this.state.inputValue) {
      this.setState({
        selectedItem: nextProps.selectedItem,
        inputValue: nextProps.getInputValue(nextProps.selectedItem)
      });
    }
  }

  getWrapperClasses () {
    return classnames({
      [this.props.className]: true,
      'input-select-v2-wrapper': true,
      'is-open': this.state.isOpen,
      'is-disabled': this.props.disabled
    });
  }

  renderMenu () {
    const options = this.renderOptions();
    if (_.isEmpty(options)) {
      return null;
    }

    let parentClassName = `input-select-v2-dropdown-menu ${this.props.menuClassName} ${this.props.showBanner ? 'input-select-v2-dropdown-menu--banner' : ''}`;

    return (
      <DropdownMenu
        disableCloseOnInputElementScroll={this.props.disableCloseOnInputElementScroll}
        disableCloseOnPageScroll={this.props.disableCloseOnPageScroll}
        fluid
        parentClassName={parentClassName}
        targetRef={this.refs.wrapper}
        onSelect={this.handleSelect}
        onClose={this.handleClose}
        ref={(ref) => { this.dropdownMenuRef = ref; }}
      >
        {options}

        {
         this.props.showBanner && (
          <StyledFlex padding={{
            paddingLeft: 'spacing-xxl',
            paddingRight: 'spacing-m',
            paddingTop: 'spacing-m',
            paddingBottom: 'spacing-m'
            }}
          ><Text>{this.props.bannerText}</Text></StyledFlex>
         )
        }
      </DropdownMenu>
    );
  }

  render () {
    const {
      placeholder,
      hideSearchGlass,
      hideCancelOnBlur,
      showToggleButton,
      selectAllOnFocus
    } = this.props;

    return (
      <div
        ref='wrapper'
        className={this.getWrapperClasses()}
        style={this.props.style}
      >
        <Input
          hideCancelOnBlur={hideCancelOnBlur}
          hideSearchGlass={hideSearchGlass}
          inputStyle='search'
          className={this.props.inputClassName}
          placeholder={placeholder || 'Type to filter'}
          query={this.state.inputValue}
          ref='input'
          selectAllOnFocus={selectAllOnFocus}
          onCancel={this.handleCancel}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          isLoading={this.props.isLoading}
          onKeyDown={this.handleKeyDown}
          disabled={this.props.disabled}
          isClearable={this.props.isClearable}
          dataTestId={this.props.dataTestId}
        />
        {
          showToggleButton && (
            <Button
              className='dropdown-button'
              ref='button'
              onClick={this.handleToggleList}
              disabled={this.props.disabled}
              focusable
            >
              <Icon name='icon-direction-down' className='dropdown-caret' />
            </Button>
          )
        }
        {
          this.state.isOpen &&
            this.renderMenu()
        }
      </div>
    );
  }
}

InputSelectV2.defaultProps = {
  closeOnBlur: true,
  closeOnSelect: true,
  hideCancelOnBlur: true, // do not show the cross icon (x) when input is blurred
  selectAllOnFocus: true,
  showToggleButton: true,
  hideSearchGlass: true,
  openOnFocus: true,
  resetEmptyFilterOnBlur: true,
  resetSearchFilterOnBlur: false, // resets the search term to active/default value when input is blurred
  menuClassName: '',
  inputClassName: '',
  disableCloseOnInputElementScroll: true,
  isClearable: true
};

InputSelectV2.propTypes = {
  closeOnBlur: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  hideCancelOnBlur: PropTypes.bool,
  isClearable: PropTypes.bool,
  selectAllOnFocus: PropTypes.bool,
  showToggleButton: PropTypes.bool,
  hideSearchGlass: PropTypes.bool,
  resetEmptyFilterOnBlur: PropTypes.bool,
  resetSearchFilterOnBlur: PropTypes.bool,
  menuClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  openOnFocus: PropTypes.bool,
  optionRenderer: PropTypes.func.isRequired,
  getInputValue: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
  getFilteredList: PropTypes.func.isRequired,
  disableCloseOnInputElementScroll: PropTypes.bool,
  handleCancel: PropTypes.func,
  showBanner: PropTypes.bool,
  bannerText: PropTypes.string
};
