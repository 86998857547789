import HeaderPresetController from '@@runtime-repl/header-preset/datastores/controllers/HeaderPresetController';
import { sanitizeVariableValue } from './util';

const TAB = '\t',
      FILE_NAME_EXT = '.postman_headerpreset.json',
      allowedHeaderPresetFields = ['id', 'name', 'headers'];

/**
 * Gets the headerPreset from the db
 * @param {Object} criteria
 */
function getFromDb (criteria) {
  return HeaderPresetController.get(criteria);
}

/**
 * Sanitizes the headerPreset before exporting
 * @param {Object} headerPreset
 */
function sanitize (headerPreset) {
  let sanitizedHeaderPreset = _.pick(headerPreset, allowedHeaderPresetFields);
  sanitizedHeaderPreset.headers = _.map(sanitizedHeaderPreset.headers, sanitizeVariableValue);

  return sanitizedHeaderPreset;
}

/**
 * Returns the entity name
 * @param {Object} headerPreset
 */
function getEntityName (headerPreset) {
  return headerPreset.name;
}

/**
 * Returns the fileName that can be used if exporting to a file
 * @param {Object} globals
 */
function getFileName (globals) {
  return this.getEntityName(globals) + FILE_NAME_EXT;
}

/**
 * Serializes the headerPreset
 * @param {Object} headerPreset
 * @returns {String} Stringified headerPreset
 */
function serialize (headerPreset) {
  return JSON.stringify(headerPreset, null, TAB);
}

export default {
  getFromDb,
  sanitize,
  getEntityName,
  getFileName,
  serialize
};
