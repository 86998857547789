import environmentModel from '../../modules/export/environment';
import { STATE } from '../../models/services/filesystem';
import util from '../../utils/util';
import exportSingle from '../../modules/export/scratchpad/export-single-scratchpad';
import fs from 'fs';
import { bootFirmware } from '@postman-app-monolith/renderer/appsdk/scratchpad/services/scratchpadMigrationBootFirmware';

const COLLECTION = 'collection',
  TAB = '\t',
  defaultName = 'scratchpad-archive.zip';

/**
 * Exports a model (collection, environment or globals)
 * @param {Object} options
 * @param {String} options.exportLevel One of 'all' or 'user'
 * @param {String} [options.filePath] Path to save the data dump. Optional. If not provided a native prompt will be shown.
 * @returns {Promise<Object>} which resolves in an object having version, collections, environments, globals
 */
export default function (options = {}) {
  /**
   * Creates a zip file at the specified path
   * @param {String} path - The path where the zip file will be created
   * @returns {Promise<String>} - A promise that resolves to the state of the operation
   */
  function createZipFile (path) {
    return new Promise((resolve, reject) => {
      try {
        const archiver = require('archiver');
        const archive = archiver('zip', { zlib: { level: 9 } });

        // Create a writable stream to specify the output file
        const output = fs.createWriteStream(path);

        // Pipe the output stream to the archive
        archive.pipe(output);

        pm.logger.info('ExportAllScratchpadZip~createZipFile: creating archive');
        resolve({ archive, output });
      } catch (err) {
        pm.logger.error('ExportAllScratchpadZip~createZipFile: error while setting file path', err);
        reject(err);
      }
    });
  }


  const dumpCollections = function (archive) {
    return Promise.resolve()

      // Get all the collections from db
      .then(() => {
        return pm.migrator.models.collection.find();
      })

      // Populate individual collections
      .then(async (collections) => {

        for (const collection of collections) {

          try {
            const singleCollection = await exportSingle(COLLECTION, { id: collection.id });

            if (singleCollection.json) {
              const fileOptions = {
              name: `collections/${singleCollection.json.name}.json`
            };

            // Append the transformed collection to the archive immediately
            archive.append(JSON.stringify(singleCollection.json, null, TAB), fileOptions);
            pm.logger.info(`ExportAllScratchpadZip~dumpCollections: saving collection ${collection?.name} to a new file`);
            }

          }

          catch (err) {
            pm.logger.error(`ExportAllScratchpadZip~dumpCollections: error while saving collection ${collection?.name} to a new file`, err);
            throw new Error(err);
          }
        }
        pm.logger.info('ExportAllScratchpadZip~dumpCollections: creating archive');
      }).catch((err) => {
        pm.logger.error('ExportAllScratchpadZip~dumpCollections: error while saving collections', err);
        throw new Error(err);
      });
  };

  const dumpEnvironments = function (archive) {
    return Promise.resolve()

   // Get all the environments from db
    .then(() => {
      return pm.migrator.models.environment.find({});
    })

    // Sanitize the environments and append each one to the archive
    .then(async (environments) => {
      for (const environment of environments) {

        try {
          const sanitizedEnv = environmentModel.sanitize(environment);
          const transformedEnv = await environmentModel.transform(sanitizedEnv, { appVersion: pm.app.get('info').version });

          const fileOptions = {
            name: `environments/${transformedEnv.name}.json`
          };

          // Append the transformed environment to the archive immediately
          archive.append(JSON.stringify(transformedEnv, null, TAB), fileOptions);
          pm.logger.info(`ExportAllScratchpadZip~dumpEnvironments: saving environment ${transformedEnv.name} to a new file`);
        } catch (err) {
          pm.logger.error(`ExportAllScratchpadZip~dumpEnvironments: error while saving environment ${environment?.name} to a new file`, err);
          throw new Error(err);

          // Handle any errors if needed
        }
      }
    }).catch((err) => {
      pm.logger.error('ExportAllScratchpadZip~dumpEnvironments: error while saving environments to a new file', err);
      throw new Error(err);
    })
    ;
};

  const dumpGlobals = function (archive) {
    return Promise.resolve()

      // Get all the globals from db
      .then(() => {
        return pm.migrator.models.globals.find({});
      })

      // Convert globals to environments
      .then((globals) => {
        return _.map(globals, (globalsRecord) => {
          return {
            id: util.guid(),
            name: 'My Workspace Globals',
            values: globalsRecord.values
          };
        });
      })

      // Sanitize the globals but using environmentModel
      // since the globals were converted to environments
      .then(async (globals) => {

        for (const globalRecord of globals) {
          try {
            const sanitizedEnv = environmentModel.sanitize(globalRecord);
            if (globalRecord.values.length === 0) {
              return;
            }
            const transformedEnv = await environmentModel.transform(sanitizedEnv, { appVersion: pm.app.get('info').version });

            transformedEnv._postman_variable_scope = 'globals';

            const fileOptions = {
              name: `globals/${transformedEnv.name}.json`
            };

            // Append the transformed environment to the archive immediately
            archive.append(JSON.stringify(transformedEnv, null, TAB), fileOptions);
            pm.logger.info(`ExportAllScratchpadZip~dumpGlobals: saving globals ${transformedEnv.name} to a new file`);
          } catch (err) {
            // Handle any errors if needed
            pm.logger.error('ExportAllScratchpadZip~dumpGlobals: error while saving globals to a new file', err);
            throw new Error(err);
          }
      }

      }).catch((err) => {
        pm.logger.error('ExportAllScratchpadZip~dumpGlobals: error while saving globals', err);
        throw new Error(err);
      });
  };


  return Promise.resolve()
    .then(async () => {

      if (!window.pm.migrator) {
        await bootFirmware();
      }

      return new Promise((resolve, reject) => {
        try {
            const result = pm.appWindow.sendMessage('getSaveTarget', defaultName); // Allow the user to use a select a name and path for the file

            result.then((path) => {
            if (!path) {
              pm.logger.error('ExportAllScratchpadZip~createZipFile: no save path, error aborted');
              resolve(STATE.ABORTED);
              return; // Save aborted by the user
            }

            createZipFile(path)
              .then(({ archive, output }) => {

                return Promise.allSettled([dumpCollections(archive), dumpEnvironments(archive), dumpGlobals(archive)])
                  .then(() => {
                    // Finalize the archive
                    archive.finalize();

                    output.on('close', function () {
                      resolve(STATE.SUCCESS);
                    });

                    output.on('error', function (err) {
                      pm.logger.error('ExportAllScratchpadZip~createZipFile: Error while saving the output', err);
                      reject(err);
                    });
                  })
                  .catch((error) => {
                    pm.logger.error('ExportAllScratchpadZip~createZipFile: Error while finalizing the data-dump', error);
                    reject(error);
                  });
              }).catch((error) => {
                  pm.logger.error('ExportAllScratchpadZip~createZipFile: Error in resolving promises', error);
                  reject(error);
              });
          });
        }
        catch (err) {
          pm.logger.error('ExportAllScratchpadZip~createZipFile: Error while saving the data-dump', err);
          reject(err);
        }
      });

    });
}
