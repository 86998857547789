import { launchDarkly } from '@postman-app-monolith/renderer/onboarding/src/common/LaunchDarkly';
import { computed } from 'mobx';

class ShowBetaTagInLanguageSettingsFlag {
  @computed
  get isEnabled () {
    return launchDarkly.getFlag('i-18-n-4-show-beta-label', false);
  }
}

export default new ShowBetaTagInLanguageSettingsFlag();
