import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Button, Flex, Text, Heading, ToggleSwitch, ButtonGroup, toast } from '@postman/aether';
import { InputSelectV2 } from '../base/InputSelectV2';
import BrowserActiveTrackService from '../../services/BrowserActiveTrackService';
import WebSocketProxy from '../../services/WebSocketProxyService';
import { DISABLE_WEBSOCKET_DEV_OPTIONS, SHOW_POSTMAN_GATEWAY_REQUEST_DETAILS_AS_QUERY_DEV_OPTIONS } from '../../constants/DevOptions';
import { openExternalLink } from '../../external-navigation/ExternalNavigationService';
import HttpService from '../../utils/HttpService';
import semver from 'semver';
import { IconDirectionForward } from '@postman/aether-icons';

const PLATFORM = {
  WINDOWS: {
    ID: 'WIN64',
    ARCH: {
      WINDOWS_64: 'windows_64'
    }
  },
  OSX: {
    ID: 'OSX',
    ARCH: {
      OSX_64: 'osx_64',
      OSX_ARM64: 'osx_arm64'
    }
  },
  LINUX: {
    ID: 'LINUX',
    ARCH: {
      LINUX_64: 'linux_64',
      LINUX_ARM64: 'linux_arm64'
    }
  }
};

class SettingsDevOptions extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeTrack: BrowserActiveTrackService.getCurrentTrack(),
      tracksList: BrowserActiveTrackService.getActiveTracks(),
      webSocketDisabled: false,
      isAppDownloadAvailable: false,
      showPostmanGatewayRequestDetailsAsQuery: false
    };

    this.handleTrackUpdate = this.handleTrackUpdate.bind(this);
    this.handleTrackSubmit = this.handleTrackSubmit.bind(this);
    this.handleWebSocketProxyUpdate = this.handleWebSocketProxyUpdate.bind(this);
    this.handleWebSocketProxySubmit = this.handleWebSocketProxySubmit.bind(this);
    this.handleDesktopAppDownload = this.handleDesktopAppDownload.bind(this);
    this.handleWebsocketDisableFieldChange = this.handleWebsocketDisableFieldChange.bind(this);
    this.handleShowPostmanGatewayRequestDetailsAsQueryFieldChange = this.handleShowPostmanGatewayRequestDetailsAsQueryFieldChange.bind(this);
  }

  getAppDownloadUrl (platform) {
    if (typeof __WP_CUSTOM_APP_NAME__ === 'undefined' || typeof __WP_CUSTOM_APP_VERSION__ === 'undefined') {
      return;
    }

    let majorVersion = semver.major(__WP_CUSTOM_APP_VERSION__),
        packageName,
        platformArch;

    if (platform === PLATFORM.WINDOWS.ID || platform === PLATFORM.WINDOWS.ARCH.WINDOWS_64) {
      packageName = `${__WP_CUSTOM_APP_NAME__}-win64-${__WP_CUSTOM_APP_VERSION__}-Setup.exe`;
      platformArch = PLATFORM.WINDOWS.ARCH.WINDOWS_64;
    }
    else if (platform === PLATFORM.OSX.ID || platform === PLATFORM.OSX.ARCH.OSX_64) {
      packageName = `${__WP_CUSTOM_APP_NAME__}%20v${__WP_CUSTOM_APP_VERSION__}%20for%20macOS%20(x64).zip`;
      platformArch = PLATFORM.OSX.ARCH.OSX_64;
    }
    else if (platform === PLATFORM.OSX.ARCH.OSX_ARM64) {
      packageName = `${__WP_CUSTOM_APP_NAME__}%20v${__WP_CUSTOM_APP_VERSION__}%20for%20macOS%20(arm64).zip`;
      platformArch = PLATFORM.OSX.ARCH.OSX_ARM64;
    }
    else if (platform === PLATFORM.LINUX.ID || platform === PLATFORM.LINUX.ARCH.LINUX_64) {
      packageName = `${__WP_CUSTOM_APP_NAME__.toLowerCase()}-${__WP_CUSTOM_APP_VERSION__}-linux-x64.tar.gz`;
      platformArch = PLATFORM.LINUX.ARCH.LINUX_64;
    }
    else if (platform === PLATFORM.LINUX.ARCH.LINUX_ARM64) {
      packageName = `${__WP_CUSTOM_APP_NAME__.toLowerCase()}-${__WP_CUSTOM_APP_VERSION__}-linux-arm64.tar.gz`;
      platformArch = PLATFORM.LINUX.ARCH.LINUX_ARM64;
    }

    return `${window.postman_custom_app_download_url}/v${majorVersion}/${window.RELEASE_CHANNEL}/${platformArch}/${packageName}`;
  }

  renderAppDownloadButton (platform) {
    switch (platform) {
      case PLATFORM.OSX.ID:
        return (
          <ButtonGroup>
            <Button
              type='outline'
              onClick={this.handleDesktopAppDownload.bind(this, PLATFORM.OSX.ARCH.OSX_64)}
              text={this.props.t('settings:dev_options.download_app_preview.platforms.osx.intel')}
            />
            <Button
              type='outline'
              onClick={this.handleDesktopAppDownload.bind(this, PLATFORM.OSX.ARCH.OSX_ARM64)}
              text={this.props.t('settings:dev_options.download_app_preview.platforms.osx.arm64')}
            />
          </ButtonGroup>
        );
      case PLATFORM.WINDOWS.ID:
        return (
          <Button
            type='outline'
            onClick={this.handleDesktopAppDownload.bind(this, PLATFORM.WINDOWS.ARCH.WINDOWS_64)}
            text={this.props.t('settings:dev_options.download_app_preview.platforms.windows.x64')}
          />
        );
      case PLATFORM.LINUX.ID:
        return (
          <ButtonGroup>
            <Button
              type='outline'
              onClick={this.handleDesktopAppDownload.bind(this, PLATFORM.LINUX.ARCH.LINUX_64)}
              text={this.props.t('settings:dev_options.download_app_preview.platforms.linux.x64')}
            />
            <Button
              type='outline'
              onClick={this.handleDesktopAppDownload.bind(this, PLATFORM.LINUX.ARCH.LINUX_ARM64)}
              text={this.props.t('settings:dev_options.download_app_preview.platforms.linux.arm64')}
            />
          </ButtonGroup>
        );
      default: {
        return false;
      }
    }
  }

  componentDidMount () {
    (async () => {
      const proxies = await WebSocketProxy.getProxies();

      this.setState({
        webSocketProxies: proxies,
        webSocketProxy: WebSocketProxy.getCurrentProxy()
      });
    })();

    if (window.RELEASE_CHANNEL === 'preview') {
      (async () => {
        let downloadUrl = this.getAppDownloadUrl(pm.app.getPlatform()),
          res;

        try {
          if (downloadUrl) {
            res = await HttpService.request(downloadUrl, {
              method: 'HEAD'
            });
          }
        }
        catch (err) {
          // Status code 403 is expected as it is returned when the file doesn't exist in s3 bucket
          if (res && res.status !== 403) {
            pm.logger.error('Unexpected response received from the server', err);
          }
        }

        if (res && res.status === 200) {
          this.setState({
            isAppDownloadAvailable: true
          });
        }
      })();
    }

    this.setState({
      webSocketDisabled: (localStorage.getItem(DISABLE_WEBSOCKET_DEV_OPTIONS) === 'true' ? true : false),
      showPostmanGatewayRequestDetailsAsQuery: (localStorage.getItem(SHOW_POSTMAN_GATEWAY_REQUEST_DETAILS_AS_QUERY_DEV_OPTIONS) === 'true' ? true : false)
    });
  }

  handleTrackUpdate (track) {
    if (!track) {
      return;
    }

    track = (typeof track === 'string') ? track : track.value;

    this.setState({
      activeTrack: track
    });
  }

  handleTrackSubmit () {
    BrowserActiveTrackService.setCurrentTrackAndRefresh(this.state.activeTrack);
  }

  handleWebSocketProxySubmit () {
    if (WebSocketProxy.isValidWebSocketProxy(this.state.webSocketProxy)) {
      WebSocketProxy.switchWebSocketProxy(this.state.webSocketProxy);

      return pm.toasts.success(this.props.t(
        'settings:dev_options.web_socket_proxy.toasts.success',
        { webSocketProxyName: this.state.webSocketProxy.name }
      ));

    }

    pm.logger.error('DevSettings~WebSocketProxy~Invalid Proxy', this.state.webSocketProxy);
    return pm.toasts.error(this.props.t('settings:dev_options.web_socket_proxy.toasts.error'));
  }

  handleWebSocketProxyUpdate (server) {
    if (!WebSocketProxy.isValidWebSocketProxy(server && server.value)) {
      return;
    }

    this.setState({
      webSocketProxy: server.value
    });
  }

  handleDesktopAppDownload (platform) {
    openExternalLink(this.getAppDownloadUrl(platform));
  }

  getMenuObject (menuEntry) {
    return {
      id: menuEntry,
      value: menuEntry
    };
  }

  getWebSocketProxy (server) {
    return {
      id: server.name,
      value: server
    };
  }

  handleWebsocketDisableFieldChange (val) {
    this.setState({
      webSocketDisabled: !this.state.webSocketDisabled
    });

    localStorage.setItem(DISABLE_WEBSOCKET_DEV_OPTIONS, val);

    toast({
      title: this.props.t('settings:dev_options.web_socket.toasts.title'),
      status: 'info',
      description: this.props.t('settings:dev_options.web_socket.toasts.description')
    });
  }

  handleShowPostmanGatewayRequestDetailsAsQueryFieldChange (val) {
    this.setState({
      showPostmanGatewayRequestDetailsAsQuery: !this.state.showPostmanGatewayRequestDetailsAsQuery
    });

    localStorage.setItem(SHOW_POSTMAN_GATEWAY_REQUEST_DETAILS_AS_QUERY_DEV_OPTIONS, val);

    toast({
      title: this.props.t('settings:dev_options.postman_gateway_request_details.toasts.title'),
      status: 'info',
      description: this.props.t('settings:dev_options.postman_gateway_request_details.toasts.description')
    });
  }

  render () {
    const { t } = this.props;
    let activeTrack = this.state.activeTrack || '',
      tracksList = this.state.tracksList || [],
      webSocketProxies = this.state.webSocketProxies || [],
      webSocketProxy = this.state.webSocketProxy || {};

    return (
      <Flex direction='column' gap='spacing-xl' >
        <Flex direction='column' gap='spacing-s'>
          <Heading text={t('settings:dev_options.page_heading')} type='h2' />
          <Text type='para'>{t('settings:dev_options.page_para')}</Text>
        </Flex>
        <Flex direction='column'>
          {
              __SDK_PLATFORM__ === 'browser' &&
            <Flex shrink={1} justifyContent='space-between' gap='spacing-xl' padding='spacing-m spacing-zero spacing-l' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
              <Flex shrink={1} direction='column'>
                <Text>{t('settings:dev_options.active_track.heading')}</Text>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s',
                  }}
                >
                  {t('settings:dev_options.active_track.sub_heading')}
                </Text>
              </Flex>
              <Flex basis='228px' justifyContent='space-between' gap='spacing-xs' padding={{ paddingTop: 'spacing-xs' }}>
                <InputSelectV2
                  className='settings-dev-select-menu'
                  menuClassName='settings-dev-select-dropdown-menu'
                  selectedItem={this.getMenuObject(activeTrack)}
                  placeholder={t('settings:dev_options.active_track.search_placeholder')}
                  getInputValue={(item) => item.value}
                  optionRenderer={(item) => (<div>{item.value}</div>)}
                  getFilteredList={() => tracksList.map((track) => { return this.getMenuObject(track); })}
                  onSelect={this.handleTrackUpdate}
                  onChange={_.debounce(this.handleTrackUpdate, 100)}
                />
                <Button
                  type='outline'
                  icon={<IconDirectionForward color='content-color-primary' />}
                  tooltip={t('settings:dev_options.active_track.button_tooltip')}
                  onClick={this.handleTrackSubmit}
                />
              </Flex>
            </Flex>
          }

          <Flex shrink={1} gap='spacing-xxl' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Flex direction='column' shrink={1}>
              <Text>{t('settings:dev_options.web_socket.heading')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:dev_options.web_socket.sub_heading')}
              </Text>
            </Flex>
            <Flex className='settings-rhs-control-spacing-adjust' shrink={0}>
              <ToggleSwitch
                isChecked={this.state.webSocketDisabled}
                onChange={this.handleWebsocketDisableFieldChange}
              />
            </Flex>
          </Flex>

          {
              __SDK_PLATFORM__ === 'browser' && WebSocketProxy.isWebSocketSelectionAllowed() &&
            <Flex shrink={1} gap='spacing-xl' padding='spacing-l spacing-zero spacing-l' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
              <Flex shrink={1} direction='column'>
                <Text>{t('settings:dev_options.web_socket_proxy.heading')}</Text>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s',
                  }}
                >
                  {t('settings:dev_options.web_socket_proxy.sub_heading')}
                </Text>
              </Flex>
              <Flex grow={1} basis='144px' gap='spacing-xs' padding={{ paddingTop: 'spacing-xs' }}>
                <InputSelectV2
                  className='settings-dev-select-menu'
                  selectedItem={this.getWebSocketProxy(webSocketProxy)}
                  getInputValue={(item) => item.id}
                  optionRenderer={(item) => (<div>{item.id}</div>)}
                  getFilteredList={() => webSocketProxies.map((server) => { return this.getWebSocketProxy(server); })}
                  onSelect={this.handleWebSocketProxyUpdate}
                  onChange={_.debounce(this.handleWebSocketProxyUpdate, 100)}
                />
                <Button
                  type='outline'
                  icon={<IconDirectionForward color='content-color-primary' />}
                  tooltip={t('settings:dev_options.web_socket_proxy.button_tooltip')}
                  onClick={this.handleWebSocketProxySubmit}
                />
              </Flex>
            </Flex>
          }
          {
            __SDK_PLATFORM__ === 'browser' && window.RELEASE_CHANNEL === 'preview' && this.state.isAppDownloadAvailable &&
            <Flex shrink={1} direction='column' gap='spacing-m' padding='spacing-m spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
              <Flex direction='column' gap='spacing-xs'>
                <Text>{t('settings:dev_options.download_app_preview.heading')}</Text>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-xs',
                  }}
                >
                  {t('settings:dev_options.download_app_preview.sub_heading')}
                </Text>
              </Flex>
              {this.renderAppDownloadButton(pm.app.getPlatform())}
            </Flex>
          }
          <Flex shrink={1} gap='spacing-xxl' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Flex direction='column' shrink={1}>
              <Text>{t('settings:dev_options.postman_gateway_request_details.heading')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:dev_options.postman_gateway_request_details.sub_heading')}
              </Text>
            </Flex>
            <Flex className='settings-rhs-control-spacing-adjust' shrink={0}>
              <ToggleSwitch
                isChecked={this.state.showPostmanGatewayRequestDetailsAsQuery}
                onChange={this.handleShowPostmanGatewayRequestDetailsAsQueryFieldChange}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default withTranslation('settings')(SettingsDevOptions);
