/**
 * Determine if the user is an admin
 * @param {Object} currentUser
 * @returns {Boolean}
 */
 function isUserAdmin (currentUser) {
  var orgs = currentUser.organizations;
  if (!_.isEmpty(orgs) && (currentUser.teamSyncEnabled)) {
    let admins = _.chain(orgs[0].team_users)
    .filter((user) => {
      return _.includes(user.roles, 'admin');
    })
    .map((user) => { return user.id; })
    .value();

    return _.includes(admins, currentUser.id);
  }
  return false;
}

export {
  isUserAdmin
};
