import { HTTPGatewayService } from './HTTPGatewayService';
import _ from 'lodash';
const HTTP_GATEWAY_SERVICE_ID = 'integrations', INTEGRATION_INSTANCE_BASE_URL = '/integration-instances', INTEGRATION_INSTANCE_ENDPOINTS = {
    BULK: '/bulk'
};
export default {
    /**
     * Fetches details of a given integration instance.
     *
     * @param instanceId - ID of the integration instance to fetch.
     * @param params - optional query params to send the API.
     * */
    fetchIntegrationInstanceDetails(instanceId, params) {
        const path = `${INTEGRATION_INSTANCE_BASE_URL}/${instanceId}`;
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'get',
                path,
                query: params
            }
        });
    },
    /**
     * Fetches the configured integrations instance and corresponding integration instance inputs
     *
     * @param entityType
     * @param entityId
     * @param clientCategories
     */
    getConfiguredIntegrationsForAnEntity(entityType, entityId, clientCategories) {
        const clientCategoryParam = _.join(clientCategories, ',');
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'get',
                path: `${INTEGRATION_INSTANCE_BASE_URL}`,
                query: {
                    entityType,
                    entityId,
                    clientCategory: clientCategoryParam
                }
            }
        });
    },
    /**
     * Creates a new integration instance of a given class.
     *
     * @param classId - ID of the class of which to create the instance.
     * @param name - name of the integration instance
     * @param inputs - key-value pairs of inputs for the instance
     * */
    createIntegrationInstance(classId, name, inputs) {
        const path = INTEGRATION_INSTANCE_BASE_URL, inputsArray = _.map(inputs, (value, key) => {
            return {
                key,
                value
            };
        });
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'post',
                path,
                body: {
                    class: classId,
                    name,
                    inputs: inputsArray
                }
            }
        });
    },
    /**
     * Creates new integration instances in bulk
     *
     * @param payload - array containing the objects of instances to be created
     * @param payload.name - name of the integration to be created
     * @param payload.class - class of the integration to be created
     * @param payload.inputs - array of objects containing the integration inputs in the key value format
     * */
    createIntegrationInstanceBulk(payload) {
        const path = INTEGRATION_INSTANCE_BASE_URL + INTEGRATION_INSTANCE_ENDPOINTS.BULK;
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'post',
                path,
                body: payload
            }
        });
    },
    /**
     * Updates a given integration instance.
     *
     * @param instanceId - ID of the integration instance to update.
     * @param name - name of the integration instance
     * @param inputs - key-value pairs of inputs for the instance
     * */
    updateIntegrationInstance(instanceId, name, inputs) {
        const path = `${INTEGRATION_INSTANCE_BASE_URL}/${instanceId}`, inputsArray = _.map(inputs, (value, key) => {
            return {
                key,
                value
            };
        }), payload = {};
        if (!_.isNil(name)) {
            payload.name = name;
        }
        if (!_.isEmpty(inputsArray)) {
            payload.inputs = inputsArray;
        }
        return HTTPGatewayService.request({
            data: {
                service: HTTP_GATEWAY_SERVICE_ID,
                method: 'put',
                path,
                body: payload
            }
        });
    }
};
