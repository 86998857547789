import { HTTPGatewayService } from './HTTPGatewayService';

const HTTP_GATEWAY_SERVICE_ID = 'integrations',
  INTEGRATION_CLASS_BASE_URL = '/integration-classes',
  INTEGRATION_SERVICE_BASE_URL = '/services';

export default {
  /**
   * Fetches details of a given integration class.
   *
   * @param {String} classId - ID of the integration class to fetch.
   * @param {object} [params] - optional query params to send the API.
   * */
  fetchIntegrationClassDetails (classId, params) {
    const path = `${INTEGRATION_CLASS_BASE_URL}/${classId}`;

    return HTTPGatewayService.request({
      data: {
        service: HTTP_GATEWAY_SERVICE_ID,
        method: 'get',
        path,
        query: params
      }
    });
  },

  /**
   *
   * @returns The list of all available integration classes
   */
   fetchAvailableClasses () {
    const path = INTEGRATION_CLASS_BASE_URL;

    return HTTPGatewayService.request({
      data: {
        service: HTTP_GATEWAY_SERVICE_ID,
        method: 'get',
        path
      }
    });
  },

  /**
   *
   * @returns The list of all available integration services
   */
  fetchAvailableServices () {
    const path = INTEGRATION_SERVICE_BASE_URL;

    return HTTPGatewayService.request({
        data: {
          service: HTTP_GATEWAY_SERVICE_ID,
          method: 'get',
          path
        }
      });
  },

  /**
   *
   * @param {*} serviceId
   * @param {*} params
   */
  fetchIntegrationServiceDetails (serviceId, params) {
    const path = `${INTEGRATION_SERVICE_BASE_URL}/${serviceId}`;

    return HTTPGatewayService.request({
      data: {
        service: HTTP_GATEWAY_SERVICE_ID,
        method: 'get',
        path,
        query: params
      }
    });
  }
};
