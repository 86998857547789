import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { Text, Heading, Checkbox, ToggleSwitch, CheckboxGroup, TextInput, TextArea, Label, Flex } from '@postman/aether';
import { openExternalLink } from '../../../external-navigation/ExternalNavigationService';
import classnames from 'classnames';
import Link from '../../../../appsdk/components/link/Link';

const proxyDocLink = 'https://go.pstmn.io/proxy-doc',

  propsToSave = {
    httpProxy: true,
    httpsProxy: true,
    disabled: true,
    authenticate: true
  };

const StyledCheckboxGroupContainer = styled(Flex)`
  // To align Checkbox labels to the text on LHS
  > div > div {
    padding-top: 2px;
  }
`;

const StyledInputSeparatorContainer = styled.span`
  // Keep the separator aligned to the center when the inputs shift up when a validation error is shown, to accomodate the space.
  position: relative;
  top: 8px;
`;

const ExternalLink = ({ children }) => (
  <Link
    className='learn-more-button'
    to={proxyDocLink}
    target='_blank'
  >
    <Text type='link-default' isExternal>
      {children}
    </Text>
  </Link>
);

class SettingsGlobalProxy extends Component {
  constructor (props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);

    this.handleProxyDisableChange = this.handleProxyPropertyChange.bind(this, 'disabled');
    this.handleHttpProtocolChange = this.handleProtocolChange.bind(this, 'httpProxy');
    this.handleHttpsProtocolChange = this.handleProtocolChange.bind(this, 'httpsProxy');

    this.handleProxyHostChange = this.handleProxyPropertyChange.bind(this, 'host');
    this.handleProxyPortChange = this.handleProxyPropertyChange.bind(this, 'port');

    this.handleProxyAuthEnableChange = this.handleProxyPropertyChange.bind(this, 'authenticate');
    this.handleProxyAuthUsernameChange = this.handleProxyPropertyChange.bind(this, 'username');
    this.handleProxyAuthPasswordChange = this.handleProxyPropertyChange.bind(this, 'password');

    this.handleProxyBypassChange = this.handleProxyPropertyChange.bind(this, 'bypass');
  }

  handleOnSave () {
    this.props.onSave();
  }

  handleProxyPropertyChange (key, value) {
    var finalValue = value;

    if (key === 'port') {
      finalValue = parseInt(value, 10);
    }
    else if (key === 'disabled') {
      finalValue = !value;
    }

    // @note: don't update local storage on input (e.g, host, port) onChange
    this.props.onUpdate(key, finalValue, () => {
      if (propsToSave[key]) {
        this.handleOnSave();
      }
    });
  }

  handleProtocolChange (prop, change) {
    let { httpProxy, httpsProxy } = this.props.proxy;

    if (prop === 'httpProxy') {
      httpProxy = change;
    }
    else {
      httpsProxy = change;
    }

    // both can't be turned off
    if (!httpProxy && !httpsProxy) {
      return;
    }

    this.handleProxyPropertyChange(prop, change);
  }

  render () {
    const { t } = this.props;
    let { useSystemProxy, useProxyEnvironmentVariables } = this.props,
        proxy = this.props.proxy || {},
        disabled = proxy.disabled,
        hostError = this.props.errors.host,
        portError = this.props.errors.port,
        proxyVarsClassName = classnames({
          'proxy-environment-variables': true,
          disabled: !useSystemProxy
        }),
        proxyAuthClassName = classnames({
          'settings-global-proxy-group': true,
          secondary: true,
          disabled: !proxy.authenticate || disabled
        }),
        proxyBypassClassName = classnames({
          'settings-global-proxy-group': true,
          secondary: true,
          disabled: disabled
        });

    return (
      <div className='settings-global-proxy'>
        <Flex direction='column' gap='spacing-xs' padding={{ paddingTop: 'spacing-s', paddingBottom: 'spacing-xl' }}>
          <Heading
            text={t('settings:proxy.global.heading')}
            type='h4'
          />
          <Text type='para' data-testid='settings-global-proxy-desc'>
            <Trans
              t={t}
              i18nKey='settings:proxy.global.desc'
              defaults='Specify a proxy setting to act as an intermediary for requests sent through the Builder in Postman. These configurations do not apply to any Postman services. Learn more about <0>using a custom proxy</0>'
              components={[<ExternalLink key='proxyLearnMoreLink'>dummyChild</ExternalLink>]}
            />
          </Text>
        </Flex>

        <Flex justifyContent='space-between' padding='spacing-m spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
          <Text>{t('settings:proxy.global.use_system_proxy_label')}</Text>
          <ToggleSwitch
            isChecked={useSystemProxy}
            onChange={this.props.onToggleSystemProxy}
            data-testid='settings-global-use-system-proxy'
          />
        </Flex>
        {useSystemProxy &&
          <Flex
            className={proxyVarsClassName}
            justifyContent='space-between'
            padding='spacing-m spacing-zero spacing-m spacing-zero'
            gap='spacing-xl'
            border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
          >
            <Text>{t('settings:proxy.global.respect_variables_label')}</Text>
            <Flex>
              <ToggleSwitch
                isChecked={Boolean(useProxyEnvironmentVariables)}
                isDisabled={!useSystemProxy}
                onChange={this.props.onToggleProxyEnvironmentVariables}
              />
            </Flex>
          </Flex>
        }
        <Flex
          justifyContent='space-between'
          padding='spacing-m spacing-zero'
          gap='spacing-xl'
          border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
        >
          <Text>{t('settings:proxy.global.use_custom_proxy_label')}</Text>
          <ToggleSwitch
            isChecked={!disabled}
            onChange={this.handleProxyDisableChange}
            data-testid='settings-custom-proxy-conf'
          />
        </Flex>

        {!disabled &&
        <Flex
          direction='column'
          border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
          className='settings-global-proxy-group-wrapper'
        >
          <Flex
            direction='column'
            padding='spacing-zero'
            className={'settings-global-proxy-group ' + (disabled ? 'disabled' : '')}
          >
            <Flex
              justifyContent='space-between'
              padding='spacing-m spacing-zero'
              gap='spacing-xl'
            >
              <Text>{t('settings:proxy.global.type_selector_label')}</Text>
              <StyledCheckboxGroupContainer>
                <CheckboxGroup direction='row'>
                  <Checkbox
                    isChecked={Boolean(proxy.httpProxy)}
                    isDisabled={disabled}
                    onChange={this.handleHttpProtocolChange}
                    label={t('settings:proxy.global.type_HTTP_label')}
                    data-testid='protocol-http'
                  />
                  <Checkbox
                    isChecked={Boolean(proxy.httpsProxy)}
                    isDisabled={disabled}
                    onChange={this.handleHttpsProtocolChange}
                    label={t('settings:proxy.global.type_HTTPS_label')}
                    data-testid='protocol-https'
                  />
                </CheckboxGroup>
              </StyledCheckboxGroupContainer>
            </Flex>
            <Flex
              justifyContent='spacing-between'
              alignItems='center'
              padding='spacing-m spacing-zero'
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
              gap='spacing-l'
            >
              <Flex>
                <Text>{t('settings:proxy.global.server_input_label')}</Text>
              </Flex>
              <Flex gap='spacing-s' alignItems='center' justifyContent='flex-end' width='100%' shrink={1} alignItems='flex-start'>
                <Flex basis='108px'>
                  <TextInput
                    isDisabled={disabled}
                    validationStatus={hostError ? 'error' : ''}
                    validationMessage={hostError}
                    placeholder='127.0.0.1'
                    value={proxy.host || ''}
                    onBlur={this.handleOnSave}
                    onChange={(e) => this.handleProxyHostChange(e.target.value)}
                    data-testid='proxy-server-host'
                  />
                </Flex>
                <StyledInputSeparatorContainer>
                  <Text type='strong'>:</Text>
                </StyledInputSeparatorContainer>
                <Flex basis='72px'>
                  <TextInput
                    isDisabled={disabled}
                    validationStatus={portError ? 'error' : ''}
                    validationMessage={portError}
                    placeholder='8080'
                    type='number'
                    value={proxy.port}
                    onBlur={this.handleOnSave}
                    onChange={(e) => this.handleProxyPortChange(e.target.value)}
                    data-testid='proxy-server-port'
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              justifyContent='spacing-between'
              alignItems='center'
              padding='spacing-m spacing-zero'
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
            >
              <Flex direction='column' grow={1}>
                <Text>{t('settings:proxy.global.auth_label')}</Text>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s'
                  }}
                >
                  {t('settings:proxy.global.auth_desc')}
                </Text>
              </Flex>
              <ToggleSwitch
                isChecked={proxy.authenticate}
                isDisabled={disabled}
                onChange={this.handleProxyAuthEnableChange}
                data-testid='proxy-authenticate'
              />
            </Flex>
            <Flex
              padding='spacing-m spacing-zero spacing-l spacing-zero'
              gap='spacing-m'
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
              className={proxyAuthClassName}
            >
              <TextInput
                isDisabled={!proxy.authenticate || disabled}
                value={proxy.username}
                onBlur={this.handleOnSave}
                onChange={(e) => this.handleProxyAuthUsernameChange(e.target.value)}
                label={<Label text={t('settings:proxy.common.username_label')} />}
                data-testid='proxy-username'
              />
              <TextInput
                className='proxy-password-input'
                isDisabled={!proxy.authenticate || disabled}
                type='password'
                label={<Label text={t('settings:proxy.common.password_label')} />}
                value={proxy.password}
                onBlur={this.handleOnSave}
                onChange={(e) => this.handleProxyAuthPasswordChange(e.target.value)}
                data-testid='proxy-password'
              />
            </Flex>
          </Flex>
          <Flex
            justifyContent='space-between'
            gap='spacing-l'
            padding='spacing-m spacing-zero'
            border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
            className={proxyBypassClassName}
          >
            <Flex direction='column' shrink={1}>
              <Text>{t('settings:proxy.global.bypass_label')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s'
                }}
              >
                {t('settings:proxy.global.bypass_desc')}
              </Text>
            </Flex>
            <Flex direction='column' basis='200px'>
              <TextArea
                className='proxy-bypass-list'
                value={proxy.bypass || ''}
                isDisabled={disabled}
                onBlur={this.handleOnSave}
                onChange={(e) => this.handleProxyBypassChange(e.target.value)}
                placeholder='E.g. 127.0.0.1, localhost, *.example.com'
              />
            </Flex>
          </Flex>
        </Flex>
        }
      </div>
    );
  }
}


SettingsGlobalProxy.propTypes = {
  onToggleSystemProxy: PropTypes.func.isRequired,
  useSystemProxy: PropTypes.bool.isRequired,
  proxy: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

SettingsGlobalProxy.defaultProps = {
  useSystemProxy: true,
  onToggleSystemProxy: _.noop,
  errors: {},
  onSave: _.noop,
  onUpdate: _.noop
};
export default withTranslation('settings')(SettingsGlobalProxy);
