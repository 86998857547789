import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, RadioGroup, Radio, Spinner, Tag } from '@postman/aether';
import { IconStateErrorStroke } from '@postman/aether-icons';
import { StyledRadioGroupContainer } from './styled-components';
import i18nService from '../../i18n/i18nService';
import AnalyticsService from '../../modules/services/AnalyticsService';
import ShowBetaTagInLanguageSettingsFlag from '../../i18n/experiments/ShowBetaTagInLanguageSettingsFlag';

const META_INFO_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error'
};

const LOADER_SHOW_TIMEOUT = 1000;

const LanguageSelectorContainer = styled(Flex)`
  transition: background-color 1.5s ease;
  width: calc(100% + 16px);
  padding: ${(props) => `${props.theme['spacing-zero']} ${props.theme['spacing-s']};`};
  border-radius: ${(props) => props.theme['border-radius-default']};
  margin: 0 -8px;

  &.highlight {
    background-color: ${(props) => props.theme['background-color-brand']};
  }
`;

/**
 * Language Selection UI
 */
function LanguageSelector () {
  const { t } = useTranslation('settings');
  const showBetaTag = ShowBetaTagInLanguageSettingsFlag.isEnabled;

  // During language switch user can close the settings modal and open it again

  // So we need to keep track when the component is rendering if the language is already in loading state
  const [metaInfoStatus, setMetaInfoStatus] = useState(i18nService.loadingLocale ? META_INFO_STATUS.LOADING : META_INFO_STATUS.IDLE);
  const [selectorLocale, setSelectorLocale] = useState(i18nService.loadingLocale || i18nService.locale);

  // For the above mentioned scenario, we will also need to react once the locale is changed properly
  useEffect(() => {
    const dispose = reaction(() => i18nService.locale, (locale) => {
      setMetaInfoStatus(META_INFO_STATUS.IDLE);
      setSelectorLocale(locale);
    });

    return dispose;
  }, []);

  // Language change handler
  const handleLanguageChange = (lang) => {
    // If the selected language is same as the current language, do not allow a change
    if (lang === selectorLocale) {
      return;
    }

    // If already a language change is in progress, do not allow another change
    if (metaInfoStatus === META_INFO_STATUS.LOADING) {
      return;
    }

    // Optimistically set the locale only for the component
    setSelectorLocale(lang);

    // Show the loader only if the language change takes more than 1000ms
    const loaderShowTimeout = setTimeout(() => {
      setMetaInfoStatus(META_INFO_STATUS.LOADING);
    }, LOADER_SHOW_TIMEOUT);

    i18nService.changeLocale(lang)
      .then(() => {
        // Language change is successful

        // Clear the timeout in case the language change is faster than 500ms
        clearTimeout(loaderShowTimeout);

        // Set the meta status to idle to show normal description
        setMetaInfoStatus(META_INFO_STATUS.IDLE);
      })
      .catch(() => {
        // Error while changing the language
        AnalyticsService.addEventV2({
          category: 'i18n',
          action: 'locale_switch_error',
          label: lang
        });

        // Cleat the loader timeout
        clearTimeout(loaderShowTimeout);

        // Switches back to the previous language
        setSelectorLocale(i18nService.locale);

        // Set the meta status to error to show error description
        setMetaInfoStatus(META_INFO_STATUS.ERROR);
      });
  };

  return (
    <LanguageSelectorContainer className='settings__language-selector'>
      <Flex direction='column' width='100%'>
        <Flex justifyContent='space-between' gal='spacing-xl' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
          <Flex direction='column' shrink={1}>
            <Flex gap='spacing-s' alignItems='center'>
              <Flex gap='spacing-xs' alignItems='center'>
                <Text color='content-color-primary'>{t('settings:general.application.language')}</Text>
                {showBetaTag && <Tag text={t('settings:beta_tag')} color='blue' />}
              </Flex>
              {metaInfoStatus === META_INFO_STATUS.LOADING && <Spinner />}
            </Flex>
            {
              metaInfoStatus === META_INFO_STATUS.IDLE &&
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:general.application.language_description')}
              </Text>
            }
            {
              metaInfoStatus === META_INFO_STATUS.LOADING &&
              <Text
                color='content-color-primary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:general.application.language_loading')}
              </Text>
            }
            {
              metaInfoStatus === META_INFO_STATUS.ERROR &&
              <Flex gap='spacing-xs' justifyContent='center'>
                <IconStateErrorStroke color='content-color-error' />
                <Text color='content-color-error' type='para'>
                  {t('settings:general.application.language_error')}
                </Text>
              </Flex>
            }
          </Flex>
          <StyledRadioGroupContainer>
            <RadioGroup
              name='settings-general-application-language'
              value={selectorLocale}
              onChange={handleLanguageChange}
            >
              <Radio value='en-US' label='English' id='en-us' isDisabled={metaInfoStatus === META_INFO_STATUS.LOADING} />
              <Radio value='ja' label='日本語' id='ja' isDisabled={metaInfoStatus === META_INFO_STATUS.LOADING} />
            </RadioGroup>
          </StyledRadioGroupContainer>
        </Flex>
      </Flex>
    </LanguageSelectorContainer>
  );
}

export default observer(LanguageSelector);
