import collection from './collection';

const COLLECTION = 'collection',
      TYPE_JSON = 'json',
      DEFAULT_FILE_NAME_EXT = '.json',
      exportModels = {
        [COLLECTION]: collection,
      };

/**
 * Exports a model (collection, environment or globals)
 * @param {String} model
 * @param {Object} criteria
 * @param {Object} exportOptions
 * @returns {Promise} which resolves in an object having name, type and data
 */
export default function (model, criteria, exportOptions) {
  let populatedEntity,
      entityName,
      fileName,
      exportModel = exportModels[model],
      context = {
        appVersion: pm.app.get('info').version,
        exportOptions
      };

  if (!exportModel) {
    return Promise.reject(new Error(`Export: unsupported model: '${model}'`));
  }

  return Promise.resolve()

    // Fetch the model from db
    .then(() => {
      return exportModel.getFromDb(criteria);
    })

    // Fetch the context (optional step)
    .then((entity) => {
      if (!entity) {
        return Promise.reject(new Error(`Export: received empty entity for model: ${model} for criteria ${JSON.stringify(criteria)}`));
      }

      populatedEntity = entity;

      if (exportModel.getContext) {
        return exportModel.getContext(criteria);
      }
    })

    // cache the context
    .then((ctx) => {
      _.merge(context, ctx);
    })

    // get the entity name and file name to be exported
    .then(() => {
      exportModel.getEntityName
        ? (entityName = exportModel.getEntityName(populatedEntity, context))
        : (entityName = model);
      exportModel.getFileName
        ? (fileName = exportModel.getFileName(populatedEntity, context))
        : (fileName = model + DEFAULT_FILE_NAME_EXT);
    })

    // sanitize the entity
    // this step MUST not mutate the entity
    .then(() => {
      if (exportModel.sanitize) {
        return exportModel.sanitize(populatedEntity, context);
      }
      return populatedEntity;
    })

    // transform the data to the exportable format
    // for collection it will first convert to v1 format then optionally to v2/v.2.1
    // for environment/globals it will add the extra meta properties
    .then((sanitizedEntity) => {
      if (!exportModel.transform) {
        return sanitizedEntity;
      }

      return exportModel.transform(sanitizedEntity, context);
    })

    // return the data
    .then((transformedData) => {
      return {
        type: TYPE_JSON,
        json: transformedData,
        meta: {
          name: entityName,
          fileName: fileName
        }
      };
    });
}
