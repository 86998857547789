import React, { Component } from 'react';
import SettingsShortcuts from '../../components/settings/SettingsShortcuts';

export default class SettingsShortcutsContainer extends Component {
  constructor (props) {
    super(props);
  }

  render () {

    return (
      <SettingsShortcuts />
    );
  }
}
