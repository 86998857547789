import { INTEGRATION_LABELS, INTEGRATION_LABEL_PRIORITY } from '../BrowseIntegrations/IntegrationsLabels';
import _ from 'lodash';

const CATEGORY_ALL = 'all';

export default {
  /**
   *
   * Fetch categories of the integrations
   *
   * @param {Array} services - contains categories
   * @param {Array} services.categories -  e.g. ['Backup', 'Notifications']
   * @returns {Array} - sorted array containing all categories including `All`
   */
  getAllCategories (services) {
    // NOTE: If we change this logic, verify if IntegrationsStore.updateFilterByCategoryAvailableIntegrations still works
    let categories = _.uniq(_.flatMap(services, 'categories'));

    // categories.sort();
    _.sortBy(categories);

    // appending `All` in beginning
    categories.unshift(CATEGORY_ALL);

    return categories;
  },

  /**
   *
   * Fetch categories of the integrations
   *
   * @param {Array} services - contains categories
   * @param {Array} services.categories -  e.g. ['Backup', 'Notifications']
   * @returns {Array} - sorted array containing all categories including `All`
   */
   getAllIntegrationCategories (integrationServices) {
    let categories = _.uniq(_.flatMap(integrationServices, (integrationClass) => {
      return _.map(integrationClass.categories, 'name');
    }));

    _.sortBy(categories);

    // appending `All` in beginning
    categories.unshift(CATEGORY_ALL);

    return categories;
  },

  /**
   *
   * @param {String} category - category of integration, e.g. `backup`, `notification` etc.
   * @param {Array} services - list of integration services
   * @returns
   */
   countServicesByCategory (category, services) {
    if (category === CATEGORY_ALL) {
      return _.size(services);
    }

    let filteredServices = _.filter(services, (service) => {
      const serviceCategories = _.map(_.get(service, 'categories', []), 'name');
      return _.includes(serviceCategories, category);
    });

    return _.size(filteredServices);
  },

  /**
   *
   * @param {String} selectedCategory - category of integrations, e.g. `backup`, `notification` etc.
   * @param {Array} services - list of integration services
   * @returns
   */
  filterByCategory (selectedCategory, services) {

    let filteredServices, lowerCaseSelectedCategory;

    if (selectedCategory === CATEGORY_ALL) {
      filteredServices = services;
    }
    else if (typeof selectedCategory !== 'string') {
      filteredServices = [];
    }
    else {
      lowerCaseSelectedCategory = _.toLower(selectedCategory);

      filteredServices = _.filter(services, (service) => {
        // Filter services that have the selected category (case insensitive)
        return _.some(service.categories, (category) => {
          const categoryName = _.toLower(_.get(category, 'name'));
          return (categoryName === lowerCaseSelectedCategory);
        });
      });
    }

    return filteredServices;
  },


  /**
   *
   * Counts category-wise available integrations
   *
   * @param {String} category - category of integration, e.g. `backup`, `notification` etc.
   */
  countByCategory (category, services) {
    if (category === CATEGORY_ALL) {
      return services.length;
    }

    let filteredServices = _.filter(services, (service) => {
      return _.includes(service.categories, category);
    });

    return filteredServices.length;
  },


  /**
   *
   * Sorts services according to the labels and alphabetically
   * Note: Services with labels get higher priority
   *
   * @param {Array} services - contains list of all services
   * @returns {Array} sorted list of available integrations
   */
  sortAvailableIntegrations (services) {

    // integrations with labels - gets higher priority than integrations without labels
    let integrationWithLabels = _.filter(services, (service) => { return INTEGRATION_LABELS[service.id]; }),

      // integration without labels
      integrationWithoutLabels = _.filter(services, (service) => { return !INTEGRATION_LABELS[service.id]; }),

      // precedence order for different labels (new > noteworthy > sponsored)
      order = INTEGRATION_LABEL_PRIORITY;

    // sorts a/q to labels first then alphabetically with name
    integrationWithLabels = integrationWithLabels.sort((a, b) => {
      return order[INTEGRATION_LABELS[a.id]] - order[INTEGRATION_LABELS[b.id]] ?
        order[INTEGRATION_LABELS[a.id]] - order[INTEGRATION_LABELS[b.id]] : (a.name > b.name ? 1 : -1);
    });

    return integrationWithLabels.concat(_.sortBy(integrationWithoutLabels, 'name'));
  },

   /**
   * This function parses the link header and returns the links based on their respective types
   * @param {String} link
   * @returns {Object} The format is of the following {next: "<nextUrl>", last: "<lastUrl>", prev: "<prevUrl>"}
   */
  parseLinkHeadersForGithub (link) {
    if (!link || typeof link !== 'string') {
      return null;
    }

    // Split parts by comma
    let parts = link.split(','),

      // Parse each part into a named link
      links = _.reduce(parts, (acc, part) => {
        let section = part.split(';');

        if (section.length !== 2) {
          return acc;
        }

        let url = section[0].replace(/<(.*)>/, '$1').trim(),
          name = section[1].replace(/rel="(.*)"/, '$1').trim();

        acc[name] = url;

        return acc;
      }, {});

    return links;
  }

};
